import { Comment, Favorite, Reply, Visibility } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import { parseTikTokHashtag, parseTikTokUsername, parseURL, prettyTruncate } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

const TikTokIcon = ({ color = "#000000" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="100%"
        height="100%"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

function TikTokPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    text = parseTikTokUsername(text);
    text = parseTikTokHashtag(text);
    
    const title = "@" + post.user_screenname;
    return <>
        <Box p={padding}>
            <PostTitleElement title={title} link={"https://www.tiktok.com/@" + post.user_screenname} />
        </Box>
        {post.img && <img alt={title} src={post.img} style={{maxWidth: '100%'}}/>}
        <Box p={padding}>

            <PostTextElement text={prettyTruncate(text,300)} post={post} />

            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">
            <Box display="flex" alignItems={"center"} mr={1}>
                <Favorite sx={{fontSize:15, color: '#CC0000', marginRight: 0.5}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_likes}</span>

                <Comment sx={{ fontSize: 15, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_comments}</span>

                <Reply sx={{ fontSize: 15, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_shares}</span>

                <Visibility sx={{ fontSize: 15, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.vk_shares}</span>
            </Box>
            <div style={{ width: "24px" }}>
                <TikTokIcon/>
            </div>
        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default TikTokPostCard;