import { Box, Checkbox, Grid, Paper, Typography } from '@mui/material';
import alertTemplates from '../../config/alert_templates';
function AlertLayoutForm({template, onChange, errors}){

    return <>
        <Grid container spacing={4}>
            {alertTemplates.map((t, index) => {
                const checked = !!template && template.templateTitle === t.templateTitle;

                return <Grid item sm={4} key={index}>
                    <Paper elevation={5} sx={{p:2}}>
                        <Box display="flex" alignItems="center">
                            <Checkbox checked={checked} onChange={() => onChange(t)} />
                            <Typography variant="subtitle1">
                                {t.templateTitle}
                            </Typography>
                        </Box>
                        
                        <Typography mt={2}>
                            {t.templateDesc}
                        </Typography>

                    </Paper>
                </Grid>
            }) }
        </Grid>
    </>
}
export default AlertLayoutForm;