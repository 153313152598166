import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import priorities from "../../../config/priorities";
import sentiments from "../../../config/sentiments";
import languages from "../../../config/languages";
import { translatePost } from "../../../features/post";

function PostMetaElement({post}){
    const project = useSelector(state => state.project.single);
    const tags = project.tags;
    const dispatch = useDispatch();
    
    return <>
    <Typography variant="body2">
    Sentiment: <b>{sentiments[post.sentiment]}</b><br/>
    Language: <b>{languages[post.lang]}</b>
    {post.lang!=="en" && <>
    &nbsp; | <span onClick={() => {
        dispatch(translatePost({
            uuid: post.uuid,
            projectId: project.id
        }))
    }} className="link">translate</span>
    </>}
    <br/>
    Priority: <b>{priorities[post.priority]}</b><br/>
    Tags: <b>{post.tags && post.tags.length > 0 ? post.tags.map(tag => {
        return tags.find(t => t.id === parseInt(tag));
    }).filter(t => !!t).map(t => t.label).join(", ") : "--"}</b><br/>
    </Typography>
    </>
    
}
export default PostMetaElement;