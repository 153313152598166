import { configureStore } from '@reduxjs/toolkit'
import user from './features/user'
import snackbar from './features/snackbar'
import app from './features/app'
import project from './features/project'
import post from './features/post'
import job from './features/job'
import projectWizard from './features/projectwizard'
import netfeedr from './features/netfeedr'
import dialog from './features/dialog'
import tag from './features/tag'
import dashboard from './features/dashboard'
import report from './features/report'
import alert from './features/alert'
import source from './features/source'
import settings from './features/settings'
import group from './features/group'
import subscriber from './features/subscriber'

export default configureStore({
    reducer: {
        alert,
        snackbar,
        subscriber,
        source,
        user,
        app,
        project,
        post,
        job,
        projectWizard,
        dialog,
        tag,
        dashboard,
        settings,
        netfeedr,
        group,
        report
    },
});