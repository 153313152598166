import { red } from '@mui/material/colors';
// import { createTheme } from '@mui/system';
// const { palette } = createTheme();
// const { augmentColor } = palette;

const theme = ({
    typography: {
        fontFamily: 'Libre Franklin',
        h1: {
            fontSize: 60
        },
        h2: {
            fontSize: 48
        },
        h3: {
            fontSize: 36
        },
        h4: {
            fontSize: 28
        }
    },
    

    overrides: { 
        MuiSelect: {
            icon: {
                position: 'relative',
                marginRight: '-22px',
            },
          }
    },


    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
    
    
    palette: {
        blu: {
            main: '#3F51B5',
            contrastText: '#FFF'
        },
        primary: {
            main: '#5a7983',
            dark: '#364d51', // Grigio scuro,
            contrastText: '#FFFFFFB3'
        },
        secondary: {
            main: '#FFc100', // Giallo
            dark: '#ff4f1c', // Rosso,
            contrastText: "#5a7983"
        },
        white:{
            main: '#FFF'
        },
        // error: {
        //     main: red.A400,
        // },
    },
});

export default theme;
