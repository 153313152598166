import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChannelButtonBar from "../../../components/netfeedr/ChannelButtonBar";
import ChannelTable from "../../../components/netfeedr/ChannelTable";
import {listChannels} from "../../../features/netfeedr";
import DeleteChannelDialog from "../../../components/netfeedr/DeleteChannelDialog";
import NewChannelDialog from "../../../components/netfeedr/NewChannelDialog";

function NetfeedrSection(){
    
    const dispatch = useDispatch();
    const channels = useSelector(state => state.netfeedr.list);
    const toggleCount = useSelector(state => state.netfeedr.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, setSearch] = useState("");

    
    const filteredChannels = (channels || [])
    .filter(c => {
        const res = !search 
        || c.channelName.toLowerCase().indexOf(search.toLowerCase())!==-1
        || (c.channelId + "").toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });


    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!channels.find(r => r.id === s);
        }));
    }, [channels]);

    useEffect(() => {
        dispatch(listChannels())
    }, []);



    useEffect(() => {
        setSelection([]);
    },[toggleCount]);
    
    return <>
        <NewChannelDialog />
        <DeleteChannelDialog />

        <ChannelButtonBar selection={selection} search={search} setSearch={setSearch} />
        <ChannelTable channels={filteredChannels} selection={selection} setSelection={setSelection} />
    </>
}
export default NetfeedrSection;