import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import tagrules from '../../config/tagrules';

const options = Object.keys(tagrules).map(key => {
    return {
        value: key,
        text: tagrules[key]
    }
});

function TagRuleSelect({
    value, 
    onChange,
    size,
    label="Tagging Rule", 
    error=false,
    helperText="",
    disabled=false, 
    required=true,
    displayEmpty=true,
    placeholder=""
}){
    
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error}>{label} </InputLabel>
        <Select 
            error={error}
            disabled={disabled}
            displayEmpty={displayEmpty}
            size={size}
            fullWidth
            value={value}
            label={label} 
            required={required} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : ""
            }}
            >
            {options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default TagRuleSelect;