import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasErrors } from '../../common';
import { closeDialog } from '../../features/dialog';
import { saveDashboard } from '../../features/project';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { validateDashboardInfo, validateDashboardTimeSpan } from '../../validator/project';
import DashboardForm from './DashboardForm';

const DIALOG_ID = "editDashboard"
const DIALOG_WIDTH = "lg"

function EditDashboardDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const project = useSelector(state => state.project.single);
    const tabId = useSelector((state) => state.dialog[DIALOG_ID])
    const show = !!tabId
    const isEdit = show && tabId !== true;
    const [errors, setErrors] = useState({});

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        const _errors = {
            ...validateDashboardInfo(form),
            ...validateDashboardTimeSpan(form),
        }
        setErrors(_errors);
        if(hasErrors(_errors)){
            dispatch(enqueueErrorSnack("Some mandatory fields have not been filled in"));
            return;
        }

        setLoading(true);
        dispatch(saveDashboard({
            dashboard: form,
            project
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Dashboard saved"))    
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    
    useEffect(() => {
        if(show && tabId !== true){
            setForm(project.tabs.find(t => t.id === tabId));
        }else{
            setForm({})
        }
    }, [tabId, show])

    return <Dialog
            open={!!show}
            onClose={onClose}
            fullWidth
            maxWidth={DIALOG_WIDTH}

            PaperProps={{
                sx: {
                    height: '90%'
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                {isEdit ? "Edit" : "Create"} Dashboard
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={false} >
                <DashboardForm form={form} onChange={f => setForm(f)} />
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" onClick={handleSubmit} type="button">Save &amp; Close</Button>
                </Box>}
            </DialogActions>
        </Dialog>
}

export default EditDashboardDialog;