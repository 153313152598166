import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../features/dialog";
import cronstrue from 'cronstrue';

function AlertTable({alerts, selection, setSelection}){
    const dispatch = useDispatch();
    
    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != alerts.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(alerts.map(r => r.id));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>Alert</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Frequency</TableCell>
                    <TableCell>Order By</TableCell>
                    <TableCell width={140}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {alerts.map((alert,i) => {
                    let humanFrequency = "--";
                    try{
                        humanFrequency = cronstrue.toString(alert.frequency).split(",").map(r => <div>{r}</div>);
                    }catch(E){

                    }
                    const selected = selection.indexOf(alert.id) !== -1;
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(alert.id)} />
                        </TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            <b>{alert.name}</b><br/>
                            {alert.subject && <>Subject: {alert.subject}</>}
                        </TableCell>
                        <TableCell>{alert.active ? "Yes" : "No"}</TableCell>
                        <TableCell>{humanFrequency}</TableCell>
                        <TableCell>{alert.orderBy}</TableCell>
                        
                        <TableCell align="right">
                            <Tooltip title={alert.active ? "Deactivate" : "Activate"}>
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: alert.active ? "deactivateAlert" : "activateAlert", props: alert.id}));
                                }}>
                                    {alert.active ? <Stop /> : <PlayArrow /> }
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: "editAlert", props: alert.id}));
                                }}><Edit /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size="small"  onClick={() => {
                                    dispatch(openDialog({id: "deleteAlert", props: alert.id}));
                                }}><Delete /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default AlertTable;