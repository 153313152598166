import {  FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import subscriptionTypes from '../../config/subscriptionTypes';
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(subscriptionTypes).map(key => {
    return {
        value: key,
        text: subscriptionTypes[key]
    }
});

function SubscriptionTypeSelect({
    value, 
    onChange,
    size,
    error=false,
    label="Subscription Type", 
    hideHelpAdornment=false,
    displayEmpty=false,
    placeholder="",
    disabled=false, 
    required=true
}){
    const _options = (required ? [...options] : [
        {value: '', text: ''},
        ...options
    ]);
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            displayEmpty={displayEmpty}
            required={required}
            disabled={disabled}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            endAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="subscriptionType" />}
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : "All"
            }}

        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
    </FormControl>
}
export default SubscriptionTypeSelect;