import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SlotRow from './alertviewrows/SlotRow';
import Header1Row from './alertviewrows/Header1Row';
import Header2Row from './alertviewrows/Header2Row';
import Header3Row from './alertviewrows/Header3Row';
import TextRow from './alertviewrows/TextRow';
import SmallRow from './alertviewrows/SmallRow';
import TimestampRow from './alertviewrows/TimestampRow';
import ImageRow from './alertviewrows/ImageRow';
import SeparatorRow from './alertviewrows/SeparatorRow';
import FeedsRow from './alertviewrows/FeedsRow';
import './AlertView.css';

function Row(props){
    const {row, index, hiddenElements} = props;

    if(hiddenElements.findIndex(h => h == index) >= 0)
        return null;
        
    switch(row.type){
        case 'slot': return <SlotRow {...props} />;
        case 'header1': return <Header1Row {...props} />;
        case 'header2': return <Header2Row {...props} />;
        case 'header3': return <Header3Row {...props} />;
        case 'text': return <TextRow {...props} />;
        case 'small': return <SmallRow {...props} />;
        case 'timestamp': return <TimestampRow {...props} />;
        case 'image': return <ImageRow {...props} />;
        case 'separator': return <SeparatorRow {...props} />;
        case 'feeds': return <FeedsRow {...props} />;
        default: return <tr><td>Not implemented</td></tr>;
    }
}

function AlertView(){
    const params = useParams();
    const [hiddenElements, setHiddenElements] = useState([]);
    const [report, setReport] = useState();
    const reportId = params && params.reportId;

    useEffect(() => {
        if(reportId){
            axios.get("/report/get/" + reportId).then(r => setReport(r.data))
        }
    }, [reportId])

    if(!report)
        return null;

    const {
        config: {
            template: {
                rows,
                templateFonts,
                templateColors,
                templateTitle,
            }
        }
    } = report;


    return <div className="alert-view">
        <table>
            <tbody>
                <tr>
                    <td bgcolor="#FFF">
                        <table 
                            width="500" 
                            cellSpacing="0" 
                            cellPadding="0" 
                            border="0" 
                            style={{
                                backgrounColor: templateColors.background,
                                margin:"0 auto",
                                border: "1px solid " + templateColors.elements
                            }}
                        >
                            <tbody>
                                {rows.map((row, index) => <Row 
                                    onHide={k => setHiddenElements([...hiddenElements, k])} 
                                    hiddenElements={hiddenElements}
                                    report={report} 
                                    row={row} 
                                    index={index}
                                    key={index} 
                                /> )}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}
export default AlertView;