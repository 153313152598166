import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActivateJobDialog from "./dialog/ActivateJobDialog";
import DeactivateJobDialog from "./dialog/DeactivateJobDialog";
import DeleteJobDialog from "./dialog/DeleteJobDialog";
import EditJobDialog from "./dialog/EditJobDialog";
import NewJobDialog from "./dialog/NewJobDialog";
import JobButtonBar from "./JobButtonBar";
import JobTable from "./JobTable";

function JobManagement(){
    const jobs = useSelector(state => state.job.list);
    const toggleCount = useSelector(state => state.job.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, setSearch] = useState("");

    
    const filteredJobs = (jobs || [])
    .filter(j => {
        const res = !search || j.label.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });


    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!jobs.find(r => r.id === s);
        }));
    }, [jobs]);

    useEffect(() => {
        setSelection([]);
    },[toggleCount]);
    
    return <>
        <DeleteJobDialog />
        <ActivateJobDialog />
        <DeactivateJobDialog />
        <EditJobDialog />
        <NewJobDialog />

        <JobButtonBar selection={selection} search={search} setSearch={setSearch} />
        <JobTable jobs={filteredJobs} selection={selection} setSelection={setSelection} />
    </>
}
export default JobManagement;