import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { hasErrors } from "../../../common";
import { validateProjectWizard } from "../../../validator/project";
import JobForm from "../../job/JobForm";

function ProjectJobStep({
    form,
    onChange,
    jobIndex=0,
    step,
    handleNext,
    handleSkip,
}){

    const [errors, setErrors] = useState({});
    const [index, setIndex] = useState(jobIndex);
    const [saved, setSaved] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [jobForm, setJobForm] = useState({});

    const prevIndex = useRef();


    const setForm = form => {
        onChange(form);
    }
    
    const handleSave = e => {
        let formErrors = validateProjectWizard(jobForm, step);
        setErrors(formErrors);
        if(hasErrors(formErrors)){
            return;
        }
        const _jobs = [...jobs];
        _jobs[index] = jobForm;
        setJobs(_jobs);
        setForm({
            ...form,
            jobs: _jobs
        })
        setSaved(true)
    }
    const handlePreview = e => {

    }
    
    const handleReview = e => {
        setSaved(false);
    }
    const handleAddJob = e => {
        setSaved(false);
        setJobForm({});
        setIndex(form.jobs.length);
    }

    useEffect(() => {
        if(prevIndex.current !== jobIndex) {
            const _job = form.jobs && form.jobs[jobIndex] ? form.jobs[jobIndex] : {};
            setJobForm(_job);
            setIndex(jobIndex);
        }
        prevIndex.current = jobIndex;
    },[jobIndex, form]);
    
    if(saved){
        return <>
            <Typography variant="h5">
                Your job <b>{jobForm.name}</b> was saved successfully.
            </Typography>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
                <Button variant="contained" color="primary" onClick={handleReview}>Review Job</Button>
                <Button variant="contained" color="secondary" onClick={handleAddJob}>Add another job</Button>
                <Button variant="contained" color="secondary" type="submit" onClick={handleSkip}>Next: define tags</Button>
            </Box>
        </>
    }

    return <>

        <JobForm form={jobForm} onChange={setJobForm} errors={errors} hideAdvancedFields/>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
            <Button variant="contained" color="primary" onClick={handleSkip}>Skip</Button>
            {/* <Button variant="contained" color="secondary" onClick={handlePreview}>Preview Results</Button> */}
            <Button variant="contained" color="secondary" type="submit" onClick={handleSave}>Save Job</Button>
        </Box>
    </>
}
export default ProjectJobStep;