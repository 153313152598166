import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../../../features/snackbar';
import { addSource } from '../../../../features/source';
import SourceForm from '../SourceForm';
import { validateProjectSource } from '../../../../validator/project';
import { hasErrors } from '../../../../common';

const DIALOG_ID = "newSource"
const DIALOG_WIDTH = "md"

function NewSourceDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const show = !!useSelector((state) => state.dialog[DIALOG_ID])
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateProjectSource(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(addSource({
            project,
            source: form
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Source saved"))    
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm({});
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Create source
            </DialogTitle>
            <DialogContent dividers={false}>

                <Box pr={3}>
                    <SourceForm form={form} onChange={setForm} errors={errors} />
                </Box> 
                
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Create Source</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default NewSourceDialog;