import { Add, AddCircle } from "@mui/icons-material";
import {  Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasErrors, validateEmail } from "../../common";
import { addProjectUser, loadProjectUsers } from "../../features/project";
import { enqueueSuccessSnack } from "../../features/snackbar";
import { allUsers } from "../../features/user";
import HelpAdornment from "./HelpAdornment";


function UserSelect({
    value, 
    onChange,
    size="small",
    label="User", 
    disabled=false, 
    helperText="",
    error=false,
    displayEmpty=true,
    multiple=false,
    hideHelpAdornment=false,
    placeholder="",
    showCreate=true,
    required=true
}){
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const users = useSelector(state => project ? state.project.users : state.user.all);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [newUserName, setNewUserName] = useState("");
    const [newUserEmail, setNewUserEmail] = useState("");
    const onCloseDialog = () => {
        setShowDialog(false) 
    }
    useEffect(() => {
        if(project){
            dispatch(loadProjectUsers(project.id));
        }else{
            dispatch(allUsers());
        }
    }, [project]);

    const handleNewUser = e => {
        const _r = {};
        if(!newUserEmail){
            _r.email = "Email is required";
        }
        if(!validateEmail(newUserEmail)){
            _r.email = "Email is not valid";
        }
        if(!newUserName){
            _r.name = "Full name is required";
        }
        setErrors(_r);
        if(hasErrors(_r)){
            return;
        }
        setLoading(true);
        dispatch(addProjectUser({
            project, 
            user: {
                user_full_name: newUserName,
                email: newUserEmail
            }
        })).then(r => {
            setShowDialog(false);
            setLoading(false);
            dispatch(enqueueSuccessSnack("User created"))
        })
    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return <Box display="flex" flexDirection={"row"} alignItems="flex-end" justifyContent={"space-between"}>
        <Dialog
            open={showDialog}
            onClose={onCloseDialog}
            fullWidth
            maxWidth={"xs"}
            
        >
            <DialogTitle>
                Create new user
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} pb={4} pt={2}>
                    <Grid item sm={12}>
                        <TextField 
                            required
                            autoFocus 
                            fullWidth 
                            error={!!errors.name}
                            helperText={errors.name}
                            value={newUserName}
                            label="User full name"
                            onChange={e => setNewUserName(e.target.value)} 
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField 
                            fullWidth 
                            required
                            type="email"
                            value={newUserEmail}
                            error={!!errors.email}
                            helperText={errors.email}
                            label="User email"
                            onChange={e => setNewUserEmail(e.target.value)} 
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onCloseDialog} >Cancel</Button>
                    <Button onClick={handleNewUser} variant="contained" color="secondary" >Create new user</Button>
                </Box>}
            </DialogActions>
        </Dialog>
        <FormControl sx={{minWidth: 150}} fullWidth size="small">
            <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
            <Select 
                required={required}
                multiple={multiple}
                displayEmpty={displayEmpty}
                error={error}
                disabled={disabled}
                size={size}
                fullWidth
                value={value}
                label={label} 
                onChange={handleChange} 
                renderValue={v => {
                    console.log("Rendering", v, users)
                    if(!v)
                        return placeholder;
                    if(multiple){
                        const _users = (users || []).filter(o => {
                            return v.find(vv => ((vv.userid || vv) +"") === o.userid)
                        });
                        console.log(_users)
                        return _users.map(u => <Chip size={size} sx={{mr:1}} key={u.userid} label={u.user_full_name || u.email} />);
                    }
                    const o = (users || []).find(o => o.userid === (v.userid || v)+"");
                    return o ? o.user_full_name || o.email : ""
                }}            
                startAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="userTier" />}
            >
                {(users || []).map(u => { 
                    return <MenuItem value={u.userid} key={u.userid}>{u.user_full_name || u.email}</MenuItem>            
                })}

            </Select>

            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
        </FormControl>
        {showCreate && !disabled && <Tooltip title="Create new user">
            <IconButton onClick={() => {
                setShowDialog(true);
                setNewUserName("");
                setNewUserEmail("");
            }} size="small" variant="contained"><AddCircle /></IconButton>
        </Tooltip>}
    </Box>
}
export default UserSelect;