import { Box, Button, Grid, LinearProgress, Tab, Tabs } from "@mui/material";
import MainContainer from "../../containers/MainContainer";
import { Navigate, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import { loadProject, setSingle } from "../../features/project";
import { setTagGroups, setTags } from "../../features/tag";
import { setJobs } from "../../features/job";
import { useDispatch, useSelector } from "react-redux";
import ToggleProjectStatusForm from "../../components/project/ToggleProjectStatusForm";
import ProjectOptionsForm from "../../components/project/ProjectOptionsForm";
import DashboardManagement from "../../components/dashboard/DashboardManagement";
import AlertManagement from "../../components/alert/AlertManagement";
import ReportManagement from "../../components/report/ReportManagement";
import JobManagement from "../../components/job/JobManagement";
import TagManagement from "../../components/tag/TagManagement";
import ProjectInfoForm from "../../components/project/ProjectInfoForm";
function EditProjectPage(){
    const navigate = useNavigate();
    const project = useSelector(state => state.project.single);
    const params = useParams();
    const dispatch = useDispatch();
    const list = useSelector(state => state.project.list);
    useEffect(() => {
        dispatch(loadProject(params.label)).then(resp => {
            if(resp && resp.payload){
                dispatch(setTagGroups(resp.payload.tagfolders));
                dispatch(setTags(resp.payload.tags));
                dispatch(setJobs(resp.payload.jobs));
            }
        });
    },[params.label, list, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSingle(null));
        }
    }, []);

    const [activeSection, setActiveSection] = useState(0);

    if(!project){
        return <MainContainer>
            <LinearProgress />
        </MainContainer>;
    }

    const sections = [{
        component: <ProjectInfoForm key="projectForm" form={project} disableLabel />,
        title: 'Project info'
    },{
        component: <TagManagement sentimentView={false} key="tagManagement" />,
        title: 'Tags'
    },{
        component: <JobManagement key="jobManagement" />,
        title: 'Jobs'
    },{
        component: <AlertManagement key="alertManagement" />,
        title: 'Alert'
    },{
        component: <ReportManagement key="reportManagement" />,
        title: 'Report'
    },{
        component: <DashboardManagement key="dashboardManagement" />,
        title: 'Dashboard'
    },{
        component: <ProjectOptionsForm key="projectOptions" form={project} />,
        title: 'Project Options'
    },{
        component: <ToggleProjectStatusForm key="projectStatus" form={project} />,
        title: project.archived ? 'Activate project' : "Archive project"
    }]
    if(project.has_autosentiment){
        sections.splice( 2, 0, {
            component: <TagManagement sentimentView key="sentimentManagement" />,
            title: 'Sentiment'
        } );

    }
    
    const Component = sections[activeSection].component;

    return <MainContainer>      
            <Box display="flex" alignItems="center" flexDirection={"row"} justifyContent={"space-between"}>
                <PageTitle variant="h3" title={"Edit Project - " + project.name} />
                <Button variant="outlined" onClick={() => navigate("/project/" + params.label)}>View project</Button>
            </Box>
            
            <Grid container mt={2} spacing={4} wrap="nowrap">
                <Grid item minWidth={230}>
                    <Tabs
                        orientation="vertical"
                        value={activeSection}
                        onChange={(e, v) => setActiveSection(v)}
                        sx={{ 
                            borderRight: 1, 
                            borderColor: 'divider',
                            pr: 2,
                            '& .MuiTabs-indicator': {
                                left: 0
                            }
                        }}
                    >
                        {sections.map((s, i) => <Tab sx={{alignItems: 'flex-start'}} label={s.title} key={i} />)}
                    </Tabs>
                </Grid>
                <Grid item flexGrow={1} pr={2}>
                    <Box gap={4} display="flex" flexDirection={"column"}>
                        {Component}
                    </Box>
                </Grid>
            </Grid>

    </MainContainer>
}
export default EditProjectPage;