import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../../components/user/UserForm";
import { enqueueSuccessSnack } from "../../features/snackbar";
import { editUser } from "../../features/user";

function ProfileInfo(){
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);
    const [form, setForm] = useState(user);
    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(editUser(form))
        .then(r => {
            if(r && r.payload){
                dispatch(enqueueSuccessSnack("Profile updated"));
            }
        })
    }

    return <>
        <Container component="main" maxWidth="sm">
            <Typography mb={4} variant="subtitle2">Edit your profile info:</Typography>
            <form onSubmit={handleSubmit}>
                <UserForm type="self" form={form} onChange={_form => setForm(_form)} />
                <Box mt={4} display="flex" justifyContent="center">
                    <Button type="submit" variant="contained" color="secondary">Save</Button>
                </Box>
            </form>
        </Container>
    </>
}
export default ProfileInfo;