import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearPosts, clearSelection, deletePosts, searchPost, toggleViewType } from "../../../features/post";
import PostCard from "../../../components/post/PostCard";
import { Alert, Box, Button, Grid, IconButton, LinearProgress, Pagination, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import { Delete, Edit, GridView, ViewList } from "@mui/icons-material";
import EditPostsDialog from "../../post/EditPostsDialog";
import { openDialog } from "../../../features/dialog";
import PostRow from "../../post/PostRow";
import { numberFormat } from "highcharts";
import PostSortingLabel from "../../post/PostSortingLabel";

function ProjectFeedTab({tab, project}){
    const dispatch = useDispatch();
    const viewType = useSelector(state => state.post.viewType);
    // Selected posts uuids
    const selectedPosts = useSelector(state => state.post.selected);
    // Are we selecting posts?
    const selecting = selectedPosts && selectedPosts.length > 0;

    const loading = useSelector(state => state.post.loading);
    const posts = useSelector(state => state.post.filteredList);
    const total = useSelector(state => state.post.filteredListTotal);
    const offset = useSelector(state => state.post.filteredListOffset) || 0;
    const limit = useSelector(state => state.post.filteredListLimit) || 100;

    const totalPages = Math.ceil(total/limit);
    const currentPage = offset > 0 ? (offset/limit) + 1 : 1;
    const [page, setPage] = useState(currentPage);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    const handleViewTypeClick = () => {
        dispatch(toggleViewType());
    } 

    const loadPage = page => {
        const filters = tab.config.filters || {};
        dispatch(searchPost({
            ...filters,
            offset: (page-1) * limit,
            project_id: project.id,
            tab_id:tab.id
        }));
    }

    useEffect(() => {
        loadPage(1);
    }, [tab.config.filters]);
    
    const handleClearSelection = () => {
        dispatch(clearSelection());
    }

    useEffect(() => {
        // loadPage(1);
        return () => {
            dispatch(clearPosts())
        }
    },[])
    useEffect(() => {
        handleClearSelection();
    },[project])

    const handleChangePage = (event, _page) => {
        setPage(_page);
        loadPage(_page);
    }

    const handleEmpty = () => {
        try{
           dispatch(clearPosts())
        }catch(E){
            
        }
    }
    const totalFormatted = numberFormat(total,0,".",",");

    const isGrid = viewType !== "list";
    const paginationHeader = <Grid container justifyContent={"space-between"} alignItems="center">
        <Grid item display={"flex"} justifyContent="center" alignItems={"center"}>
            {posts && posts.length > 0 && <Tooltip title={"Switch to " + (isGrid ? "list" : "grid") + " view"}>
                <IconButton onClick={handleViewTypeClick} size="small">
                    {isGrid ? <>
                        <ViewList size="small"/>
                    </> : <>
                        <GridView size="small"/>
                    </>}
                </IconButton>
            </Tooltip> }
            {posts && posts.length > 0 && <Typography variant="body2">
                Displaying posts from {offset+1} to {Math.min(offset+limit, total)} of <b>{totalFormatted}</b>
                &nbsp; 
                sorted by {<PostSortingLabel tab={tab} />}
                {/* , newest first */}
            </Typography> }
        </Grid>
        <Grid item>
            {posts && totalPages > 1 &&  <Stack spacing={2}>
                <Pagination color="secondary" page={page} count={totalPages} onChange={handleChangePage}/>
            </Stack> }
        </Grid>
    </Grid>;

// console.log(totalPages);
    return <Box mt={2}>
        <EditPostsDialog />

        {paginationHeader}

        {selecting && <Paper elevation={6} sx={{ width: '50%', left: "25%", bottom: 0, position: 'fixed'}}>
            <Alert variant="filled" color="primary" action={
                <Box gap={2} display="flex">
                    <Button color="secondary" size="small" onClick={() => dispatch(openDialog("editPosts"))}>
                        <Edit sx={{mr: 1}} fontSize="small"></Edit>
                        edit
                    </Button>
                    <Button color="secondary" size="small" onClick={() => dispatch(deletePosts())}>
                        <Delete sx={{mr: 1}} fontSize="small" />
                        trash
                    </Button>
                </Box>
            }>
                <span><b>{selectedPosts.length} posts</b> selected</span>
                <b onClick={handleClearSelection} style={{textDecoration: 'underline', cursor: 'pointer', marginLeft: 10}}>undo</b>
            </Alert>
        </Paper>}

        {loading && <LinearProgress sx={{mt: 2, mb: 2}}/>}
        {posts && posts.length === 0 && !loading && <>
            <Paper elevation={2}>
                <Box sx={{height: 200}} display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography variant="h4" color="primary.dark">No posts found</Typography>
                </Box>
            </Paper>
        </>}
        {isGrid && posts && posts.length > 0 && <Masonry columns={5} spacing={2} sx={{mt:2}}>
            {!loading && posts.map(post => {
                return <Box minHeight={220}key={post.uuid}><PostCard post={post} /></Box>
            })}
        </Masonry>}
        {!isGrid && posts && posts.length > 0 && <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Post</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Sentiment</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Language</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {posts.map((p, index) => <PostRow key={index} project={project} post={p} />)}
            </TableBody>
        </Table>}
        <Box mt={2}>
            {paginationHeader}
        </Box>
    </Box>
}

export default ProjectFeedTab;