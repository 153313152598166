import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ProjectCard({project}){
    const [elevation, setElevation] = useState(1);
    const navigate = useNavigate();
    
    const handleOpen = () => {
        navigate("/project/" + project.label)
    }
    return <Card 
        sx={{cursor: 'pointer'}} 
        elevation={elevation} 
        onMouseOver={() => setElevation(3)} 
        onMouseOut={() => setElevation(1)}
        onClick={handleOpen}
    >
        <CardMedia
            component="img"
            height="130"
            image="/assets/img/placeholder.jpg"
            alt={project.name}
        />
        
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {project.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {project.postCount || "0"} posts
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {project.jobCount || "0"} active jobs
            </Typography>
        </CardContent>
    </Card>
}
export default ProjectCard;