import { Grid, TextField } from "@mui/material";
import BooleanSelect from "../common/BooleanSelect";
import UserTierSelect from "../common/UserTierSelect";

function UserForm({form, onChange, type="admin"}){
    const isSelf = type==="self";
    return <>
        <Grid container spacing={3}>
            <Grid item sm={isSelf ? 12 : 6}>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    size="small"
                    label="Full name"
                    value={form.user_full_name || ""}
                    onChange={e => onChange({...form, user_full_name: e.target.value})}
                />
            </Grid>
            <Grid item sm={isSelf ? 12 : 6}>
                <TextField
                    fullWidth
                    size="small"
                    type={"email"}
                    required
                    label="Email"
                    value={form.email || ""}
                    onChange={e => onChange({...form, email: e.target.value})}
                />
            </Grid>
            {!isSelf && <Grid item sm={6}>
                <UserTierSelect
                    required
                    fullWidth
                    value={form.tier || ""}
                    onChange={tier => onChange({...form, tier})}
                />
            </Grid> }
            {!isSelf && <Grid item sm={6}>
                <BooleanSelect
                    required
                    label="Active"
                    fullWidth
                    value={form.active || ""}
                    onChange={active => onChange({...form, active})}
                />
            </Grid> }
        </Grid>
    </>
}
export default UserForm;