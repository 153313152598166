import { Alert, Box, Button, FormGroup, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleProjectStatus } from "../../features/project";
import { enqueueSuccessSnack } from "../../features/snackbar";

function ToggleProjectStatusForm({form, onChange, errors={}}){
    const [loading, setLoading] = useState(false);
    const {archived} = (form || {});
    const dispatch = useDispatch();

    const handleSubmit = e => {
        setLoading(true);
        e.preventDefault();
        dispatch(toggleProjectStatus(form)).then(r => {
            dispatch(enqueueSuccessSnack("Project " + (form.archived ? "activated" : "archived")));
            setLoading(false)
        });
    }


    return <>
        <Alert severity={archived ? "error" : "success"}>
            <Typography variant="subtitle1">
                Your project is currently <b>{archived ? "archived" : "active"}</b>.
            </Typography>
            <Typography variant="subtitle2">
                Archived projects no longer run jobs but the stored data remains available.
            </Typography>
        </Alert>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4}}>
            {!loading && <Button variant="contained" color={archived ? "secondary" : "error"} type="submit" onClick={handleSubmit}>
                {archived ? "Reactivate" : "Archive"} project
            </Button>}
            {loading && <LinearProgress />}
        </Box>
    </>
}
export default ToggleProjectStatusForm;