import axios from "axios";
import { useEffect, useState } from "react";

function SlotRow({report, row, index, onHide}){
    const [image, setImage] = useState();
    useEffect(() => {
        if(report && index){
            axios.post("/report/slotPreview", {
                report_id: report.id,
                slot: {
                    contentConfig: row.contentConfig,
                    query: report.config.query
                }
            }).then(r => {
                if(r.indexOf("/")===0){
                    setImage(r);
                }
            })
        }
    },[report, index]);

    return <tr onClick={() => onHide(index)}>
        <td style={{padding: "0 10px 0 10px;"}}>
            {image && <img src={image} style={{width: '100%', height: 'auto' }} />}
        </td>
    </tr>;
}

export default SlotRow;