import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function TagGroupSelect({
    value, 
    onChange,
    size,
    label="Tag Group",
    placeholder="",
    helperText,
    error=false,
    disabled=false, 
    displayEmpty=true,
    required=true
}){
    const groups = useSelector(state => state.tag.groups);
    const _options = required ? [...groups] : [
        {id: '', label: 'Any'},
        ...groups
    ];

    
    return<FormControl 
        sx={{minWidth: 150}}
        error={error}
        fullWidth 
        size="small"
    >
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error}>{label} </InputLabel>
        <Select 
            error={error}
            disabled={disabled}
            size={size}
            placeholder={placeholder}
            required={required}
            displayEmpty={displayEmpty}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(v){
                    const o = _options.find(o => o.id === v || o.name === v || o.label === v);
                    return o.name || o.label;
                 }
                 return placeholder;
            }}
        >
            {_options.map(o => { 
                const id = typeof o.id !== "undefined" ? o.id : o.name || o.label;
                // console.log(id)
                return <MenuItem value={id} key={id}>{o.name || o.label}</MenuItem>            
            })}

        </Select>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default TagGroupSelect;