import { Box, Grid, LinearProgress, Pagination, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobTable from "../../components/job/JobTable";
import JobButtonBar from "../../components/job/JobButtonBar";
import MainContainer from "../../containers/MainContainer";
import ActivateJobDialog from "../../components/job/dialog/ActivateJobDialog";
import DeactivateJobDialog from "../../components/job/dialog/DeactivateJobDialog";
import DeleteJobDialog from "../../components/job/dialog/DeleteJobDialog";
import EditJobDialog from "../../components/job/dialog/EditJobDialog";
import NewJobDialog from "../../components/job/dialog/NewJobDialog";
import { allJobs } from "../../features/job";
import { numberFormat } from "highcharts";

function JobsAdminPage(){
    const dispatch = useDispatch();
    const jobs = useSelector(state => state.job.all);
    const toggleCount = useSelector(state => state.job.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, _setSearch] = useState("");
    const limit = 10;
    const [page, setPage] = useState(1);
    const offset = (page-1) * limit;
    
    const filteredJobs = (jobs || [])
    .filter(j => {
        const res = !search || j.label.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });
    const pagedJobs = filteredJobs.slice(offset, offset+limit);
    const total = filteredJobs.length;
    const totalPages = Math.ceil(total/limit);

    const setSearch = s => {
        _setSearch(s);
        setPage(1);
    }

    const handleChangePage = (event, _page) => {
        setPage(_page);
    }

    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!jobs.find(r => r.id === s);
        }));
    }, [jobs]);
    
    useEffect(() => {
        setSelection([]);
    },[toggleCount]);

    useEffect(() => {
        dispatch(allJobs());
    }, []);
    const totalFormatted = numberFormat(total,0,".",",");

    const paginationHeader = <Grid container justifyContent={"space-between"} alignItems="center" mt={2}>
        <Grid item display={"flex"} justifyContent="center" alignItems={"center"}>

            {filteredJobs && filteredJobs.length > 0 && <Typography variant="body2">
                Displaying jobs from {offset+1} to {Math.min(offset+limit, total)} of <b>{totalFormatted}</b>
            </Typography> }
        </Grid>
        <Grid item>
            {filteredJobs && totalPages > 1 &&  <Stack spacing={2}>
                <Pagination color="secondary" page={page} count={totalPages} onChange={handleChangePage}/>
            </Stack> }
        </Grid>
    </Grid>;

    return <MainContainer>

        <DeleteJobDialog />
        <ActivateJobDialog />
        <DeactivateJobDialog />
        <EditJobDialog />
        <NewJobDialog />
        <Typography sx={{mb: 4}} color="primary" variant="h2">Jobs</Typography>

        <JobButtonBar selection={selection} search={search} setSearch={setSearch} />
        {jobs === null && <>
            <LinearProgress />
        </>}

        {paginationHeader}

        {filteredJobs && filteredJobs.length === 0 && <>
            
            <Paper elevation={2} sx={{mt: 4}}>
                <Box sx={{height: 200}} display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography variant="h4" color="primary.dark">No jobs found</Typography>
                </Box>
            </Paper>
        </>}

        {jobs && jobs.length > 0 && <>
            <JobTable jobs={pagedJobs} selection={selection} setSelection={setSelection} />
        </>}
        
    </MainContainer>
}
export default JobsAdminPage;