import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../../../features/snackbar';
import { editSource } from '../../../../features/source';
import SourceForm from '../SourceForm';
import { validateProjectSource } from '../../../../validator/project';
import { hasErrors } from '../../../../common';

const DIALOG_ID = "editSource"
const DIALOG_WIDTH = "md"

function EditSourceDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const sources = useSelector(state => state.source.list);
    const project = useSelector(state => state.project.single);
    const sourceId = useSelector((state) => state.dialog[DIALOG_ID])
    const show = !!sourceId;
    const source = sourceId ? sources.find(j => j.id === sourceId) : null;
    const [errors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateProjectSource(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(editSource({source: form, project}))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Source saved"))    
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm(source);
    }, [source, sourceId]);

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Edit source
            </DialogTitle>
            <DialogContent dividers={false}>
                <Box pr={3}>
                    <SourceForm form={form || source || {}} onChange={setForm} errors={errors} />
                </Box>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Save Source</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditSourceDialog;