const fields = {
    "jobId": 'Job Id',
    "user_screenname": "User name",
    "user_id": "User ID",
    "title": "Post Title",
    "post_text": "Post Text",
    "domain": "Domain",
    "lang": "Language",
    "platform": "Platform",
    "sentiment": "Sentiment",
};
export default fields;