import { Box, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDialog } from "../../features/dialog";

function GroupButtonBar({selection, search, setSearch}){
    const dispatch = useDispatch();

    return <Box justifyContent="space-between" display="flex">
    <Box flexGrow={0}>
        <TextField
            size="small"
            fullWidth
            autoFocus
            placeholder="Search group"
            value={search}
            label="Filter by name"
            onChange={e => setSearch(e.target.value)}
            InputLabelProps={{
                shrink: true
            }}
        />
    </Box>
    <Box display="flex" gap={2} alignItems="center">

    </Box>
</Box>
}
export default GroupButtonBar;