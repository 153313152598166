import { useSelector } from "react-redux";

import { Box, IconButton, ListSubheader, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectTabLabel from "./ProjectTabLabel";
import { DragDropContext, Droppable, Draggable,resetServerContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { reorderTabs } from "../../features/project";
import { Add, Tune } from "@mui/icons-material";
import { openDialog } from "../../features/dialog";

resetServerContext()

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function AddMenu(){
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (dialog) => {
        handleClose();
        dispatch(openDialog(dialog));
    }
    return <Box 
        alignItems="center" 
        display="flex" 
        flexDirection="column" 
        sx={{
            mt: 1,
            p: 1,
            pt: 2.5,
            ml: 2.5,
        }}
    >
        <IconButton
            aria-controls={open ? 'add-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <Add color="primary" fontSize={"medium"} />
        </IconButton>
        <Menu
            id="add-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'add-button',
            }}
        >
            <ListSubheader title="Create new" sx={{fontWeight: 'bold', fontSize: '100%'}}>Create New</ListSubheader>
            <MenuItem onClick={() => handleMenuClick("editDashboard")}>Dashboard</MenuItem>
            <MenuItem onClick={() => handleMenuClick("newReport")}>Report</MenuItem>
            <MenuItem onClick={() => handleMenuClick("newAlert")}>Alert</MenuItem>
        </Menu>

    </Box>
    
}

function ProjectTabs({ activeTab, project }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [tabs, setTabs] = useState([]);

    const user = useSelector(state => state.user.profile);

    useEffect(() => {
        let _tabs = [...project.tabs];
        setTabs(_tabs);
    }, [project.tabs])

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            tabs,
            result.source.index,
            result.destination.index
        );
        dispatch(reorderTabs({
            project_id: project.id,
            tabs: items.map(i => i.id)
        }))

        setTabs(items);
    }

    const clickTab = useCallback((tabId) => {
        navigate("/project/" + project.label + "/" + tabId);
    }, [project.label, navigate])

    return <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
        borderBottom: 1,
        borderBottomColor: 'primary'
    }}>
        <Box display="flex">

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <Box 
                            display="flex" 
                            flexDirection={"rows"} 
                            mt={1}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {tabs.map((tab, index) => (
                                <Draggable key={tab.id} draggableId={tab.id + ""} index={index}>
                                    {(provided, snapshot) => (
                                        <ProjectTabLabel
                                            innerRef={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => clickTab(tab.id)}
                                            key={tab.id}
                                            index={index}
                                            id={tab.id}
                                            tab={tab}
                                            activeTab={activeTab}
                                        />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
            
            <AddMenu />
        </Box>
      {
        user.tier < 4 ? <>
        {/* hide edit project from base users */}
        <Box 
            // onClick={() => dispatch(openDialog("editProject"))}
            onClick={() => navigate("/project/" + project.label + "/edit")}
            alignItems="center" 
            display="flex" 
            flexDirection="column" 
            sx={{
                bgcolor: 'primary.main',
                mt: 1,
                p: 1,
                cursor: 'pointer'
            }}
        >
            <IconButton><Tune htmlColor={theme.palette.primary.contrastText} fontSize={"medium"} /></IconButton>
            <Typography  mt={1} textAlign="center" color={theme.palette.primary.contrastText } textTransform={"uppercase"} variant="subtitle2">Edit project</Typography>
        </Box>

        </>:<></>
      }
        
    </Box>
}
export default ProjectTabs;