import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Input, ListSubheader } from '@mui/material';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ProjectSelect({
    label="Project", 
    required=true,
    error=false,
    fullWidth=false,
    displayEmpty=false,
    placeholder="",
    size="small",
    onChange, 
    value
}) {
    const projects = useSelector(state => state.project.list) || [];

    const handleDelete = (toRemove) => {
        const val = typeof value === 'string' ? value.split(',') : value;
        onChange(val.filter(v => v !== toRemove))
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl size={size} fullWidth={fullWidth}>
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
            <Select
                displayEmpty={displayEmpty}
                required={required} 
                size={size}
                variant="standard"
                fullWidth={fullWidth}
                multiple
                value={value}
                onChange={handleChange}
                input={<Input label={label} />}
                renderValue={(selected) => {

                    if((!selected || selected.length === 0) && displayEmpty){
                        return placeholder;
                    }
                    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((_project) => {
                            const projectId = _project.id || _project;
                            const project = projects.find(p => p.id === projectId)
                            return <Chip 
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onDelete={() => handleDelete(project.id)} 
                                key={project.id} 
                                label={project.name} 
                                size={size} 
                            />
                        })}
                    </Box>
                }}
                MenuProps={MenuProps}
            >   
                {projects.map((project) => {
                    return <MenuItem
                        key={project.id}
                        value={project.id}
                    >
                        {project.name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    );
}
