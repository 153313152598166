import { FormControlLabel, Grid, Radio, RadioGroup, Slider, TextField, Typography } from "@mui/material";
import { intervalLabel } from "../../common";

function DashboardFormTimeSpan({form, onChange, wizard=false, errors={}}){
    const config = form.config || {};
    const filters = config.filters || {};
    const date = filters.date || {};
    const type = config.spanType || "fixed";

    const updateFilter = (field, value) => {
        onChange({
            ...form, 
            config: {
                ...config, 
                filters: {
                    ...filters, 
                    [field]: value
                }
            }
        });
    }

    return <>
        {wizard && <Typography color="primary.main" variant="h4">Select your time interval</Typography>}
        {wizard && <Typography variant="subtitle1">You can change it anytime</Typography>}
        <RadioGroup
            value={type}
            onChange={(e) => {
                const spanType = e.target.value;
                const removeField = spanType==="fixed" ? "interval" : "date";
                delete filters[removeField];
                onChange({
                    ...form, 
                    config:{
                        ...config, 
                        spanType,
                        filters: filters
                    }
                })
            }}
        >
            <FormControlLabel value="fixed" control={<Radio />} label="Fixed: a specific time interval" />
            <FormControlLabel value="dynamic" control={<Radio />} label="Dynamic: interval changes with time" />
        </RadioGroup>
        {type==="dynamic" && <>
            <Grid container spacing={2} mt={1} mb={2}>
                <Grid item sm={12}>
                    <Typography style={{cursor: 'pointer'}} color="primary.main" variant="h6">Display data from the last:</Typography>
                    <Slider
                        valueLabelDisplay="on"
                        marks={[{
                            value: 1,
                            label: 'a day'
                        },{
                            value: 360,
                            label: 'a year'
                        }]}
                        value={filters.interval || 1}
                        min={1}
                        step={1}
                        max={360}
                        getAriaValueText={value => intervalLabel(value)}
                        valueLabelFormat={value => intervalLabel(value)}
                        onChange={e => updateFilter('interval', e.target.value)}
                        
                    />
                </Grid>
            </Grid>
        </>}

        {type==="fixed" && <>
            <Grid container spacing={2} mt={1} mb={2}>
                <Grid item sm={6}>
                    <TextField
                        autoFocus
                        fullWidth
                        error={errors.start}
                        size="small"
                        type="date"
                        value={date.start || ""}
                        onChange={e => updateFilter('date',{...date, start: e.target.value})}
                        InputLabelProps={{ shrink: true }}
                        label="Beginning"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        error={errors.end}
                        size="small"
                        type="date"
                        value={date.end || ""}

                        onChange={e => updateFilter('date',{...date, end: e.target.value})}
                        label="End"
                    />
                </Grid>
            </Grid>
        </>}

    </>
}
export default DashboardFormTimeSpan;