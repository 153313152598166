
function SmallRow({report, row, index, onHide}){
    return <tr onClick={() => onHide(index)}>
        <td style={{padding: "0 10px 0 10px"}}>
            <p
                style={{
                    fontFamily: report.config.template.templateFonts.text,
                    fontWeight: 'normal',
                    fontSize: 10,
                    color: report.config.template.templateColors.text
                }}
            >
                {row.content}
            </p>
        </td>
    </tr>;
}
export default SmallRow;