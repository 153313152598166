const sentiments = {
    // "any":"Any",
    "pos": 'Positive',
    "neu": 'Neutral',
    "neg": 'Negative',
    "null": 'N/A',
};

const sentimentTags = [1000,1001,1002,1003];

const getSentimentLabel = (id) => {
    const label = sentiments[id];
    return label ? label.replace("--","").trim() : ""
}

export default sentiments;
export {getSentimentLabel, sentimentTags};
