import { Comment, ThumbUp, Reply, Facebook } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
// import { findAllByPlaceholderText } from "@testing-library/react";
import React from "react";
import { parseURL } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function FacebookPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    const title = "@" + post.user_screenname;
    return <>
        <Box p={padding}>
            <PostTitleElement title={title} link={"http://facebook.com/profile.php?id=" + post.user_id} />
        </Box>
        <PostImageElement post={post} />
        <Box p={padding}>
            {/* THIS BREAKS THINGS */}
            <PostTextElement post={post} text={text} truncate={200} /> 
            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">
            <Box display="flex" alignItems={"center"} mr={1}>
                <ThumbUp sx={{marginRight: 0.5}} fontSize="small"/>
                {post.fb_likes}

                <Comment sx={{ fontSize: 15, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 15}}>{post.fb_comments}</span>

                <Reply sx={{ fontSize: 15, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 15}}>{post.fb_shares}</span>
            </Box>
            <Facebook sx={{color: '#4267B2'}}/>
        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default FacebookPostCard;