import { useState } from "react"

function PostImageElement({post, title}){
    const [error, setError] = useState(false);

    if(error){
        return null;
    }
    return <>
        {post.img && <a href={post.full_uri} target="_blank" rel="noreferrer"><img onError={() => setError(true)} alt={title || post.post_title || post.title || post.user_screenname} src={post.img} style={{maxWidth: '100%'}}/></a>}
    </>
}
export default PostImageElement;