import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateTab } from "../../features/project";

function PostSortingLabel({tab}){
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setOrderBy = newOrder => {
        handleClose();

        dispatch(updateTab({
            id: tab.id,
            filters: {
                ...(tab.config.filters || {}),
                orderBy: newOrder
            }
        }));
    }
  
    const orderBy = tab.config.filters && tab.config.filters.orderBy;
    const orderByString = orderBy === "engagement" ? orderBy : "date";
    return <>
        <span
            aria-controls={open ? 'sort-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className="link">
                {orderByString}
        </span>
        , {orderBy==="engagement" ? "most populars first" : "newest first"}
        <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={() => setOrderBy("post_time")}>Date</MenuItem>
            <MenuItem onClick={() => setOrderBy("engagement")}>Engagement</MenuItem>
        </Menu>

    </>
}

export default PostSortingLabel;