import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProject, loadProjectList } from "../../../features/project";
import { enqueueSuccessSnack } from "../../../features/snackbar";

function ProjectReviewStep({
    handleNext,
    form
}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setLoading(true);
        dispatch(createProject(form))
        .then(r => {
            const {project} = r.payload;
            if(project && project.id){
                dispatch(enqueueSuccessSnack("Project created"));
                dispatch(loadProjectList());
                navigate("/project/" + project.label);
            }
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }
    return <Box gap={4} display="flex" flexDirection={"column"}>
        {loading && <>
            <Typography variant="subtitle1" mt={4} mb={4}>Creating project</Typography>
            <CircularProgress />
        </>}

        {!loading && <>
        <Typography variant="subtitle1" mt={4} mb={4}>
            Your basic project setup is complete. <br/>
            By closing this procedure the jobs you created will be enqueued and content will start getting collected, tagged and saved into your project for analysis.
        </Typography>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4}}>
            <Button variant="contained" color="secondary" type="button" onClick={handleSave}>Start Project</Button>
        </Box>
        </>}
    </Box>
}
export default ProjectReviewStep;