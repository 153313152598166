import { Grid, TextField } from "@mui/material";

import HelpAdornment from "../common/HelpAdornment";
import ReportTypeSelect from "../common/ReportTypeSelect";


function ReportForm({form, onChange, errors, hideType=false}){
    const reportForm = form || {};
    const setReportForm = f => {
        onChange && onChange(f);
    }

    return <Grid container spacing={4} columnSpacing={6}>
        <Grid item xs={12}>
            <TextField
                fullWidth
                required
                autoFocus
                error={!!errors.title}
                variant="standard"
                label="Report name"
                helperText={"The name you're identifying the report with"}
                value={reportForm.title || ""}
                onChange={e => setReportForm({...reportForm, title: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"reportTitle"} />
                }}

            />
        </Grid>
        {!hideType && <Grid item xs={6}>
            <ReportTypeSelect
                error={!!errors.type}
                value={reportForm.type}
                onChange={type => setReportForm({...reportForm, type})}

            />
        </Grid>}
    </Grid>
}

export default ReportForm;