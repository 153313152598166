import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { deactivateUser } from '../../features/user';

const DIALOG_ID = "deactivateUser"
const DIALOG_WIDTH = "sm"

function DeactivateUserDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const userId = useSelector((state) => state.dialog[DIALOG_ID])
    const users = useSelector(state => state.user.list || state.user.all);
    const user = users && users.find(el => el.userid === userId);

    const userLabel =  Array.isArray(userId) ? "selected" : (user ? user.user_full_name : "");
    const show = !!userId;

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(deactivateUser(Array.isArray(userId) ? userId : [userId]))
        .then(r => {
            if(r && r.payload){
                dispatch(closeDialog(DIALOG_ID));
                dispatch(enqueueSuccessSnack("User deactivated"))
            }
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    // useEffect(() => {
    //     setLoading(false);
    // }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle>
                Deactivate user
            </DialogTitle>
            <DialogContent dividers={false}>
                <Typography variant="subtitle1">Are you sure you want to deactivate the <b>{userLabel}</b> user?</Typography>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="warning" type="submit">Deactivate</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default DeactivateUserDialog;