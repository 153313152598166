import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sentimentTags } from "../../config/sentiments";
import { openDialog } from "../../features/dialog";
import TagGroupSelect from "../common/TagGroupSelect";
import DeleteTagDialog from "./DeleteTagDialog";
import DeleteTagRuleDialog from "./DeleteTagRuleDialog";
import EditTagDialog from "./EditTagDialog";
import EditTagGroupDialog from "./EditTagGroupDialog";
import EditTagRulesDialog from "./EditTagRulesDialog";
import NewTagDialog from "./NewTagDialog";
import NewTagGroupDialog from "./NewTagGroupDialog";

function TagManagement({sentimentView=false}){
    const tags = useSelector(state => state.tag.list);
    const [selection, setSelection] = useState([]);

    const [folder, setFolder] = useState("");
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const filteredTags = (tags || [])
    .filter(t => {
        const res = !folder || t.folder.id === folder;
        return res;
    })
    .filter(t => {
        const res = !search || t.label.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    })
    .filter(t => {
        const isSentimentTag = sentimentTags.indexOf(t.id) !== -1;
        return sentimentView ? isSentimentTag : !isSentimentTag;
    })
    ;
    
    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    
    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!tags.find(r => r.id === s);
        }));
    }, [tags]);
    return <>
        <NewTagGroupDialog />
        <EditTagGroupDialog />

        <NewTagDialog />
        <EditTagDialog />
        <DeleteTagDialog />
        <EditTagRulesDialog />
        <DeleteTagRuleDialog />
        {!sentimentView && <Box justifyContent="space-between" display="flex">
            <Box display="flex" gap={4}>

                <TextField
                    size="small"
                    fullWidth
                    autoFocus
                    placeholder="Search tag name"
                    value={search}
                    label="Filter by name"
                    onChange={e => setSearch(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

                <TagGroupSelect
                    required={false}
                    placeholder="Select tag folder"
                    label="Filter by folder"
                    displayEmpty
                    value={folder}
                    onChange={e => setFolder(e)}
                />
            </Box>
            <Box display="flex" gap={2} alignItems="center">
            {selection && selection.length > 0 && <Button 
                    color="error" 
                    variant="outlined"
                    onClick={() => {
                        dispatch(openDialog({id: "deleteTag", props: selection.length === 1 ? selection[0] : selection}));
                    }}
                >Delete selected</Button> }
                <Button  variant="contained" color="secondary" onClick={() => dispatch(openDialog("newTag"))}>Add Tag</Button>
                <Button  variant="contained" color="secondary" onClick={() => dispatch(openDialog("newTagGroup"))}>Add Folder</Button>
            </Box>
        </Box>}
        <Table size="small">
            <TableHead>
                <TableRow>
                    {!sentimentView && <TableCell>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != filteredTags.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(filteredTags.map(r => r.id));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell> }
                    <TableCell>Tag</TableCell>
                    <TableCell>Folder</TableCell>
                    <TableCell>Rules</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredTags.map((tag,i) => {
                    const selected = selection.indexOf(tag.id) !== -1;
                    return <TableRow key={i} hover>
                        {!sentimentView && <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(tag.id)} />
                        </TableCell>}
                        <TableCell>{tag.label}</TableCell>
                        <TableCell><span onClick={() => dispatch(openDialog({
                            id: 'editTagGroup',
                            props: tag.folder
                        }))} className="link">{tag.folder ? tag.folder.label : "--"}</span></TableCell>
                        <TableCell><span className="link" onClick={() => {
                            dispatch(openDialog({id: "editTagRules", props: tag.id}));
                        }}>
                            {!tag.rules || tag.rules.length === 0 ? <>
                                No rules
                            </> : <>
                                {tag.rules.length} rule(s)
                            </>}
                        </span></TableCell>
                        <TableCell align="right">
                            <IconButton size="small" onClick={() => {
                                dispatch(openDialog({id: "editTag", props: tag.id}));
                            }}><Edit /></IconButton>
                            {!sentimentView && <IconButton size="small"  onClick={() => {
                                dispatch(openDialog({id: "deleteTag", props: tag.id}));
                            }}><Delete /></IconButton> }
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}
export default TagManagement;