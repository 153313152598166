import { Autorenew, Favorite, Reviews } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import { parseURL } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function ReviewsPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    const title = post.title || post.user_screenname;
    return <>
        <Box p={padding}>
            <PostTitleElement title={title} post={post} />
        </Box>

        <PostImageElement post={post}/>
        
        <Box p={padding}>
            <PostTextElement text={text} post={post} />

            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">

            {/* <Box display="flex" alignItems={"center"} mr={1}>
                <Favorite sx={{color: '#CC0000', marginRight: 0.5}} fontSize="small"/>
                {post.tw_likes}

                <Autorenew sx={{ fontSize: 12, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.tw_rts}</span>
            </Box> */}

            <Reviews sx={{color: '#0e76a8'}}/>

        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default ReviewsPostCard;