import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent, CardHeader, IconButton, Stack } from "@mui/material";
import { openDialog } from "../../../features/dialog";
import React, { lazy, useEffect, useState } from "react";
import { loadConfig, updateElementSettings } from "../../../features/dashboard";
import { Settings } from "@mui/icons-material";
import WidgetSettingsDialog from "../../dashboard/builder/WidgetSettingsDialog";
import { hasProperties } from "../../../common";
import ErrorBoundary from "../../../containers/ErrorBoundary";

function ProjectDashboardTab({ tab, project }) {
    const dispatch = useDispatch();
    const [currentConfig, setCurrentConfig] = useState(null);
    const config = tab.config || {};
    const elements = config.elements || [];
    const widgetConfigs = useSelector(state => state.dashboard.config);
    const [views, setViews] = useState([]);
    const importView = el => {
        return lazy(() =>
            import(`./elements/${el.id}`).catch(() =>
                import(`./elements/NullView`)
            )
        );
    }

    useEffect(() => {
        async function loadViews() {
            const componentPromises = elements.map(async (el, index) => {
                const hasConfig = widgetConfigs && widgetConfigs.find(c => c.id === el.id)
                const config = hasConfig ? hasConfig.config : null;
                const View = await importView(el);

                
                return <Card key={index} sx={{mb: 2}} elevation={4}>
                    <CardHeader title={el.title} action={
                    <>
                        {config && hasProperties(config) && <IconButton onClick={() => {
                            setCurrentConfig({
                                el,
                                index,
                                config: config
                            })
                        }}>
                            <Settings />    
                        </IconButton>}
                    </>
                    }/>
                    <CardContent>
                        <ErrorBoundary>
                            <View project={project} tab={tab} element={el} elementIndex={index}/>
                        </ErrorBoundary>
                    </CardContent>
                </Card>
            });

            Promise.all(componentPromises)
            .then(setViews)
            .catch(E => {
                console.error(E);
            });
        }
        if(elements.length > 0)
            loadViews();
    }, [elements, widgetConfigs]);


    useEffect(() => {
        if(!widgetConfigs && dispatch){
            dispatch(loadConfig(project.label));
        }
    }, [widgetConfigs, dispatch])

    return <Box mt={2}>

        <WidgetSettingsDialog 
            open={!!currentConfig} 
            onClose={() => setCurrentConfig(null)} 
            onSave={data => {
                dispatch(updateElementSettings({
                    elementIndex: currentConfig.index,
                    settings: data,
                    tabId: tab.id
                }));
                setCurrentConfig(null);
            }}
            config={currentConfig && currentConfig.config}
            settings={currentConfig && currentConfig.el.settings}
            
        />

        {(!elements || elements.length === 0) && <Stack alignItems="center" justifyContent={"center"}>
            <p>No elements defined.</p>
            <Button onClick={() => dispatch(openDialog({
                id: 'editDashboard',
                props: tab.id
            }))} variant="contained" color="secondary">Edit dashboard</Button>

        </Stack>}

        <React.Suspense fallback='Loading dashboard...'>
            {views}
        </React.Suspense>
    </Box>
}

export default ProjectDashboardTab;