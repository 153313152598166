import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueErrorSnack, enqueueSnack } from './snackbar';
import { commonApiPromise, updateArray } from '../common';
import { updateElementSettings } from './dashboard';

export const slice = createSlice({
    name: 'project',
    initialState: {
        list: null,
        filters: {},
        terms: {},
        activeTab: 0
    },
    
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSingle: (state, action) => {
            state.single = action.payload;
        },
        setActiveTab: (state,action) => {
            state.activeTab = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(loadProjectList.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = payload.data;
        })
        
        builder.addCase(loadProjectTerms.fulfilled, (state, action) => {
            const {payload} = action;
            const terms = {...state.terms};
            const tabTerms = terms[action.meta.arg.tab.id] || {};
            tabTerms[action.meta.arg.type] = payload;
            state.terms = {...terms, [action.meta.arg.tab.id]: tabTerms};
        })
        
        builder.addCase(updateElementSettings.fulfilled, (state, action) => {
            const {payload, meta: {arg: {
                elementIndex,
                settings,
                tabId
            }}} = action;


            state.single = {
                ...state.single,
                tabs: state.single.tabs.map(tab => {
                    if(tab.id === tabId){
                        tab.config.elements[elementIndex].settings = settings;
                    }
                    return tab;
                })
            }
            state.list = updateArray(state.list, state.single);
            
        })
        builder.addCase(updateTab.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = state.list.map(proj => {
                proj.tabs = proj.tabs.map(tab => {
                    if(tab.id === payload.id){
                        return {
                            ...tab,
                            ...payload
                        }
                    }
                    return tab;
                });
                return proj;
            })
        });

        builder.addCase(loadProject.fulfilled, (state, action) => {
            state.single = action.payload;
        });

        builder.addCase(editProject.fulfilled, (state, action) => {
            state.single = {
                ...state.single || {},
                ...action.payload
            }
        });
        builder.addCase(toggleProjectStatus.fulfilled, (state, action) => {
            state.single = {
                ...state.single || {},
                archived: !action.meta.arg.archived
            }
            state.list = updateArray(state.list, state.single);
        });
        builder.addCase(deleteDashboard.fulfilled, (state, action) => {
            const id = action.meta.arg.dashboard;
            state.single = {
                ...state.single,
                tabs: state.single.tabs.filter(t => t.id !== id)
            };
        });
        builder.addCase(loadQueryData.fulfilled, (state, action) => {
            state.queryData = action.payload;
        });
        builder.addCase(loadProjectUsers.fulfilled, (state, action) => {
            state.users = action.payload.data;
        });
        builder.addCase(addProjectUser.fulfilled, (state, action) => {
            state.users = [...state.users, action.payload];
        });

        builder.addCase(saveDashboard.fulfilled, (state, action) => {
            const oldId = action.meta.arg.dashboard.id;
            const {payload} = action;
            const newProject = {
                ...state.single
            }
            if(oldId){
                newProject.tabs = updateArray(newProject.tabs, payload);
            }else{
                newProject.tabs.push(payload);
            }
            state.single = newProject;
            state.list = updateArray(state.list, newProject);
        });

        builder.addCase(reorderTabs.fulfilled, (state, action) => {
            const args = action.meta.arg;
            // const list = [...state.list];
            state.single.tabs.sort((a,b) => {
                return args.tabs.indexOf(a.id) - args.tabs.indexOf(b.id);
            })
            // state.list = list.map(p => {
            //     if(p.id === args.project_id){
            //         p.tabs.sort((a,b) => {
            //             return args.tabs.indexOf(a.id) - args.tabs.indexOf(b.id);
            //         })
            //     }
            //     return p;
            // })
            // if(state.project){
            //     state.project.
            //     state.project = state.list.find(p => p.id === state.project.id);
            // }
        })

      }
    
})

export const saveDashboard = createAsyncThunk('project/saveDashboard', async (data, store) => {
    const {project, dashboard} = data;
    return axios.post("/project/savedashboard/" + project.id, dashboard)
    .then(r => {
        return r.data;
    })
    .catch(E => {
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    })
});

export const loadQueryData = createAsyncThunk('project/loadQueryData', async (data, store) => {
    const {project, tab} = data;
    return commonApiPromise(axios.post("/chart/querydata", {
        project_id: project.id,
        tab_id: tab.id,
        ...tab.config.filters || {}
    }), store);
});

export const loadProjectTerms = createAsyncThunk('project/loadTerms', async (data, store) => {
    const {project, type, tab, limit} = data;
    const filters = (tab && tab.config && tab.config.filters) || {}
    let promise;
    if(type === "user"){
        promise = axios.post("/chart/multiradar",{limit: 10, project_id: project.id, radarColumn: 'user_screenname', ...filters})
        .then(r => {
            const _users = {};
            r.data.axes.forEach((name,index) => {
                _users[name] = r.data.values[index];
            })
            return _users;
        })
    }else{
        promise = axios.post("/chart/dendro" + type + "s", {
            project_id: project.id, 
            limit: limit || 10,
            plain: true,
            ...filters
        })
        .then(r => {
            return r.data;
        })
    }
    return promise.catch(E => {
        store.dispatch(enqueueErrorSnack(E))
    })
});


export const deleteDashboard = createAsyncThunk('project/deleteDashboard', async (data, store) => {
    const {project, dashboard} = data;
    return axios.post("/project/deletedashboard/" + project.id, {dashboard})
    .then(r => {
        return r.data;
    })
    .catch(E => {
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    })
});


export const loadProjectUsers = createAsyncThunk('project/loadUsers', async (projectId, store) => {
    return commonApiPromise(axios.get("/project/users/" + projectId), store)
});

export const addProjectUser = createAsyncThunk('project/addUser', async (data, store) => {
    const {project, user} = data;
    return commonApiPromise(axios.post("/project/newuser/" + project.id, {user}), store)
});

export const updateTab = createAsyncThunk('project/updateTab', async (data, store) => {
    try{
        const response = await axios.post("/project/updateTab", data);
        return response.data;
    }catch(E){
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    }
});

export const editProject = createAsyncThunk('project/edit', async (data, store) => {
    try{
        const response = await axios.post("/project/edit", data);
        return response.data;
    }catch(E){
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    }
});

export const toggleProjectStatus = createAsyncThunk('project/toggleStatus', async (project, store) => {
    const {archived, id} = project;
    const endpoint = archived ? "thaw" : "freeze";
    try{
        const response = await axios.post("/project/" + endpoint + "/" + id);
        return response.data;
    }catch(E){
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    }
});

export const createProject = createAsyncThunk('project/create', async (data, store) => {
    try{
        const response = await axios.post("/project/wizard/", data);
        return response.data;
    }catch(E){
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    }
});

export const reorderTabs = createAsyncThunk('project/reorderTabs', async (data, store) => {
    try{
        const response = await axios.post("/project/reorderTabs", data);
        return response.data;
    }catch(E){
        store.dispatch(enqueueSnack({
            message: E,
            options: {
                variant: 'error'
            }
        }))
    }
});

export const loadProject = createAsyncThunk('project/load', async (id, store) => {
    const state = store.getState();
    const func = isNaN(id) ? (
        p => p.label === id
    ) : (
        p => p.id === id
    )
    const project = state.project.list.find(func);
    return axios.get("/project/tags/" + project.id)
    .then((response) => {
        const data = response.data;
        return {
            ...project,
            tags: data
        }
    })
    .then(r => {
        return axios.get("/project/tagfolders/" + project.id)
        .then((response) => {
            const data = response.data;
            return {
                ...r,
                tagfolders: data.data
            }
        })
    })
    .then(r => {
        return axios.get("/project/listjobs/" + project.id)
        .then((response) => {
            const data = response.data;
            return {
                ...r,
                jobs: data.data
            }
        })
        .catch(e => {
            return {
                ...r,
                jobs: []
            }
        })
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const loadProjectList = createAsyncThunk('project/list', async (filters, store) => {
    store.dispatch(slice.actions.setError(false));
    return axios.get("/project/all")
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const { setError, setSingle, setActiveTab } = slice.actions

export default slice.reducer
