import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";
import { getJobFrequencyLabel } from "../../config/jobfrequency";
import { getJobPlatformLabel } from "../../config/jobplatforms";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";

function JobTable({jobs, selection, setSelection}){
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const projects = useSelector(state => state.project.list);

    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != jobs.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(jobs.map(r => r.id));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>Job</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Frequency</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Last run</TableCell>
                    {!project && <TableCell>Project</TableCell>}
                    <TableCell width={140}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {jobs.map((job,i) => {
                    const selected = selection.indexOf(job.id) !== -1;
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(job.id)} />
                        </TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            {job.label}
                        </TableCell>
                        <TableCell>{job.active ? "Yes" : "No"}</TableCell>
                        <TableCell>{getJobFrequencyLabel(job.freq)}</TableCell>
                        <TableCell>{getJobPlatformLabel(job.type)}</TableCell>
                        <TableCell>
                            {job.lastrun ? moment(job.lastrun * 1000).fromNow() : "Never"}
                        </TableCell>
                        {!project && <TableCell>{(job.projects || []).map(pid => ((projects || []).find(p => p.id === pid) || {}).name).join(", ") || "--"}</TableCell>}
                        <TableCell align="right">
                            <Tooltip title={job.active ? "Deactivate" : "Activate"}>
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: job.active ? "deactivateJob" : "activateJob", props: job.id}));
                                }}>
                                    {job.active ? <Stop /> : <PlayArrow /> }
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: "editJob", props: job.id}));
                                }}><Edit /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size="small"  onClick={() => {
                                    dispatch(openDialog({id: "deleteJob", props: job.id}));
                                }}><Delete /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default JobTable;