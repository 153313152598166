//templates per email alert
const alertTemplates = [

    //----------------MSC WEST MED 
    {
      "templateTitle": "MSC alert WESTERN MED",
      "templateDesc": "Grandiosa + Splendida + Fantasia",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - Western Med"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
        {
          "type": "header2",
          "content": "[MSC Grandiosa]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 46,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
        /*
  
  
              */
  
        /*
     
  */
  
        /*
  
              */
        //------------------MSC SPLENDIDA 
        {
          "type": "header2",
          "content": "[MSC Splendida]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 49,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
        //------------------MSC FANTASIA  
        {
          "type": "header2",
          "content": "[MSC Fantasia]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 79,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
  
  
  
      ]
    },
  
    // MSC EAST MED
    {
      "templateTitle": "MSC alert EASTERN MED",
      "templateDesc": "Orchestra",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - Eastern Med"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
        // --------------ORCHESTRA
  
        {
          "type": "header2",
          "content": "[MSC Orchestra]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 48,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
      ]
    },
  
    // MSC NORTHERN EU
    {
      "templateTitle": "MSC alert NORTHERN EUROPE",
      "templateDesc": "(Magnifica)",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - Northern Europe"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
        //--------------- MAGNIFICA 
  
        {
          "type": "header2",
          "content": "[MSC Magnifica]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 47,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
  
      ]
    },
  
    // MSC CARIBBEAN
    {
      "templateTitle": "MSC alert CARIBBEAN",
      "templateDesc": "Meraviglia, Divina, Seashore, Seaview",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - Caribbean"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
        //------------MERAVIGLIA
  
        {
          "type": "header2",
          "content": "[MSC Meraviglia]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 56,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
  
        //-------------DIVINA
  
        {
          "type": "header2",
          "content": "[MSC Divina]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 57,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
  
  
  
        //--------------- SEASHORE 
        {
          "type": "header2",
          "content": "[MSC Seashore]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 54,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
  
        //-------------- SEAVIEW 
        {
          "type": "header2",
          "content": "[MSC Seaview]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 43,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
  
  
      ]
    },
  
  
    // MSC SOUTH AMERICA
    {
      "templateTitle": "MSC alert SOUTH AMERICA",
      "templateDesc": "Preziosa, Seaside",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - South America"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
        //--------------PREZIOSA 
  
        {
          "type": "header2",
          "content": "[MSC Preziosa]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 60,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
  
        {
          "type": "separator"
        },
        
        //--------------SEASIDE 
  
        {
          "type": "header2",
          "content": "[MSC Seaside]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 45,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "separator"
        },
  
      ]
    },
  
    // MSC MIDDLE EAST
    {
      "templateTitle": "MSC alert MIDDLE EAST",
      "templateDesc": "Bellissima + Virtuosa",
      "templateColors": {
        "primary": "#000033",
        "secondary": "#000066",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#AAAAAA",
        "sentimentPos": "#009900",
        "sentimentNeu": "#333333",
        "sentimentNeg": "#990000"
      },
      "templateFonts": {
        "headings": "Arial,Helvetica,sans-serif",
        "text": "Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "image",
          "src": "/dist/images/alert/msc_alert_logo.png"
        },
        {
          "type": "header1",
          "content": "0. Update: MSC Cruises restart - Middle East"
        },
        {
          "type": "timestamp",
          "content": "00/00/00 00:00"
        },
        {
          "type": "small",
          "content": "To facilitate the interpretation of users' sentiment, the negative posts are reported in red, the positive posts are in green, and the neutral ones in black."
        },
        {
          "type": "separator"
        },
  
  
        //-------------BELLISSIMA
  
  
        {
          "type": "header2",
          "content": "[MSC Bellissima]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 76,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
  
  
        //------------VIRTUOSA
  
  
        {
          "type": "header2",
          "content": "[MSC Virtuosa]"
        },
  
        {
          "type": "header3",
          "content": "English Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "en",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "French Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "fr",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Spanish Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "es",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Portuguese Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "pt",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "Italian Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "it",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        },
        {
          "type": "header3",
          "content": "German Language"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 44,
            "displayFeeds": 10,
            "filterByLanguage": true,
            "language": "de",
            "options": {
              "colorBySentiment": true,
              "hideTitle": false,
              "renderTitleAsText": true,
              "hideBody": true,
              "hideTimestamp": true,
              "hideEngagement": true
            }
          }
        }
  
      ]
    },
  
  
  
    // VERONESI
    {
      "templateTitle": "Veronesi",
      "templateDesc": "alert Veronesi",
      "templateColors": {
        "primary": "#006633",
        "secondary": "#339966",
        "highlight": "#FF6600",
        "text": "#333333",
        "background": "#FFFFFF",
        "elements": "#CCCCCC"
      },
      "templateFonts": {
        "headings": "Georgia,Times,serif",
        "text": "Calibri,Arial,Helvetica,sans-serif"
      },
      "rows": [{
          "type": "header1",
          "content": "header 1"
        },
        {
          "type": "header2",
          "content": "header 2"
        },
        {
          "type": "slot",
          "contentConfig": {
            "id": "platformheatmap"
          },
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 1023,
            "displayFeeds": 5
          }
        },
        {
          "type": "separator"
        },
        {
          "type": "feeds",
          "contentConfig": {
            "tagId": 1019,
            "displayFeeds": 3
          }
        },
        {
          "type": "header3",
          "content": "header 3"
        },
        {
          "type": "text",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          "type": "separator"
        },
        {
          "type": "text",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          "type": "slot",
          "contentConfig": {
            "id": "engagementtrend",
            "resolution": "day",
            "layout": "spline"
          },
        },
        {
          "type": "text",
          "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ] //rows array
    }
];

export default alertTemplates;