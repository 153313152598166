import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import HelpAdornment from "./HelpAdornment";
import platforms from '../../config/platforms';

const options = Object.keys(platforms).map(key => {
    return {
        value: key,
        text: platforms[key]
    }
});

function PlatformSelect({
    value, 
    onChange,
    size="small",
    helperText,
    error=false,
    displayEmpty=false,
    hideHelpAdornment=false,
    placeholder="",
    label="Platform", 
    disabled=false, 
    required=true, 
}){
    const _options = required ? [...options] : [
        {value: '', text: 'Any'},
        ...options
    ];
    return<FormControl sx={{minWidth: 150}} fullWidth size={size}>
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            error={error}
            disabled={disabled}
            required={required}
            displayEmpty={displayEmpty}
            size={size}
            fullWidth
            value={value || ""}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : "Any"
            }}
            startAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="platform" />}
        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>

        {helperText && <FormHelperText>{helperText}</FormHelperText>
}
    </FormControl>
}
export default PlatformSelect;