import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise, updateArray } from '../common';
import project from './project';

export const slice = createSlice({
    name: 'settings',
    initialState: {
        bulkToggleCount: 0,
        list: null,
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        
        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listSettings.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.list = payload;
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteSettings.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
            }
        })
        
        builder.addCase(saveSettings.fulfilled, (state, action) => {
            const {payload} = action;
            const parsedSettings = payload;
            state.list = updateArray(state.list, parsedSettings, "setting_name");
        })
        
        builder.addCase(addSettings.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
        })
      }
    
})


export const deleteSettings = createAsyncThunk('settings/delete', async (settingName, store) => {
    return axios.post("/settings/delete", {setting_name: settingName}).then(r => r.data);
});


export const saveSettings = createAsyncThunk('settings/save', async (settings, store) => {
    return axios.post("/settings/save", settings).then(r => r.data);
});


export const addSettings = createAsyncThunk('settings/new', async (settings, store) => {
    return commonApiPromise(axios.post("/settings/add", settings), store)
});


export const listSettings = createAsyncThunk('settings/list', async (nil, store) => {
    return commonApiPromise(axios.get("/settings/list"), store).then(r => r.data)
})

export const { setError, setLoading, setSettingss } = slice.actions

export default slice.reducer
