import { Box, Button, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { loadConfig } from "../../../../features/dashboard";
import WidgetBoxField from "../../../dashboard/builder/WidgetBoxField";
import WidgetContainer from "../../../dashboard/builder/WidgetContainer";

function DashboardBuilder({handleNext, onChange, form, wizard=true}){
    const margin = 4;
    const [elements, setElements] = useState([]);
    const project = useSelector(state => state.project.single);
    const [showPreview, setShowPreview] = useState(false);
    const config = useSelector(state => state.dashboard.config);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(!config){
            dispatch(loadConfig(project ? project.label : ""));
        }
    }, [config, dispatch])

    useEffect(() => {
        setElements(((form.config || {}).elements || []).map((e,index) => {
            return {
                ...e,
                realId: e.realId || (e.id + "" + index)
            }
        }));
    }, [form])

    const onAdd = element => {
        const _elements = [
            ...elements,
            {
                ...element,
                realId: element.id + elements.length
            }
        ];
        setElements(_elements)
        onChange({
            ...form,
            config: {
                ...form.config || {},
                elements: _elements
            }
        })
    }
    const onUpdate = _elements => {
        setElements(_elements);
        onChange({
            ...form,
            config: {
                ...form.config || {},
                elements: _elements
            }
        })
    }

    return <>
        {wizard && <Box sx={{mt: margin, mb: margin}}>
            <Typography color="primary.main" variant="h4">Create data visualizations</Typography>
            <Typography color="primary.main" variant="caption">You can edit your preferences anytime.</Typography>
        </Box> }
        <DndProvider backend={HTML5Backend}>
            <Grid container display={"flex"} spacing={2}>
                <Grid item sm={9}>
                    <FormGroup>
                        <FormControlLabel control={<Switch value={showPreview} onChange={e => setShowPreview(e.target.checked)} />} label="Show preview" />
                    </FormGroup>
                </Grid>
                <Grid item sm={3}>
                    <Typography variant="subtitle1">Dashboard components</Typography>
                </Grid>

                <Grid item sm={9}>
                    <WidgetContainer 
                        showPreview={showPreview}
                        onUpdate={onUpdate}
                        onAdd={onAdd} 
                        elements={elements} 
                    />
                </Grid>
                <Grid item sm={3}>
                    <Box
                        sx={{
                            overflow: 'auto',
                            height: '100%',
                            // maxHeight: '100%'
                        }}
                    >
                        {(config || []).map((r, index) => {
                            return <WidgetBoxField showPreview={showPreview} elements={elements} config={r} key={index} />
                        })}
                    </Box>
                </Grid>
            </Grid>
        </DndProvider>       
        {wizard && <Box mt={3} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleNext}>
                End: Start collecting data
            </Button>
        </Box>}

    </>
}
export default DashboardBuilder;