import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import ProjectInfoForm from "../ProjectInfoForm";

function ProjectInfoStep({
    form,
    onChange,
    handleNext
}){

    const errors = useSelector(state => state.projectWizard.errors);
    const setForm = form => {
        onChange(form);
    }
    return <Box gap={4} display="flex" flexDirection={"column"}>
        <ProjectInfoForm form={form} onChange={f => setForm(f)} errors={errors} onSave={handleNext}/>
    </Box>
}
export default ProjectInfoStep;