import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';

function EngagementForm({form, onChange}){
    const mapping = {
        default: {
            likes: 'Likes',
            comments: 'Comments'
        },
        fb: {
            likes: 'Likes',
            comments: 'Comments',
            shares: 'Shares'
        },
        in: {
            reactions: 'Reactions',
            comments: 'Comments'
        },
        yt: {
            views: 'Views',
            likes: 'Likes',
            dislikes: 'Dislikes',
            comments: 'Comments',
        }
    }
    
    
    const {platform} = form;
    const currentMapping = mapping[platform] || mapping.default;
    if(!platform)
        return null;
    const size = Object.keys(currentMapping).length;
    return <>
        {Object.keys(currentMapping).map(key => {
            const formKey = platform + "_" + key;
            return <Grid item sm={12/size} key={key}>
                <TextField 
                    type="number"
                    value={form[formKey] || ""}
                    onChange={e => onChange({...form, [formKey]: e.target.value})}
                    fullWidth
                    label={currentMapping[key]}
                />
            </Grid>
        })}
    </>
}
export default EngagementForm;