import { Close } from "@mui/icons-material";
import { Alert, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {prettyTruncate} from '../../common';
import { reloadPosts } from "../../features/post";
import { enqueueErrorSnack, enqueueSuccessSnack } from "../../features/snackbar";

const mapping = [
    "user_screenname",
    "title",
    "post_text",
    "lang",
    "full_uri",
    "domain",
    "site",
    "sentiment",
    "platform",
    "post_time",
    "priority",
    "tags"
]

function BatchFeedForm({}){
    const dispatch = useDispatch();
    const divRef = useRef();
    const [rows, setRows] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const project = useSelector(state => state.project.single);
    const tags = project.tags;

    const hasErrors = errors && errors.length>0;
    const errorFeedCount = hasErrors ? Object.keys(errors.reduce((carry, item) => {
        carry[item[0]] = true;
        return carry;
    },{})).length : 0;



    const fetch = (action) => axios.post("/post/insert-bulk",{
        project_id: project.id,
        action,
        data: rows
    }).then(r => r.data)

    const save = () => {
        setLoading(true);
        fetch("insert").then(r => {
            if(!r || !r.count){
                dispatch(enqueueErrorSnack("There was an error during the insert process."));
                return;
            }
            dispatch(enqueueSuccessSnack(r.count + " feed(s) inserted"));
            setRows(null);
            setErrors(null);
            dispatch(reloadPosts());
        }).catch(e => {
            dispatch(enqueueErrorSnack(e.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    const validate = () => {
        fetch("validate").then(r => {
            setErrors(r.errors);
        })
    }

    const parseRows = e => {
        setTimeout(() => {
            const table = divRef.current.querySelector("table");
            console.log(table);
            const obj = [];
            for(let j = 0; j<table.rows.length; j++){
                const row = table.rows[j];
                const json = {}
                mapping.forEach((m, i) => {
                    json[m] = row.cells[i].innerText;
                })
                json.tags = [];
                for(var i=mapping.length-1;i<mapping.length+5;i++){
                    if(!row.cells[i])
                        break;
                    const tagLabel = (row.cells[i].innerText || "").trim().toLowerCase();
                    if(tagLabel){
                        const tag = tags.find(t => t.label.toLowerCase() === tagLabel);
                        if(tag){
                            json.tags.push(tag.id);
                        }
                    }
                }
                obj.push(json);
            }
            setRows(obj);
        },100);
        return true;

    }

    useEffect(() => {
        if(rows && rows.length > 0){
            validate();
        }else{
            divRef.current.focus();
        }
    },[rows]);

    if(!rows || rows.length === 0){
        return <>
        <Typography variant="h5" textAlign={"center"}>Paste your feeds here</Typography>
        <div
            ref={divRef}
            style={{
                width: '100%',
                border: '1px solid #CCC',
                height: 150
            }}
            
            contentEditable
            onPaste={e => parseRows(e)}

        />

        <Typography variant="subtitle2" textAlign={"center"}>
            <a href="/assets/files/batch_insert_model.xlsx">Download model</a> &nbsp; | &nbsp;
            <a target="_blank" href="https://docs.google.com/spreadsheets/d/1G3XYt_zhoWKlLPo7fWKjgSkNm5pEyJk3d0tEqYBTaBc/edit">Use online model</a>
        </Typography>
        
        </>
    }
    
    return <>

        <Box mt={3}>
            <Alert severity={!hasErrors ? "success" : "error"} action={<>
                {!loading && <Button onClick={() => {
                    setRows(null);
                    setErrors(null)
                }}>Restart</Button> }
                {!loading && !hasErrors && <Button onClick={save} variant="contained" color="success">Insert</Button>}
                {loading && <CircularProgress size={24} />}
            </>}>
                {hasErrors && <>
                    Warning: there are <b>{errors.length}</b> errors in <b>{errorFeedCount}</b> out of <b>{rows.length}</b> feeds you inserted
                </>}
                {!hasErrors && <>
                    You are inserting <b>{rows.length}</b> feed(s).
                </>}
            </Alert>
        </Box>

        <Box mt={3}>
            <table cellSpacing="0" cellPadding="0" id="batch-insert-feed-table">
                <thead>
                    <tr>
                        <td></td>
                        {mapping.map(m => <th key={m}>{m}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => {
                        return <FeedRow {...{
                            key: rowIndex,
                            rowIndex,
                            row,
                            errors,
                            hasErrors,
                            tags,
                            onDelete: () => {
                                setRows(rows.filter((r,i) => i!==rowIndex))
                            }
                        }}/>
                    })}
                </tbody>
            </table>
        </Box>
    </>
}

function FeedRow({hasErrors, errors, rowIndex, row, tags, onDelete}){
    const [hover, setHover] = useState(false);
    const rowError = hasErrors && !!errors.find(e => e[0] === rowIndex);
    return <tr className={hover ? "hover" : ""} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <td width={40} className={rowError ? "error" : ""}>
            {!hover && <>#{rowIndex}</>}
            {hover && <IconButton color="error" onClick={onDelete} size="small"><Close /></IconButton>}
        </td>
        {mapping.map(m => {
            const className = hasErrors && errors.find(e => e[0] === rowIndex && e[1] === m) ? "error" : ""
            return <td key={m + "_" + rowIndex} className={className}>
                {m==="tags" ? row[m].map(tagId => tags.find(t => t.id === tagId).label).join(", ") : prettyTruncate(row[m],50)}
            </td>
        })}
    </tr>
}
export default BatchFeedForm;