import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";
import DeleteDashboardDialog from "./DeleteDashboardDialog";
import EditDashboardDialog from "./EditDashboardDialog";

function DashboardManagement(){
    const project = useSelector(state => state.project.single);
    const dispatch = useDispatch();
    const filteredTabs = (project.tabs || []).filter(t => {
        return t.type==="dashboard";
    });
    return <>
        <DeleteDashboardDialog />
        <EditDashboardDialog />

        <Box justifyContent="space-between" display="flex">
            <Box flexGrow={0}>

            </Box>
            <Box display="flex" gap={2}>
                <Button variant="contained" color="secondary" onClick={() => dispatch(openDialog("editDashboard"))}>Create Dashboard</Button>
            </Box>
        </Box>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Dashboard</TableCell>
                    <TableCell>Elements</TableCell>
                    <TableCell>Time span</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredTabs.map((tab,i) => {
                    const elements = (tab.config && tab.config.elements) || [];
                    return <TableRow key={i} hover>
                        <TableCell>{tab.label}</TableCell>
                        <TableCell>{elements.length}</TableCell>
                        <TableCell>
                            {tab.config.spanType}
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" onClick={() => {
                                dispatch(openDialog({id: "editDashboard", props: tab.id}));
                            }}><Edit /></IconButton>
                            <IconButton size="small"  onClick={() => {
                                dispatch(openDialog({id: "deleteDashboard", props: tab.id}));
                            }}><Delete /></IconButton>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}
export default DashboardManagement;