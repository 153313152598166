import { CopyAll, Delete, Download, Edit, EmailOutlined, Tv, Visibility } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";
import report, { cloneReport, listReports } from "../../features/report";
import DeleteReportDialog from "./DeleteReportDialog";
import EditReportDialog from "./EditReportDialog";
import NewReportDialog from "./NewReportDialog";

function ReportManagement(){
    const reports = useSelector(state => state.report.list);
    const [selection, setSelection] = useState([]);

    const dispatch = useDispatch();
    const filteredReports = (reports || []).filter(t => {
        return true;
    });

    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    
    const handleOpenEmailReport = id => {
        window.open("/alert-view/" + id)
    }
    
    const handleOpenReport = id => {
        window.open(axios.defaults.baseURL + "/report/download/" + id + "/" + axios.defaults.headers["X_API_KEY"]);
    }

    useEffect(() => {
        dispatch(listReports());
    },[]);

    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!reports.find(r => r.id === s);
        }));
    }, [reports]);
    
    return <>
        <DeleteReportDialog />
        <EditReportDialog />
        <NewReportDialog />
        <Box justifyContent="space-between" display="flex">
            <Box flexGrow={0}>
            </Box>
            <Box display="flex" gap={2}>

                {selection && selection.length > 0 && <Button 
                    color="error" 
                    variant="outlined"
                    onClick={() => {
                        dispatch(openDialog({id: "deleteReport", props: selection.length === 1 ? selection[0] : selection}));
                    }}
                >Delete selected</Button> }

                <Button variant="contained" color="secondary" onClick={() => dispatch(openDialog("newReport"))}>Add Report</Button>
            </Box>
        </Box>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != filteredReports.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(filteredReports.map(r => r.id));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>Report</TableCell>
                    <TableCell>Last save</TableCell>
                    <TableCell>Info</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredReports.map((report,i) => {
                    const selected = selection.indexOf(report.id) !== -1;
                    const type = (report.config && report.config.type) || "report";
                    const isAlert = type !== "report";
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(report.id)} />
                        </TableCell>
                        <TableCell>
                            <Box display="flex" alignItems="center">
                                {isAlert ? <EmailOutlined /> : <Tv />} 
                                <Typography ml={1}>{report.title}</Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            {report.last_save ? moment(report.last_save).fromNow() : "Never"}
                        </TableCell>
                        <TableCell>
                            {isAlert && <>
                                Template: {(report.config && report.config.template && report.config.template.templateTitle) || "--"}
                            </>}

                            {!isAlert && <>
                                {(report.config && report.config.slides && report.config.slides.length) || 0} Slide(s)
                            </>}
                            
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" onClick={() => {
                                dispatch(openDialog({id: "editReport", props: report.id}));
                            }}><Edit /></IconButton>
                            <IconButton size="small" onClick={() => {
                                dispatch(cloneReport(report.id));
                            }}><CopyAll /></IconButton>
                            {type === "alert" ? <>
                                <IconButton size="small" onClick={() => handleOpenEmailReport(report.id)}>
                                    <Visibility />
                                </IconButton>
                            </> : <>
                                <IconButton size="small" onClick={() => handleOpenReport(report.id)}>
                                    <Download />
                                </IconButton>
                            </>}
                            <IconButton size="small"  onClick={() => {
                                dispatch(openDialog({id: "deleteReport", props: report.id}));
                            }}><Delete /></IconButton>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}
export default ReportManagement;