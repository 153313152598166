const filterbyfields = {
    "text_search": 'Text',
    "date": 'Date',
    "interval": 'Date interval',
    "tag": 'Tag',
    "sentiment": 'Sentiment',
    "priority": 'Priority',
    "platform": 'Platform',
    "language": 'Language',
    "author": 'Author',
};
export default filterbyfields;