import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteSubscriberDialog from "./dialog/DeleteSubscriberDialog";
import EditSubscriberDialog from "./dialog/EditSubscriberDialog";
import NewSubscriberDialog from "./dialog/NewSubscriberDialog";
import SubscriberButtonBar from "./SubscriberButtonBar";
import SubscriberTable from "./SubscriberTable";
import { listSubscribers } from "../../../features/subscriber";

function SubscriberAlertManagement(){
    const dispatch = useDispatch();
    const subscribers = useSelector(state => state.subscriber.list);
    const project = useSelector(state => state.project.single);
    const toggleCount = useSelector(state => state.subscriber.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, setSearch] = useState("");

    
    const filteredSubscribers = (subscribers || [])
    .filter(j => {
        const res = !search || 
        j.user.user_full_name.toLowerCase().indexOf(search.toLowerCase())!==-1 || 
        j.user.email.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });


    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!subscribers.find(r => r.id === s);
        }));
    }, [subscribers]);

    useEffect(() => {
        dispatch(listSubscribers(project.id))
    }, [project]);



    useEffect(() => {
        setSelection([]);
    },[toggleCount]);
    
    return <>
        <DeleteSubscriberDialog />
        <EditSubscriberDialog />
        <NewSubscriberDialog />

        <SubscriberButtonBar selection={selection} search={search} setSearch={setSearch} />
        <SubscriberTable subscribers={filteredSubscribers} selection={selection} setSelection={setSelection} />
    </>
}
export default SubscriberAlertManagement;