import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux'
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import './highcharts_setup';
import ErrorBoundary from './containers/ErrorBoundary';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const container = document.getElementById('root');
if (module.hot) {
    module.hot.accept();
}
  
const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <SnackbarProvider maxSnack={10}>
                <App />
            </SnackbarProvider>
        </ErrorBoundary>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
