import { Tab, Tabs } from "@mui/material";

function DashboardFormTabs({activeTab, onChange}){

    return <Tabs value={activeTab} onChange={(e, value) => onChange(value)}>
        <Tab label="Dashboard info" />
        <Tab label="Time span" />
        <Tab label="Filters" />
        <Tab label="Elements" />
    </Tabs>
}
export default DashboardFormTabs;