import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { editUser, resetUserPassword } from '../../features/user';
import UserForm from './UserForm';
import { hasErrors } from '../../common';
import { validateUser } from '../../validator/user';

const DIALOG_ID = "editUser"
const DIALOG_WIDTH = "md"

function EditUserDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const users = useSelector(state => state.user.all);
    const userId = useSelector((state) => state.dialog[DIALOG_ID])

    const show = !!userId;
    const user = userId ? users.find(j => j.userid === userId) : null;
    const [errors] = useState({});
    const [form, setForm] = useState({});

    const handleResetPassword = e => {
        if(window.confirm("Do you really want to reset password for " + user.user_full_name + "?")){
            dispatch(resetUserPassword(userId)).then(r => {
                dispatch(enqueueSuccessSnack("Password reset completed succesfully."))
                dispatch(closeDialog(DIALOG_ID));
            })
        }
    }

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateUser(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(editUser(form))
        .then(r => {
            if(r && r.payload){
                dispatch(closeDialog(DIALOG_ID));
                dispatch(enqueueSuccessSnack("User saved"));
            }
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm(user);
    }, [user, userId]);

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Edit user
            </DialogTitle>
            <DialogContent dividers={false}>
                <Box pr={3}>
                    <UserForm form={form || user || {}} onChange={setForm} errors={errors} />
                </Box>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Stack gap={1} direction="row">
                        <Button variant="contained" color="primary" type="button" onClick={handleResetPassword}>Reset password</Button>
                        <Button variant="contained" color="secondary" type="submit">Save User</Button>
                    </Stack>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditUserDialog;