import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise } from '../common';
import { enqueueSnack } from './snackbar';
import { useDispatch, useSelector } from 'react-redux';


const updateUiSettings = (ui) => {
    localStorage.setItem("uiSettings", JSON.stringify(ui));
}
const uiSettings = localStorage.getItem("uiSettings");

export const slice = createSlice({
    name: 'app',
    initialState: {
        ui: uiSettings ? JSON.parse(uiSettings) : {
            menuOpen: true,
            darkMode: true
        },
        helpStrings: {},
        applications: [],
    },
    reducers: {
        toggleMenu: (state, action) => {
            state.ui = {...state.ui, menuOpen: action.payload};
            updateUiSettings(state.ui);
        },
        toggleDarkMode: (state, action) => {
            state.ui.darkMode = !state.ui.darkMode;
            updateUiSettings(state.ui);
        },
    },

    extraReducers:(builder) => {
        // console.log(loadApplications.fulfilled);

        builder.addCase(loadApplications.fulfilled, (state, action) => {
          const { payload } = action;
        //   console.log("function payload",payload);
          state.applications = [...payload];
          localStorage.setItem("apps",JSON.stringify(payload));
      });

        builder.addCase(loadHelpStrings.fulfilled, (state, action) => {
            const {payload} = action;
            state.helpStrings = {};
            payload.forEach(element => {
                state.helpStrings[element.help_key] = element.help_text;
            });
        });
        
        builder.addCase(loadServiceStatus.fulfilled, (state, action) => {
            const {payload} = action;
            state.serviceStatus = payload;
        });
    }
}); //createSlice 


export const loadHelpStrings = createAsyncThunk('app/loadHelpStrings', async (nil, store) => {
    
    return axios.get("/help/all")
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const loadApplications = createAsyncThunk('app/loadApplications', async (_,store) => {
    // const apiKey = useSelector(state => state.user.profile.api_key);

    // console.log("function received apikey", apiKey);
    // console.log("headers",axios.defaults.headers);

    const state = store.getState();
    const apiKey = state.user.apiKey;

    return axios.get("/system/apps/" + apiKey)
    .then((response) => {
        const data = response.data.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        // console.log("function data",data)
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const loadServiceStatus = createAsyncThunk('app/serviceStatus', async (nil, store) => {
    return commonApiPromise(axios.get("/system/servicestatus"), store);
})

export const { toggleMenu, toggleDarkMode } = slice.actions

export default slice.reducer
