import { Box, Grid, LinearProgress, Pagination, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "../../components/user/UserTable";
import UserButtonBar from "../../components/user/UserButtonBar";
import MainContainer from "../../containers/MainContainer";
import ActivateUserDialog from "../../components/user/ActivateUserDialog";
import DeactivateUserDialog from "../../components/user/DeactivateUserDialog";
import DeleteUserDialog from "../../components/user/DeleteUserDialog";
import EditUserDialog from "../../components/user/EditUserDialog";
import NewUserDialog from "../../components/user/NewUserDialog";
import { allUsers } from "../../features/user";
import { numberFormat } from "highcharts";

function UsersAdminPage(){
    const dispatch = useDispatch();
    const users = useSelector(state => state.user.all);
    const loadingUsers = useSelector(state => state.user.loading);
    const toggleCount = useSelector(state => state.user.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, _setSearch] = useState("");
    const limit = 10;
    const [page, setPage] = useState(1);
    const offset = (page-1) * limit;
    
    const filteredUsers = (users || [])
    .filter(u => {
        const res = !search || (u.user_full_name + " " + u.email + " " + u.userid).toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });
    const pagedUsers = filteredUsers.slice(offset, offset+limit);
    const total = filteredUsers.length;
    const totalPages = Math.ceil(total/limit);

    const setSearch = s => {
        _setSearch(s);
        setPage(1);
    }

    const handleChangePage = (event, _page) => {
        setPage(_page);
    }

    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!users.find(r => r.id === s);
        }));
    }, [users]);
    
    useEffect(() => {
        setSelection([]);
    },[toggleCount]);

    useEffect(() => {
        dispatch(allUsers());
    }, []);
    const totalFormatted = numberFormat(total,0,".",",");

    const paginationHeader = <Grid container justifyContent={"space-between"} alignItems="center" mt={2}>
        <Grid item display={"flex"} justifyContent="center" alignItems={"center"}>

            {filteredUsers && filteredUsers.length > 0 && <Typography variant="body2">
                Displaying users from {offset+1} to {Math.min(offset+limit, total)} of <b>{totalFormatted}</b>
            </Typography> }
        </Grid>
        <Grid item>
            {filteredUsers && totalPages > 1 &&  <Stack spacing={2}>
                <Pagination color="secondary" page={page} count={totalPages} onChange={handleChangePage}/>
            </Stack> }
        </Grid>
    </Grid>;

    return <MainContainer>

        <DeleteUserDialog />
        <ActivateUserDialog />
        <DeactivateUserDialog />
        <EditUserDialog />
        <NewUserDialog />
        <Typography sx={{mb: 4}} color="primary" variant="h2">Users</Typography>

        <UserButtonBar selection={selection} search={search} setSearch={setSearch} />
        {users === null && <>
            <LinearProgress />
        </>}

        {paginationHeader}

        {filteredUsers && filteredUsers.length === 0 && <>
            
            <Paper elevation={2} sx={{mt: 4}}>
                <Box sx={{height: 200}} display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography variant="h4" color="primary.dark">No users found</Typography>
                </Box>
            </Paper>
        </>}

        {users && users.length > 0 && <>
            <UserTable users={pagedUsers} selection={selection} setSelection={setSelection} />
        </>}
        
    </MainContainer>
}
export default UsersAdminPage;