import { Box, Button, CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import PlatformSelect from "../common/PlatformSelect";
import TagFilterSelect from "../common/TagFilterSelect";
import SentimentSelect from "../common/SentimentSelect";
import PrioritySelect from "../common/PrioritySelect";
import LanguageSelect from "../common/LanguageSelect";
import { useDispatch } from "react-redux";
import { enqueueErrorSnack, enqueueSuccessSnack } from "../../features/snackbar";
import axios from "axios";
import { useSelector } from "react-redux";
import EngagementForm from "./EngagementForm";
import moment from "moment/moment";
import { useRef } from "react";
import { closeDialog } from "../../features/dialog";
function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
}
function newForm(){
    return {
        post_time: moment().format("YYYY-MM-DDTHH:mm")
    }
}
function FeedForm(){
    const dispatch = useDispatch();
    const uriRef = useRef();
    const project = useSelector(state => state.project.single);
    const [form, setForm] = useState(newForm());
    const [loading, setLoading] = useState(false);
    const [testing, setTesting] = useState(false);
    const testUrl = () => {
        const {full_uri} = form;
        if(!full_uri || !isValidHttpUrl(full_uri)){
            dispatch(enqueueErrorSnack("Invalid URI"));
            return;
        }
        setTesting(true);
        axios.post("/post/parseurl",{
            project_id: project.id,
            feed_uri: full_uri
        })
        .then(r => {
            const data = r.data;
            const postData = data[data.response + "Data"] || data.postData;
            if(postData){
                setForm({...form, ...postData});
            }
        })
        .catch(E => dispatch(enqueueErrorSnack(E.message)))
        .finally(() => setTesting(false))
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        axios.post("/post/insert",{
            projectData: {
                project_label: project.label
            },
            postData: form
        })
        .then(r => {
            const data = r.data;
            if(data && data.result){
                dispatch(enqueueSuccessSnack("Feed inserted"));
                dispatch(closeDialog("addFeed"));
            }else{
                dispatch(enqueueErrorSnack("There was an error during the insert process"));
            }
        })
        .catch(E => dispatch(enqueueErrorSnack(E.message)))
        .finally(() => setLoading(false))
    }

    return <form onSubmit={handleSubmit}>
        <Grid spacing={3} container p={2}>
            <Grid item sm={12}>
                <TextField
                    ref={uriRef}
                    autoFocus
                    fullWidth
                    size="small"
                    required
                    value={form.full_uri || ""}
                    onChange={e => setForm({...form, full_uri: e.target.value})}
                    label="Full Address"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {testing ? <CircularProgress size={24} /> : <Button size="small" color="primary" onClick={testUrl}>Test URI</Button>}
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item sm={4}>
                <TextField
                    label="UUID"
                    size="small"
                    required
                    fullWidth
                    value={form.uuid || ""}
                    onChange={e => setForm({...form, uuid: e.target.value})}
                />
            </Grid>
            <Grid item sm={4}>
                <PlatformSelect
                    label="Platform"
                    required
                    fullWidth
                    value={form.platform || ""}
                    onChange={platform => setForm({...form, platform})}
                />
            </Grid>
            <Grid item sm={4}>
                <TextField
                    label="Post date"
                    size="small"
                    fullWidth
                    value={form.post_time || ""}
                    onChange={e => setForm({...form, post_time: e.target.value})}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    label="Site"
                    size="small"
                    required
                    fullWidth
                    value={form.site || ""}
                    onChange={e => setForm({...form, site: e.target.value})}
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    label="Domain"
                    required
                    size="small"
                    fullWidth
                    value={form.domain || ""}
                    onChange={e => setForm({...form, domain: e.target.value})}
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    label="User name"
                    size="small"
                    fullWidth
                    value={form.user_screenname || ""}
                    onChange={e => setForm({...form, user_screenname: e.target.value})}
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    label="User ID"
                    size="small"
                    fullWidth
                    value={form.user_id || ""}
                    onChange={e => setForm({...form, user_id: e.target.value})}
                />
            </Grid>
            <Grid item sm={12}>
                <TextField
                    label="Post text"
                    size="small"
                    fullWidth
                    multiline
                    rows={4}
                    value={form.post_text || ""}
                    onChange={e => setForm({...form, post_time: e.target.value})}
                />
            </Grid>
            <Grid item sm={12}>
                <TextField
                    fullWidth
                    size="small"
                    value={form.img || ""}
                    onChange={e => setForm({...form, img: e.target.value})}
                    label="Image URI"
                />
            </Grid>
            <Grid item sm={6}>
                <TagFilterSelect
                    label="Tags"
                    fullWidth
                    value={form.tags || []}
                    onChange={tags => setForm({...form, tags})}
                />
            </Grid>
            <Grid item sm={6}>
                <SentimentSelect
                    label="Sentiment"
                    fullWidth
                    showAny={false}
                    showDontChange={false}
                    value={form.sentiment || ""}
                    onChange={sentiment => setForm({...form, sentiment})}
                />
            </Grid>
            <Grid item sm={6}>
                <PrioritySelect
                    label="Priority"
                    fullWidth
                    value={form.priority || ""}
                    onChange={priority => setForm({...form, priority})}
                    hideMultiple
                />
            </Grid>
            <Grid item sm={6}>
                <LanguageSelect
                    label="Language"
                    fullWidth
                    value={form.lang || ""}
                    onChange={lang => setForm({...form, lang})}
                />
            </Grid>

            <EngagementForm form={form} onChange={form => setForm(form)} />
        </Grid>
        <Box w={1} display="flex" alignItems="center" justifyContent="center" mt={2}>
            {loading ? <CircularProgress />: <Button variant="contained" type="submit" color="secondary">Save</Button>}
        </Box>
    </form>
}
export default FeedForm;