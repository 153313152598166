import { Box, Typography } from "@mui/material"
import { useDrop } from "react-dnd"
import WidgetElement from "./WidgetElement"
import update from 'immutability-helper'
import { useCallback } from "react"
import { uniqueKey } from "highcharts"

function selectBackgroundColor(isActive, canDrop) {
    if (isActive) {
        return 'success.light'
    } else if (canDrop) {
        return 'secondary.main'
    } else {
        return 'transparent'
    }
}

function WidgetContainer({elements, onAdd, onUpdate, showPreview}){
    
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: "widget",
          drop: (element) => {
              onAdd && onAdd(element);
              return element;
          },
          collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [elements]
      )
    const isActive = canDrop && isOver
    const backgroundColor = selectBackgroundColor(isActive, canDrop)

    const move = (dragIndex, hoverIndex) => {
        onUpdate(update(elements, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, elements[dragIndex]],
            ],
          })
        )
    }

    const style = {
        borderColor: '#CCC',
        borderWidth: 4,
        borderStyle: 'dashed',
        minHeight: 200,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor
    }
    const empty = elements.length === 0;
    if(empty){
        style.alignItems = style.justifyContent = "center"
    }
    const renderElement = useCallback((el, index)=> {
        return <WidgetElement 
            showPreview={showPreview}
            move={move}
            onDelete={() => {
                onUpdate(elements.filter((e,eindex) => eindex !== index));
            }}
            onUpdateSettings={settings => {
                const _elements = [...elements];
                _elements[index] = {
                    ..._elements[index],
                    settings: settings
                }
                onUpdate(_elements);
            }} 
            settings={el.settings}
            config={el} 
            key={el.realId} 
            index={index} 
            id={el.realId} 
        />
    })
    return <Box ref={drop} sx={style}>
        {elements && elements.map((el, index) => renderElement(el, index)) }
        {empty && <Typography color="primary" variant="subtitle1">Drag components here</Typography>}
    </Box>
}

export default WidgetContainer;