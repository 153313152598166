

function Header2Row({report, row, index, onHide}){
    return <tr onClick={() => onHide(index)}>
        <td style={{padding: "0 10px 0 10px"}}>
            <h2 style={{
                fontFamily: report.config.template.templateFonts.headings,
                fontWeight: "bold",
                fontSize: "20px",
                color: report.config.template.templateColors.secondary
            }}>{row.content}</h2>
        </td>
    </tr>;
}
export default Header2Row;