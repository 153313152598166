import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";
import { getTierById } from "../../config/tiers";

function GroupTable({groups, selection, setSelection}){
    const dispatch = useDispatch();

    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    {/* <TableCell width={40}>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != groups.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(groups.map(r => r.groupid));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell> */}
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {groups.map((group,i) => {
                    const selected = selection.indexOf(group.id) !== -1;
                    return <TableRow key={i} hover>
                        {/* <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(group.id)} />
                        </TableCell> */}
                        <TableCell width={40}>{group.id}</TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            {group.label}
                        </TableCell>
                        <TableCell>{group.users.map(u => <div key={u.id}>{u.name}<br/></div>)}</TableCell>
                        <TableCell align="right">
                          
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: "editGroup", props: group.id}));
                                }}><Edit /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default GroupTable;