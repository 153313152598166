const languages = {
    "en": 'English',
    "fr": 'French',
    "de": 'German',
    "nl": 'Dutch',
    "es": 'Spanish',
    "it": 'Italian',
    "pt": 'Portuguese',
    "pl": 'Polish',
    "sv": 'Swedish',
    "ru": 'Russian',
    "ja": 'Japanese',
    "zh": 'Chinese',
    "ar": 'Arabic',
    "af": 'Afrikaans',
}

const getLanguageLabel = (id) => {
    const label = languages[id];
    return label ? label.replace("--","").trim() : ""
}

export default languages;
export {getLanguageLabel};
