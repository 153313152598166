import { createSlice } from '@reduxjs/toolkit'

const _getPayload = (action) => {
    let {payload} = action;
    if(typeof payload === "string"){
        payload = {
            message: payload
        }
    }
    return payload;
}
const _enqueue = (state, action, variant) => {
    const payload = _getPayload(action);
    if(variant){
        payload.options = payload.options || {};
        payload.options.variant = variant;
    }
    payload.options.anchorOrigin = payload.options.anchorOrigin || {
        horizontal: 'center',
        vertical: 'top'
    }
    const key = payload.options && payload.options.key;

    state.notifications = [
        ...state.notifications,
        {
            key: key || new Date().getTime() + Math.random(),
            ...payload,
        },
    ];
}

export const slice = createSlice({
    name: 'snack',
    initialState: {
        notifications: []
    },
    reducers: {
        enqueueSnack: (state, action) => {
            _enqueue(state, action)
        },

        enqueueSuccessSnack: (state, action) => {
            _enqueue(state, action, "success");
        },

        enqueueErrorSnack: (state, action) => {
            _enqueue(state, action, "error");
        },

        closeSnack: (state, action) => {
            const {payload} = action;
            state.notifications = state.notifications.map(notification => (
                (payload.dismissAll || notification.key === payload.key)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            ));
        },

        removeSnack: (state, action) => {
            state.notifications = state.notifications.filter(
                notification => notification.key !== action.payload,
            );
        },

    },
    
})

export const { enqueueSnack, enqueueSuccessSnack, enqueueErrorSnack, closeSnack, removeSnack } = slice.actions

export default slice.reducer
