import { Box, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../features/dialog";

function AlertButtonBar({selection, search, setSearch}){
    const dispatch = useDispatch();

    return <Box justifyContent="space-between" display="flex">
    <Box flexGrow={0}>
        <TextField
            size="small"
            fullWidth
            autoFocus
            placeholder="Search alert name"
            value={search}
            label="Filter by name"
            onChange={e => setSearch(e.target.value)}
            InputLabelProps={{
                shrink: true
            }}
        />
    </Box>
    <Box display="flex" gap={2} alignItems="center">

        {selection && selection.length > 0 && <Button 
            color="error" 
            variant="outlined"
            onClick={() => {
                dispatch(openDialog({id: "deleteAlert", props: selection.length === 1 ? selection[0] : selection}));
            }}
        >Delete selected</Button> }

        {selection && selection.length > 0 && <Button 
            color="warning" 
            variant="outlined"
            onClick={() => {
                dispatch(openDialog({id: "deactivateAlert", props: selection.length === 1 ? selection[0] : selection}));
            }}
        >Deactivate selected</Button> }

        {selection && selection.length > 0 && <Button 
            color="success" 
            variant="outlined"
            onClick={() => {
                dispatch(openDialog({id: "activateAlert", props: selection.length === 1 ? selection[0] : selection}));
            }}
        >Activate selected</Button> }
        <Button variant="contained" color="secondary" onClick={() => dispatch(openDialog("newAlert"))}>Add Alert</Button>
    </Box>
</Box>
}
export default AlertButtonBar;