import MainContainer from "../../containers/MainContainer";
import { Card, CardContent, CardHeader, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadServiceStatus } from "../../features/app";
import PageTitle from "../../components/common/PageTitle";

export default function ServiceStatusPage(){
    const dispatch = useDispatch();
    const status = useSelector(state => state.app.serviceStatus);

    useEffect(() => {
        if(!status){
            dispatch(loadServiceStatus());
        }
    }, []);
    return <MainContainer>
        <PageTitle title="Service Status" />
        {!status && <LinearProgress />}
        {status && <Card sx={{mb: 3, mt:3 }} elevation={4}>
            <CardHeader title="MySQL Server" />
            <CardContent>
                <pre>{status.mysqldStatus}</pre>
            </CardContent>
        </Card> }

        {status && <Card sx={{mb: 3}} elevation={4}>
            <CardHeader title="ElasticSearch Server" />
            <CardContent>
                <pre>{status.elasticsearchStatus}</pre>
            </CardContent>
        </Card> }

        {status && <Card sx={{mb: 3}} elevation={4}>
            <CardHeader title="Server Uptime" />
            <CardContent>
                <pre>{status.uptime}</pre>
            </CardContent>
        </Card> }
    </MainContainer>
}