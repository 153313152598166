import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasErrors } from "../../../common";
import { openDialog } from "../../../features/dialog";
import { validateProjectWizard } from "../../../validator/project";
import PostFieldSelect from "../../common/PostFieldSelect";
import TagGroupSelect from "../../common/TagGroupSelect";
import TagRuleSelect from "../../common/TagRuleSelect";

function ProjectTagsStep({
    form,
    onChange,
    tagIndex=0,
    step,
    handleNext,
    handleSkip,
}){

    const dispatch = useDispatch();
    const recentlyAddedTagGroup = useSelector(state => state.tag.recentlyAddedGroup);
    const [errors, setErrors] = useState({});
    const [index, setIndex] = useState(tagIndex);
    const [saved, setSaved] = useState(false);
    const [tagLabeled, setTagLabeled] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagForm, setTagForm] = useState({
        rules: []
    });
    const [ruleForm, setRuleForm] = useState({});

    const prevIndex = useRef();


    const setForm = form => {
        onChange(form);
    }

    const handleDefineTag = e => {
        let formErrors = validateProjectWizard(tagForm, step, 1);
        setErrors(formErrors);
        if(hasErrors(formErrors)){
            return;
        }
        setTagLabeled(true);
    }
    const handleDismissDefine = e => {
        setTagLabeled(false);
        setTagForm({});
    }
    const handleSave = e => {
        let formErrors = validateProjectWizard(ruleForm, step, 2);
        setErrors(formErrors);
        if(hasErrors(formErrors)){
            return;
        }
        const _tags = [...tags];
        _tags[index] = {...tagForm, rules: [...(tagForm.rules || []), ruleForm]};
        setTagForm(_tags[index])
        setTags(_tags);
        setForm({
            ...form,
            tags: _tags
        })
        setSaved(true)
    }
    
    const handleAddTag = e => {
        setSaved(false);
        setTagLabeled(false);
        setTagForm({});
        setIndex(form.tags.length);
    }

    useEffect(() => {
        if(prevIndex.current !== tagIndex) {
            const _tag = form.tags && form.tags[tagIndex] ? form.tags[tagIndex] : {};
            setTagForm(_tag);
            setIndex(tagIndex);
        }
        prevIndex.current = tagIndex;
    },[tagIndex, form]);

    useEffect(() => {
        if(recentlyAddedTagGroup){
            setTagForm({
                ...tagForm,
                group: recentlyAddedTagGroup.name
            })
        }
    }, [recentlyAddedTagGroup]);

    const handleChangeName = e => {
        const value = e.target.value;
        setTagForm({
            ...tagForm,
            name: value
        })
    }
    const handleAddRule = e => {
        setSaved(false);
        setTagLabeled(true);
        setRuleForm({});
    }

    const handleChangeGroup = group => {
        setTagForm({
            ...tagForm,
            group
        })
    }
    
    if(saved){
        return <>
            <Typography variant="h5">
                Your tagging rule was saved successfully.
            </Typography>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
                <Button variant="contained" color="primary" onClick={handleAddRule}>Add another rule</Button>
                <Button variant="contained" color="secondary" onClick={handleAddTag}>Add another tag</Button>
                <Button variant="contained" color="secondary" type="submit" onClick={handleSkip}>Next: Create dashboards</Button>
            </Box>
        </>
    }

    return <Box gap={4} display="flex" flexDirection={"column"}>
        {tagLabeled && <>
            <Typography sx={{
                fontWeight: 'bold',
                mb: 2
            }} color="primary.main" variant="h4"
            >
                Apply tag {tagForm.name} to any feed if:
            </Typography>

            <Grid container spacing={4}>
                <Grid item md={8}>
                    <PostFieldSelect
                        error={errors.field}
                        helperText="The field to verify the condition for"
                        value={ruleForm.field}
                        onChange={field => setRuleForm({...ruleForm, field})}
                        required={true}
                        placeholder="Select field"
                    />
                </Grid>
                <Grid item md={8}>
                    <TagRuleSelect
                        error={errors.rule}
                        helperText="The rule to verify the condition with"
                        value={ruleForm.rule || ""}
                        onChange={rule => setRuleForm({...ruleForm, rule})}
                        required={true}
                        placeholder="Select rule type"
                    />
                </Grid>
                <Grid item md={12}>
                    <TextField
                        required
                        fullWidth
                        multiline={true}
                        rows={3}
                        label="Tagging value"
                        placeholder="Insert tagging value"
                        helperText="The value to check your rule against"
                        error={errors.value}
                        value={ruleForm.value}
                        onChange={e => setRuleForm({...ruleForm, value: e.target.value})}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
                <Button variant="contained" color="primary" onClick={handleDismissDefine}>Skip and create another tag</Button>
                <Button variant="contained" color="secondary" type="submit" onClick={handleSave}>Create tag rule</Button>
            </Box>

        </>}

        {!tagLabeled && <>
        
            <TextField
                fullWidth
                required
                autoFocus
                error={!!errors.name}
                variant="standard"
                label="Tag name"
                helperText={"The label to identify the tag"}
                value={tagForm.name || ""}
                onChange={handleChangeName}
            />
            <Grid container alignItems={"center"}>
                <Grid item md={6}>
                    <TagGroupSelect
                        error={!!errors.group}
                        placeholder="Group name"
                        label="Assign to group"
                        helperText="Name of the tag group"
                        value={tagForm.group || ""}
                        onChange={handleChangeGroup}
                    />
                </Grid>
                <Grid item md={6}>
                    <Button variant="contained" sx={{ml: 5}} color="secondary" onClick={() => dispatch(openDialog("newTagGroup"))}>Create Tag Group</Button>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
                <Button variant="contained" color="primary" onClick={handleSkip}>Skip Tag Creation</Button>
                <Button variant="contained" color="secondary" type="submit" onClick={handleDefineTag}>Save Tag</Button>
            </Box>
        </>}
    </Box>
}
export default ProjectTagsStep;