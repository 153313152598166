import { Facebook } from "@mui/icons-material";
import { Alert, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactFacebookLogin from "react17-facebook-login";
import { enqueueSuccessSnack } from "../../features/snackbar";
import { updateFbKey } from "../../features/user";

function FacebookLogin(){
    const dispatch = useDispatch();
    const [connected, setConnected] = useState(false);

    const responseFacebook = fbResponse => {
        if(fbResponse.status==="connected"){
            setConnected(true);
            var data = {
                FBaccessToken: fbResponse.authResponse.accessToken,
                FBaccessTokenExpirationTimestamp: Math.floor(new Date().getTime() / 1000) + fbResponse.authResponse.expiresIn
            };
            dispatch(updateFbKey(data))
            .then(r => {
                if(r && r.payload){
                    enqueueSuccessSnack("Facebook login completed");
                }
            })
        }else{
            setConnected(false);
        }
    }
    return <>
        <Container component="main" maxWidth="sm">
            <Typography variant="subtitle2" mb={3}>
                Connect with facebook to increase your API limits.
            </Typography>
            {!connected && <ReactFacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name,email"
                callback={responseFacebook}
            /> }

            {connected && <Alert severity="success">
                Facebook login already connected.    
            </Alert>}
            
        </Container>

    </>
}
export default FacebookLogin;