import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
const operators = {
    'default':'At least one of the above',
    'and':'All of the above',
    'not':'None of the above',
    'notstrict':'Not one of the above',
};
const options = Object.keys(operators).map(key => {
    return {
        value: key,
        text: operators[key]
    }
});

function TagFilterOperatorSelect({
    value, 
    onChange,
    size,
    label="Operator", 
    error=false,
    helperText="",
    disabled=false, 
    required=true,
    displayEmpty=true,
    placeholder=""
}){
    
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error}>{label} </InputLabel>
        <Select 
            required={required} 
            error={error}
            disabled={disabled}
            displayEmpty={displayEmpty}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => v ? options.find(o => o.value === v).text : placeholder}

        >
            {options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default TagFilterOperatorSelect;