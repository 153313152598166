import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from '../components/navbar';
import { useSelector } from 'react-redux';
import Sidebar from '../components/sidebar/Sidebar';

const drawerWidth = 260;

const openedMixin = (theme) => ({
    left: 0,
    width: drawerWidth,
    transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(7)} + 1px)`,
    left: (0 - drawerWidth) + 65,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        position: 'absolute',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function AuthenticatedContainer({children, showMenu=true}) {
    const open = useSelector(state => state.app.ui.menuOpen);
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NavBar open={open} showMenu={showMenu} />
            {showMenu && <>
                <Drawer 
                    variant="permanent" 
                    open={open}  
                    PaperProps={{
                        elevation: 5,
                        sx: {
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }
                    }}
                >
                    {/* <DrawerHeader>

                    </DrawerHeader> */}
                    <Sidebar open={open} />
                </Drawer>
            </>}
            <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 9, pl: 12 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}
