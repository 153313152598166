import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { hasErrors } from "../../common";
import { validateDashboardInfo, validateDashboardTimeSpan, validateProjectWizard } from "../../validator/project";
import DashboardBuilder from "../project/wizard/dashboard/DashboardBuilder";
import DashboardFilters from "../project/wizard/dashboard/DashboardFilters";
import DashboardFormInfo from "./DashboardFormInfo";
import DashboardFormTabs from "./DashboardFormTabs";
import DashboardFormTimeSpan from "./DashboardFormTimeSpan";

function MdContent({children, size="sm"}){
    return <Box display={"flex"} justifyContent="center" width={1} p={2}>
        <Box mt={4} display={"flex"}  flexDirection="column" width={1} maxWidth={size} >
            {children}
        </Box>
    </Box>
}

function DashboardForm({form, onChange}){
    const [activeTab, setActiveTab] = useState(0);
    const [errors, setErrors] = useState({});
    const handleNext = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let _errors = {};
        if(activeTab===0){
            _errors = validateDashboardInfo(form);
        }else if(activeTab===1){
            _errors = validateDashboardTimeSpan(form);
        }else if(activeTab===2){
            
        }else if(activeTab===3){
            
        }
        
        setErrors(_errors);
        if(hasErrors(_errors)){
            return;
        }
        setActiveTab(activeTab+1);
    }

    const tabProps = {form, onChange, errors, handleNext, wizard: false};
    const nextButton = <Button type="submit" sx={{mt:2, width: 0.3}} color="secondary" variant="contained" onClick={handleNext}>Next</Button>;
    return <>
        <DashboardFormTabs activeTab={activeTab} onChange={setActiveTab} />
        <form onSubmit={handleNext}>
            {activeTab===0 && <MdContent>
                <DashboardFormInfo {...tabProps} /> 
                {nextButton}
            </MdContent> }

            {activeTab===1 && <MdContent>
                <DashboardFormTimeSpan {...tabProps} /> 
                {nextButton}
            </MdContent> }

            {activeTab===2 && <MdContent size={1}>
                <DashboardFilters {...tabProps} /> 
                {nextButton}
            </MdContent> }

            {activeTab===3 && <MdContent size={1}>
                <DashboardBuilder {...tabProps} />
            </MdContent> }
        </form>
        
    </>
}
export default DashboardForm;