import { Grid, TextField } from "@mui/material";
import UserSelect from "../common/UserSelect";

function GroupForm({form, onChange}){
    console.log(form)
    return <>
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextField
                    fullWidth
                    required
                    disabled
                    size="small"
                    label="Name"
                    value={form.label || ""}
                    onChange={e => onChange({...form, label: e.target.value})}
                />
            </Grid>
            <Grid item sm={12}>
                <UserSelect
                    showCreate={false}
                    label="Users"
                    value={(form.users || []).map(u => (u.id || u) + "")}
                    onChange={v => onChange({...form, users: v})}
                    multiple
                    fullWidth
                />
            </Grid>
        </Grid>
    </>
}
export default GroupForm;