import { prettyTruncate } from "../../../common";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { summarizePost } from "../../../features/post";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from 'react';




function PostTextElement({post, text, noPadding=false, noMargin=false, truncate=false}){
    
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const [iconClicked, setIconClicked] = useState(false);
    
    
    
    const style = {
        fontSize: 13,
        // padding: noPadding ? 0 : null,
        margin: noMargin ? 0 : null,
        // wordBreak: "break-all",
        padding:"3px"
    }
    const translatedStyle = {
        ...style,
        backgroundColor: "#ddf"
    }
    const summaryStyle = {
        ...style,
        backgroundColor: "#ffffcc"
        
    }
    
    const handleIconClick = () => {
        // Perform the summarizePost action here if needed
        // ...
        dispatch(summarizePost({
            uuid: post.uuid,
            projectId: project.id
        }))
        // Update the state to mark the icon as clicked
        setIconClicked(true);
    };
    
    let truncatedText = (text || (post && post.post_text)) || "";
    let translatedText = (post && post.translated) || "";
    let summaryText = (post && post.post_summary) || "";
    
    if(truncate){
        truncatedText = prettyTruncate(truncatedText, truncate);
        translatedText = prettyTruncate(translatedText, truncate);
    }
    
    return <div>
    <p style={style} dangerouslySetInnerHTML={{__html: truncatedText}} />
    {translatedText && (
        <p style={translatedStyle} dangerouslySetInnerHTML={{ __html: translatedText }} />
        )}
        {summaryText && (
            <p style={summaryStyle} dangerouslySetInnerHTML={{ __html: summaryText }} />
            )}
            
            
            <Typography variant="body2">
            {post.post_text && post.post_text.length > 500 && !summaryText && !iconClicked && (
                <SummarizeIcon style={{color:"#aaaaaa",cursor:"pointer",fontSize:22}} onClick={handleIconClick} />
                )}
                </Typography>
                </div>
                
            }
            export default PostTextElement;