import { CopyAll, Delete } from '@mui/icons-material';
import { Box, Button,Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { hasProperties, useContainerDimensions } from '../../common';
import slideTemplates from '../../config/slide_templates';
import SelectReportChartDialog from './SelectReportChartDialog';
import SlotPreviewBox from './SlotPreviewBox';
import SlotSettingsDialog from './SlotSettingsDialog';
import SlotFiltersDialog from './SlotFiltersDialog';
import { useSelector } from 'react-redux';

function SlideLayoutBox({tpl, onClick}){
    const [hover, setHover] = useState(false);
    return <Grid item sm={3} onClick={onClick} onMouseEnter={e => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Paper sx={{cursor: 'pointer'}} elevation={hover ? 5 : 2}>
            <img src={tpl.layoutPreview} style={{width: '100%'}}/>
            <Box pr={2} pl={2} pb={2}>
                <Typography variant="subtitle1" fontWeight={"bold"}>{tpl.layoutTitle}</Typography>
                <Typography variant="body1"><small>{tpl.layoutDesc}</small></Typography>
            </Box>
        </Paper>
    </Grid>
}

function SlideEditorForm({report, form, onChange, errors}){
    const builderRef = useRef(null);
    const { width, height } = useContainerDimensions(builderRef)
    const config = useSelector(state => state.report.config);
    
    const [slotDialog, setSlotDialog] = useState(false);
    const [slotSettingsDialog, setSlotSettingsDialog] = useState(false);
    const [slotFiltersDialog, setSlotFiltersDialog] = useState(false);

    const [addingSlide, setAddingSlide] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);

    const slides = form.slides || [];
    const slide = slides[activeSlide];
    const slideLayout = slide && slideTemplates[slide.layoutId];
    const zoom = (width > 0 ? width : 960) / 1280;
    const zoomPercentage = zoom.toFixed(2) * 100
    const slots = (slide && slide.slots ? slide.slots : slideLayout && slideLayout.slots) || [];



    // Select slot chart
    const handleOpenSlotDialog = slotIndex => {
        setSlotDialog(slotIndex);
    }
    const handleCloseSlotDialog = () => {
        setSlotDialog(false);
    }

    // Edit slot settings
    const handleOpenSlotSettingsDialog = slotIndex => {
        setSlotSettingsDialog(slotIndex);
    }
    const handleCloseSlotSettingsDialog = () => {
        setSlotSettingsDialog(false);
    }

    // Edit slot filters
    const handleOpenSlotFiltersDialog = slotIndex => {
        setSlotFiltersDialog(slotIndex);
    }
    const handleCloseSlotFiltersDialog = () => {
        setSlotFiltersDialog(false);
    }

    const handleCloneSlide = () => {    
        onChange({
            ...form,
            slides: [
                ...slides,
                {
                    ...slide
                }
            ]
        });
        setActiveSlide(slides.length);
    }

    const handleDeleteSlide = () => {
        onChange({
            ...form,
            slides: slides.filter((s,i) => i!==activeSlide)
        });
        if(activeSlide>0){
            setActiveSlide(activeSlide-1);
        }
    }

    const updateSlot = (slot, index) => {

        const _slides = [...slides];
        const _slide = {...slide};
        const _slots = [...slots];
        const _slot = {
            ..._slots[index],
            ...slot
        }
        _slots[index] = _slot;
        _slide.slots = _slots;
        _slides[activeSlide] = _slide;
        // console.log(_slides);
        onChange({
            ...form,
            slides: _slides
        })
    }

    if(addingSlide){
        return <>
            <Typography variant="h6">Select new slide layout</Typography>
            <Grid mt={1} container spacing={2}>
                {slideTemplates.map((tpl, index) => {
                    return <SlideLayoutBox tpl={tpl} key={index} onClick={() => {
                        setAddingSlide(false);
                        onChange({
                            ...form,
                            slides: [
                                ...slides,
                                {
                                    name: "Slide " + (slides.length+1),
                                    layoutId: index,
                                    slots: tpl.slots
                                }
                            ]
                        })
                    }} />
                })}
            </Grid>
        </>
    }
    return <>

        <SelectReportChartDialog 
            onClose={handleCloseSlotDialog} 
            open={slotDialog!==false} 
            onSelect={chart => {
                updateSlot({
                    contentConfig:{id: chart.id}
                }, slotDialog);
                const chartConfig = config && config.find(c => c.id === chart.id);
                if(chartConfig && chartConfig.config && hasProperties(chartConfig.config)){
                    // Se il widget ha delle opzioni, apro la finestra di impostazioni
                    handleOpenSlotSettingsDialog(slotDialog);
                }
                handleCloseSlotDialog();
            }} 
        />

        <SlotSettingsDialog
            onClose={handleCloseSlotSettingsDialog} 
            open={slotSettingsDialog!==false}
            data={slotSettingsDialog!==false && slots[slotSettingsDialog].contentConfig}
            widgetType={slotSettingsDialog!==false && slots[slotSettingsDialog].contentConfig.id}
            onSave={settings => {
                updateSlot({
                    contentConfig:{
                        id: slots[slotSettingsDialog].contentConfig.id,
                        ...settings
                    }
                }, slotSettingsDialog);
                handleCloseSlotSettingsDialog();

            }} 
        />

        <SlotFiltersDialog
            onClose={handleCloseSlotFiltersDialog} 
            open={slotFiltersDialog!==false}
            data={slotFiltersDialog!==false && slots[slotFiltersDialog].query}
            onSave={filters => {
                updateSlot({
                    query: filters
                }, slotFiltersDialog);
                handleCloseSlotFiltersDialog();

            }} 
        />

        <Grid container spacing={4}>
            <Grid item sm={2}>
                <Tabs 
                    orientation="vertical"
                    value={activeSlide}
                    onChange={(e, v) => setActiveSlide(v)}
                    sx={{ 
                        borderRight: 1, 
                        borderColor: 'divider',
                        pr: 2,
                        '& .MuiTabs-indicator': {
                            left: 0
                        }
                    }}
                >
                    {(form.slides || []).map((slide,index) => {
                        return <Tab key={index} label={"Slide " + (index+1)} />
                    })}
                </Tabs>
                <Button sx={{mt: 4, width: '100%'}} onClick={() => setAddingSlide(true)} variant="contained" color="primary">Add slide</Button>
            </Grid>

            <Grid item sm={10}>
                {slide && <>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Typography>{(slideLayout && slideLayout.layoutTitle) || "No layout"} <small>(Zoom {zoomPercentage}%)</small></Typography>
                        <Box display="flex" justifyContent={"flex-end"} gap={2}>
                            <Button size="small" variant="outlined" color="primary" onClick={handleCloneSlide}>
                                <CopyAll />
                                Clone Slide
                            </Button>
                            <Button size="small" variant="outlined" color="error" onClick={handleDeleteSlide}>
                                <Delete />
                                Delete slide
                            </Button>
                        </Box>
                        
                    </Box>

                    <Box ref={builderRef} sx={{position: 'relative'}}>
                        {(slots || []).map((slot, slotIndex) => {
                            return <SlotPreviewBox {...{
                                zoom,
                                report,
                                slot,
                                slotIndex,
                                key: slotIndex,
                                handleSelectChart: () => handleOpenSlotDialog(slotIndex),
                                handleDelete: () => updateSlot({contentConfig: null}, slotIndex),
                                handleOpenSettings: () => handleOpenSlotSettingsDialog(slotIndex),
                                handleOpenFilters: () => handleOpenSlotFiltersDialog(slotIndex),
                                onUpdate: newSlot => updateSlot(newSlot, slotIndex)
                            }} />
                        })}
                    </Box>
                </>}
            </Grid>
        </Grid>
    </>
}
export default SlideEditorForm;