import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {getJobPlatformLabel} from '../../../config/jobplatforms';
import { openDialog } from "../../../features/dialog";

function SourceTable({sources, selection, setSelection}){
    const dispatch = useDispatch();
    
    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != sources.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(sources.map(r => r.id));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Rule</TableCell>
                    <TableCell width={140}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sources.map((source,i) => {                    const selected = selection.indexOf(source.id) !== -1;
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(source.id)} />
                        </TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            {source.name}
                        </TableCell>
                        <TableCell>{getJobPlatformLabel(source.platform)}</TableCell>
                        <TableCell>{source.matchName}={source.matchValue}</TableCell>
                        
                        <TableCell align="right">
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: "editSource", props: source.id}));
                                }}><Edit /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size="small"  onClick={() => {
                                    dispatch(openDialog({id: "deleteSource", props: source.id}));
                                }}><Delete /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default SourceTable;