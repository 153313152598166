import React from "react";
import { Avatar, Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from "react-router-dom";
import { formToJson } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user";

export default function LoginForm() {
    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    const handleSubmit = (e) => {
        e.preventDefault();
        const obj = formToJson(e.currentTarget);
        dispatch(login(obj));
    }

    return <Card sx={{
        p: 4,
        marginTop: 8,
    }}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                {userState.loading && <Box
                    sx={{
                        marginTop: 3,
                        marginBottom: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                ><CircularProgress /></Box>}
                {!userState.loading && <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </Button>}
                <Grid container>
                    <Grid item xs>
                        <Link to="/forgot-password" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Card>
}