import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listSettings, saveSettings } from "../../../features/settings";
import { enqueueSuccessSnack } from "../../../features/snackbar";

function GeneralSettingsSection(){
    const settings = useSelector(state => state.settings.list);
    const dispatch = useDispatch();

    const [showDialog, setShowDialog] = useState(false);
    const [newValue, setNewValue] = useState("");
    const [editFieldName, setEditFieldName] = useState("");

    const onCloseDialog = () => {
        setShowDialog(false) 
    }
    useEffect(() => {
        if(!settings){
            dispatch(listSettings());
        }
    },[settings]);
    const handleSaveSettings = () => {
        dispatch(saveSettings({
            setting_name: editFieldName,
            setting_value: newValue
        }))
        .then(onCloseDialog)
        .then(() => dispatch(enqueueSuccessSnack("Setting saved")))
    }

    const fields = {
        "api_log_persistence":"API log persistence (days)",
        "current_build":"Current platform build (frontend+backend+API)",
        "default_time_window":"Default time window (days)",
        "translate_to":"Default translation lang",
        "login_duration":"Durata di default del login",
        "max_password_age":"Durata massima password",
        "engagement_updates_persistence":"Engagement updates persistence (days)",
        "idle_session_timeout":"Inattività in sec prima del timeout",
        "login_attempts_window":"Intervallo tempo tentativi login (sec)",
        "job_log_persistence":"Job log persistence (days)",
        "last_nestle_sync":"Last Nestle sync to sustainability",
        "enforced_password_length":"Lunghezza password",
        "max_alert_items":"Max number of alert items",
        "grandiosa_timespan":"Minuti di recency foto dashboard MSC Grandiosa 2019",
        "universiade_timespan":"Minuti di recency foto dashboard MSC Universiadi Napoli 2019",
        "netfeedr_total_calls":"Monthly netfeedr call allocation",
        "webhose_total_calls":"Monthly webhose call allocation",
        "widget_time_shift":"Sfasamento in secondi dell'orario dei widget (rispetto a UTC)",
        "time_before_restart":"Tempo (sec) dall'ultima esecuzione di un job che produce un riavvio della coda",
        "max_login_attempts":"Tentativi login prima del lockout",
        "maintenance_mode":"Toggle (0 - 1) maintenance mode",
        "masterindex_threshold":"Valore minimo a cui viene inviato alert",
        "webhose_remaining_calls":"Webhose calls for the current billing period",
        "posts_per_page":"Widget visualizzati per pagina",
        "default_replicas":"replicas per index",
        "default_shards":"shards per index"
    };


    if(!settings){
        return <LinearProgress />
    }
    
    return <Grid container spacing={3}>
        <Dialog
            open={showDialog}
            onClose={onCloseDialog}
            fullWidth
            maxWidth={"xs"}
            
        >
            <DialogTitle>
                Edit settings
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} pb={4} pt={2}>
                    <Grid item sm={12}>
                        <TextField 
                            required
                            onKeyUp={e => {
                                if(e.key === "Enter"){
                                    handleSaveSettings()
                                }
                            }}
                            autoFocus 
                            fullWidth 
                            value={newValue}
                            label={fields[editFieldName]}
                            onChange={e => setNewValue(e.target.value)} 
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onCloseDialog} >Cancel</Button>
                    <Button onClick={handleSaveSettings} variant="contained" color="secondary" >Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
        {Object.keys(fields).map(fieldName => {
            const setting = settings.find(s => s.setting_name === fieldName);
            const value = setting ? setting.setting_value : "";
            return [<Grid key={fieldName} item sm={8} style={{cursor: 'pointer'}}>
                <Tooltip 
                    title="Click to edit" 
                    onClick={() => {
                        setEditFieldName(fieldName);
                        setNewValue(value);
                        setShowDialog(true);
                    }}
                    placement="left"
                >
                    <Typography variant="subtitle1">{fields[fieldName]}</Typography>
                </Tooltip>
            </Grid>,
            <Grid item sm={4} key={fieldName + "_value"}>
                <Typography variant="subtitle2" fontWeight={"bold"}>{value}</Typography>
            </Grid>,
            <Grid item sm={12}>
                <Divider />
            </Grid>
            ]
        })}
    </Grid>
}
export default GeneralSettingsSection;