import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueSnack } from './snackbar';

export const slice = createSlice({
    name: 'post',
    initialState: {
        viewType: 'grid',
        filteredList: [],
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        addToSelection: (state, action) => {
            state.selected = [
                ...state.selected || [],
                action.payload
            ]
        },
        removeFromSelection: (state, action) => {
            state.selected = state.selected.filter(s => s !== action.payload);
        },
        clearSelection: (state, action) => {
            state.selected = [];
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        clearPosts: state => {
            state.filteredList = [];
        },
        setViewType: (state, action) => {
            state.viewType = action;
        },
        toggleViewType: (state, action) => {
            state.viewType = state.viewType==="grid" ? "list" : "grid";
        }
    },
    extraReducers(builder) {
        builder.addCase(translatePost.fulfilled, (state, action) => {
            const {payload} = action;
            const args = action.meta.arg;
            state.filteredList = (state.filteredList || []).map(p => {
                if(p.uuid === args.uuid){
                    return {
                        ...p,
                        translated: payload
                    }
                }
                return p;
            })

        });

        builder.addCase(searchPost.fulfilled, (state, action) => {
            const {payload} = action;
            const args = action.meta.arg;
            state.filters = args;
            state.filteredListOffset = args.offset || 0;
            state.filteredListLimit = args.limit || 100;
            state.filteredList = payload.posts;
            state.filteredListTotal = payload.total;
        })

      }
    
})
export const reloadPosts = createAsyncThunk('post/reload', async (filters, store) => {
    const state = store.getState();
    return state.dispatch(searchPost(state.post.filters));
});

export const searchPost = createAsyncThunk('post/search', async (filters, store) => {
    
    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));
    
    return axios.post("/post/search", filters)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const editPosts = createAsyncThunk('post/edit-bulk', async (params, store) => {
    const state = store.getState();
    params.tab_id = state.project.activeTab;
    return axios.post("/post/edit-bulk", params)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
})

export const deletePosts = createAsyncThunk('post/delete', async(params,store) => {

    
    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));
    
    const selectedPosts = store.getState().post.selected;
    const projectId = store.getState().project.single.id;
    const filters = store.getState().post.filters;
    const tab_id = store.getState().project.activeTab;
    
    return axios.post('/post/delete', { "projectId":projectId, "uuidArray":selectedPosts, "tab_id":tab_id })
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        setTimeout(() => {
            store.dispatch(clearSelection());
            store.dispatch(searchPost(filters,store));
            store.dispatch(slice.actions.setLoading(false));
        },1000); //elastic non fa un update immediato
    })
})

export const translatePost = createAsyncThunk('post/edit-bulk', async (params, store) => {
    return axios.post("/post/translate", params)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
})

export const summarizePost = createAsyncThunk('post/edit-bulk', async (params, store) => {
    return axios.post("/post/summarize", params)
    .then((response) => {
        const data = response.data.summary;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
})

export const {
    clearPosts, 
    clearSelection, 
    addToSelection, 
    removeFromSelection, 
    setError, 
    setLoading, 
    setViewType,
    toggleViewType,
} = slice.actions

export default slice.reducer
