import { Message,ThumbUp, Visibility, People, Theaters, YouTube } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import { parseURL,formatNumber,prettyTruncate } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function YoutubePostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    const title = post.title || post.user_screenname;
    return <>
    
        
        <Box p={padding}>
            <PostTitleElement title={post.user_screenname} link={"https://www.youtube.com/channel/" + post.user_id} />
            <PostImageElement post={post}/>
            <PostTitleElement title={title} post={post} />
            <PostTextElement text={prettyTruncate(text,300)} post={post} />
            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">

            { <Box display="flex" alignItems={"center"} mr={1}>

                <ThumbUp sx={{ fontSize: 14, marginRight: 0.5}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_likes?formatNumber(post.fb_likes):0}</span>

                <Message sx={{ fontSize: 14, marginRight: 0.5, marginLeft: 1}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_comments?post.fb_comments:0}</span>

                <Visibility sx={{ fontSize: 14, color:"#777", marginRight: 0.5, marginLeft: 1}} fontSize="small"/>
                <span style={{fontSize: 12, color:"#777"}}>{formatNumber(post.vk_shares)}</span>
            
                
                <People sx={{ fontSize: 14, color:"#777", marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12 , color:"#777"}}>{formatNumber(post.channel_followers)}</span>
                <Theaters sx={{ fontSize: 14, color:"#777", marginRight: 0.5, marginLeft: 0}} fontSize="small"/>
                <span style={{fontSize: 12 , color:"#777"}}>{formatNumber(post.channel_videos)}</span>
            </Box> }

            <YouTube sx={{color: '#ff0000'}}/>

        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default YoutubePostCard;