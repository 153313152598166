import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLanguageLabel } from '../../config/languages';
import { getPlatformLabel } from '../../config/platforms';
import { getSentimentLabel } from '../../config/sentiments';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack } from '../../features/snackbar';

const DIALOG_ID = "downloadCsv"
const DIALOG_WIDTH = "sm"

function DownloadCsvDialog() {
    const dispatch = useDispatch();
    const params = useParams();
    const project = useSelector(state => state.project.single);
    const activeTab = parseInt(project && project.tabs && project.tabs.length > 0 ? params.tab || project.tabs[0].id : 0);
    const tabs = (project.tabs || []);
    const tab = tabs.find(t => t.id === activeTab) || project.tabs[0] || {};
    const filters = (tab.config && tab.config.filters) || {}

    const [loading, setLoading] = useState(false);
    const show = useSelector((state) => state.dialog[DIALOG_ID])
    
    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }

    const doDownload = () => {
        setLoading(true);
        return axios.post("/post/export", {
            project_id: project.id,
            ...filters || {},
            format: 'text',
            debug: false
        })
        .then(r => {
            const data = r.data;
            var blob=new Blob([data]);
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="export.csv";
            link.click();
        })
        .finally(() => setLoading(false))
        .catch(E => {
            dispatch(enqueueErrorSnack(E))
        })
    }

    useEffect(() => {
        setLoading(false);
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
    >
        <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            {project.name} - Download CSV
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        
        <DialogContent dividers={false} >
            <Box display="flex" alignItems="center" justifyContent="center">
                {!loading && <Button variant="contained" color="secondary" onClick={doDownload} sx={{m: 4}}>Start Download</Button>}
                {loading && <>
                    Preparing download<br/>
                    <LinearProgress />
                </>}
            </Box>
        </DialogContent>
    </Dialog>
}

export default DownloadCsvDialog;