import { Container } from "@mui/material";
import Copyright from "../../components/common/Copyright";
import LoginForm from "../../components/user/LoginForm";
import MainContainer from "../../containers/MainContainer";

export default function LoginPage(){
    return <MainContainer>

        <Container component="main" maxWidth="xs">
            <LoginForm />
            <Copyright />
        </Container>
    </MainContainer>
}