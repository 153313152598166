import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadConfig } from "../../features/report";

function SlotChartBox({chart, onClick}){
    const [hover, setHover] = useState(false);
    return <Grid item sm={4} onClick={onClick} onMouseEnter={e => setHover(true)} onMouseLeave={() => setHover(false)}>
    <Paper sx={{cursor: 'pointer'}} elevation={hover ? 5 : 2}>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{height: 100}}>
            <img src={"/assets/img/report/settings/" + chart.id + ".png"} style={{maxWidth: '100%', maxHeight:100}}/>
        </Box>
        <Box pr={2} pl={2} pb={2} pt={1}>
            <Typography variant="body1" align="center">{chart.title}</Typography>
        </Box>
    </Paper>
</Grid>
}

function SelectReportChartDialog({onClose, open, onSelect}){
    const config = useSelector(state => state.report.config);
    const project = useSelector(state => state.project.single);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!config){
            dispatch(loadConfig(project.label));
        }
    },[]);
    return <Dialog 
        open={open} 
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        >
        <DialogTitle>Set chart settings</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                {config && config.map((r, i) => {
                    return <SlotChartBox chart={r} key={i} onClick={() => onSelect(r)} />
                })}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
}
export default SelectReportChartDialog;