
function Header1Row({report, row, index, onHide}){
    return <tr onClick={() => onHide(index)}>
        <td style={{padding: "0 10px 0 10px"}}>
            <h1 style={{
                fontFamily: report.config.template.templateFonts.headings,
                fontWeight: "bold",
                fontSize: "24px",
                color: report.config.template.templateColors.primary
            }}>{row.content}</h1>
        </td>
    </tr>;
}
export default Header1Row;