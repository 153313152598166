//-----------raccolta layouts predefiniti per slides 
const slideLayouts = [{
    "layoutPreview": "/assets/img/report/layouts/single-page.png",
    "layoutTitle": "Single chart",
    "layoutDesc": "Single chart on one slide",
    "slots": [{
        "slotConfig": {
            "x": 50,
            "y": 50,
            "w": 1180,
            "h": 620
        },
        "contentConfig": {},
        "query": {}
    }]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-square.png",
    "layoutTitle": "Two Squares",
    "layoutDesc": "Two square charts",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 50,
                "w": 565,
                "h": 620
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 50,
                "w": 565,
                "h": 620
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/one-and-two.png",
    "layoutTitle": "One and Two",
    "layoutDesc": "One square and two stacked charts",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 50,
                "w": 565,
                "h": 620
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 50,
                "w": 565,
                "h": 285
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 385,
                "w": 565,
                "h": 285
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-row.png",
    "layoutTitle": "Two rows",
    "layoutDesc": "Two charts on two rows",
    "slots": [{
        "slotConfig": {
            "x": 50,
            "y": 50,
            "w": 1180,
            "h": 285
        },
        "contentConfig": {},
        "query": {}
    }, {
        "slotConfig": {
            "x": 50,
            "y": 385,
            "w": 1080,
            "h": 285
        },
        "contentConfig": {},
        "query": {}
    }]
},
{
    "layoutPreview": "/assets/img/report/layouts/one-and-three.png",
    "layoutTitle": "One and Three",
    "layoutDesc": "One square and three stacked charts",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 50,
                "w": 565,
                "h": 620
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 50,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 273,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 496,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        }
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/three-wide.png",
    "layoutTitle": "Three rows (full)",
    "layoutDesc": "Three stacked full width charts",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 50,
                "w": 1180,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 50,
                "y": 223,
                "w": 1180,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 50,
                "y": 496,
                "w": 1180,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        }
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/three-twothirds.png",
    "layoutTitle": "Three rows (2/3)",
    "layoutDesc": "Three stacked (2/3 wide) charts",
    "slots": [{
            "slotConfig": {
                "x": 443,
                "y": 50,
                "w": 787,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 443,
                "y": 273,
                "w": 787,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 443,
                "y": 496,
                "w": 787,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        }
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/three-half.png",
    "layoutTitle": "Three rows (1/2)",
    "layoutDesc": "Three stacked (1/2 wide) charts",
    "slots": [{
            "slotConfig": {
                "x": 665,
                "y": 50,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 273,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 665,
                "y": 496,
                "w": 565,
                "h": 173
            },
            "contentConfig": {},
            "query": {}
        }
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/one-wide.png",
    "layoutTitle": "One wide (1/3)",
    "layoutDesc": "One wide (1/3 height) chart",
    "slots": [{
        "slotConfig": {
            "x": 50,
            "y": 257,
            "w": 1180,
            "h": 206
        },
        "contentConfig": {},
        "query": {}
    }]
},
{
    "layoutPreview": "/assets/img/report/layouts/four-grid.png",
    "layoutTitle": "Four grid",
    "layoutDesc": "Four charts in a grid",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 50,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 664,
                "y": 50,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 50,
                "y": 384,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 664,
                "y": 384,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-right.png",
    "layoutTitle": "Two right",
    "layoutDesc": "Two charts in the right half of the slide",
    "slots": [{
            "slotConfig": {
                "x": 664,
                "y": 50,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 664,
                "y": 384,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-half.png",
    "layoutTitle": "Two thirds (tall)",
    "layoutDesc": "Two (1/2 height, 2/3 wide) charts stacked",
    "slots": [{
            "slotConfig": {
                "x": 247,
                "y": 50,
                "w": 787,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 247,
                "y": 384,
                "w": 787,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-thirds.png",
    "layoutTitle": "Two thirds (low)",
    "layoutDesc": "Two (1/3 height, 2/3 wide) charts stacked",
    "slots": [{
            "slotConfig": {
                "x": 247,
                "y": 128,
                "w": 787,
                "h": 207
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 247,
                "y": 384,
                "w": 787,
                "h": 207
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
{
    "layoutPreview": "/assets/img/report/layouts/two-under.png",
    "layoutTitle": "Two bottom",
    "layoutDesc": "Two charts in the bottom half of the slide",
    "slots": [{
            "slotConfig": {
                "x": 50,
                "y": 286,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
        {
            "slotConfig": {
                "x": 664,
                "y": 286,
                "w": 566,
                "h": 286
            },
            "contentConfig": {},
            "query": {}
        },
    ]
},
];

export default slideLayouts;