import { common } from '@mui/material/colors';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { updateArray } from '../common';
import { enqueueSnack } from './snackbar';

function checkJobSearchJson(job){
    const res = {
        ...job,
        searchJson: {}
    }
    try{
        if(typeof JSON.parse(job.search) === "object"){
            res.searchJson = JSON.parse(job.search) || {}
        }else{
            res.searchJson = {}
        }
    }catch(E){
        
    }
    return res;
}

export const slice = createSlice({
    name: 'job',
    initialState: {
        bulkToggleCount: 0,
        all: [],
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        
        setJobs: (state, action) => {
            try{
                state.list = (action.payload || []).map(checkJobSearchJson);
            }catch(E){
                console.error(E)
            }
        },

        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(allJobs.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.all = payload.data.map(checkJobSearchJson);
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteJob.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
                state.all = (state.all || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
                state.all = (state.all || []).filter(t => t.id !== meta.arg.id);
            }
        })
        

        builder.addCase(deactivateJob.fulfilled, (state, action) => {
            const jobId = action.meta.arg;
            state.bulkToggleCount = state.bulkToggleCount+1;
            if(Array.isArray(jobId)){
                state.list = (state.list || []).map(j => {
                    if(jobId.indexOf(j.id)!==-1){
                        j.active = false;
                    }
                    return j;
                })
                state.all = (state.all || []).map(j => {
                    if(jobId.indexOf(j.id)!==-1){
                        j.active = false;
                    }
                    return j;
                })
            }else{
                const job = {
                    ...(state.all || state.list).find(j => j.id === jobId),
                    active: false
                }
                state.list = updateArray(state.list, job);
                state.all = updateArray(state.all, job);
            }
        })

        builder.addCase(activateJob.fulfilled, (state, action) => {
            const jobId = action.meta.arg;
            state.bulkToggleCount = state.bulkToggleCount+1;
            if(Array.isArray(jobId)){
                state.list = (state.list || []).map(j => {
                    if(jobId.indexOf(j.id)!==-1){
                        j.active = true;
                    }
                    return j;
                })
                state.all = (state.all || []).map(j => {
                    if(jobId.indexOf(j.id)!==-1){
                        j.active = true;
                    }
                    return j;
                })
            }else{
                const job = {
                    ...(state.all || state.list).find(j => j.id === jobId),
                    active: true
                }
                state.list = updateArray(state.list, job);
                state.all = updateArray(state.all, job);
            }
        })
        
        builder.addCase(editJob.fulfilled, (state, action) => {
            const {payload} = action;
            const parsedJob = checkJobSearchJson(payload);
            state.list = updateArray(state.list, parsedJob);
            state.all = updateArray(state.all, parsedJob);
        })
        
        builder.addCase(addJob.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                checkJobSearchJson(payload)
            ];
            state.all = [
                ...state.all || [],
                checkJobSearchJson(payload)
            ];
        })
      }
    
})


export const deleteJob = createAsyncThunk('job/delete', async (job, store) => {
    const {id, project} = job;
    if(!project){
        return axios.post("/job/delete", {
            job_id: id
        }).then(r => r.data);
    }
    return axios.post("/project/removejob", {
        project_id: project.id,
        job_id: id
    }).then(r => r.data);
});


export const editJob = createAsyncThunk('job/edit', async (job, store) => {
    return axios.post("/job/edit/" + job.id, job).then(r => r.data);
});


export const addJob = createAsyncThunk('job/add', async (data, store) => {
    const {job, project} = data;
    return axios.post("/job/new", {
        project_id: project ? project.id : null,
        ...job
    }).then(r => r.data);
});


export const activateJob = createAsyncThunk('job/on', async (job, store) => {
    return axios.post("/job/on", {
        job_id: job
    }).then(r => r.data);
});


export const deactivateJob = createAsyncThunk('job/off', async (job, store) => {
    return axios.post("/job/off", {
        job_id: job
    }).then(r => r.data);
});


export const allJobs = createAsyncThunk('jobs/all', async (filters, store) => {
    const state = store.getState();
    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));
    const apiKey = state.user.apiKey;
    return axios.get("/job/all/" + apiKey)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const { setError, setLoading, setJobs } = slice.actions

export default slice.reducer
