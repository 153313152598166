import { Box, Grid, LinearProgress, Pagination, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupTable from "../../components/group/GroupTable";
import GroupButtonBar from "../../components/group/GroupButtonBar";
import MainContainer from "../../containers/MainContainer";
import EditGroupDialog from "../../components/group/EditGroupDialog";
import { listGroups } from "../../features/group";
import { numberFormat } from "highcharts";

function GroupsAdminPage(){
    const dispatch = useDispatch();
    const groups = useSelector(state => state.group.list);
    const toggleCount = useSelector(state => state.group.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, _setSearch] = useState("");
    const limit = 10;
    const [page, setPage] = useState(1);
    const offset = (page-1) * limit;
    
    const filteredGroups = (groups || [])
    .filter(u => {
        const res = !search || (u.group_full_name + " " + u.email + " " + u.groupid).toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });
    const pagedGroups = filteredGroups.slice(offset, offset+limit);
    const total = filteredGroups.length;
    const totalPages = Math.ceil(total/limit);

    const setSearch = s => {
        _setSearch(s);
        setPage(1);
    }

    const handleChangePage = (event, _page) => {
        setPage(_page);
    }

    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!groups.find(r => r.id === s);
        }));
    }, [groups]);
    
    useEffect(() => {
        setSelection([]);
    },[toggleCount]);

    useEffect(() => {
        dispatch(listGroups());
    }, []);
    
    const totalFormatted = numberFormat(total,0,".",",");

    const paginationHeader = <Grid container justifyContent={"space-between"} alignItems="flex-end" mt={2}>
        <Grid item display={"flex"} justifyContent="center" alignItems={"center"}>

            {filteredGroups && filteredGroups.length > 0 && <Typography variant="body2">
                Displaying groups from {offset+1} to {Math.min(offset+limit, total)} of <b>{totalFormatted}</b>
            </Typography> }
        </Grid>
        <Grid item>
            {filteredGroups && totalPages > 1 &&  <Stack spacing={2}>
                <Pagination color="secondary" page={page} count={totalPages} onChange={handleChangePage}/>
            </Stack> }
        </Grid>
    </Grid>;

    return <MainContainer>

        <EditGroupDialog />

        <Typography sx={{mb: 4}} color="primary" variant="h2">User Groups</Typography>

        <GroupButtonBar selection={selection} search={search} setSearch={setSearch} />
        {groups === null && <>
            <LinearProgress />
        </>}

        {filteredGroups && filteredGroups.length === 0 && <>
            
            <Paper elevation={2} sx={{mt: 4}}>
                <Box sx={{height: 200}} display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography variant="h4" color="primary.dark">No groups found</Typography>
                </Box>
            </Paper>
        </>}

        {groups && groups.length > 0 && <>
            <GroupTable groups={pagedGroups} selection={selection} setSelection={setSelection} />
        </>}
        
        {paginationHeader}

    </MainContainer>
}
export default GroupsAdminPage;