import {  CheckCircle, CheckCircleOutlined } from "@mui/icons-material";
import { Box, Checkbox, Paper, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import priorities from "../../config/priorities";
import languages from "../../config/languages";
import sentiments from "../../config/sentiments";
import platforms from "../../config/platforms";
import { addToSelection, removeFromSelection } from "../../features/post";
import GenericPostCard from "./card/GenericPostCard";
import TwitterPostCard from "./card/TwitterPostCard";
import PostTitleElement from "./element/PostTitleElement";
import PostTextElement from "./element/PostTextElement";
import { parseURL } from "../../common";

function PostRow({post, project}){
    const dispatch = useDispatch();
    // Selected posts uuids
    const selectedPosts = useSelector(state => state.post.selected);
    // Are we selecting posts?
    const selecting = selectedPosts && selectedPosts.length > 0;
    // Is this post selected?
    const selected = selecting && selectedPosts.indexOf(post.uuid) !== -1;

    const toggleSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(selected){
            dispatch(removeFromSelection(post.uuid));
        }else{
            dispatch(addToSelection(post.uuid));
        }

    }

    const title = post.title || post.user_screenname;
    const text = parseURL(post.post_text);
    return <TableRow 
        hover
        onClick={selecting ? toggleSelect : null}
        sx={selecting && {
            cursor: 'pointer',
            borderColor: (theme) => theme.palette.secondary.main,
            borderWidth: 0,
            borderLeftWidth: selected ? 3 : 0,
            borderStyle: 'solid'
        }}
    >
        <TableCell>
            <Checkbox checked={!!selected} onChange={toggleSelect} />
        </TableCell>
        <TableCell>
            <PostTitleElement title={title} post={post} />
            <PostTextElement text={text} post={post} noPadding noMargin truncate={200} />
        </TableCell>
        <TableCell align="center">
            {platforms[post.platform] || post.platform}
        </TableCell>
        <TableCell align="center">
            {priorities[post.priority]}
        </TableCell>
        <TableCell align="center">
            {sentiments[post.sentiment]}
        </TableCell>
        <TableCell>
            {post.tags && post.tags.length > 0 ? post.tags.map(tag => {
                return project.tags.find(t => t.id === parseInt(tag));
            }).filter(t => !!t).map(t => t.label).join(", ") : "--"}
        </TableCell>
        <TableCell align="center">
            {languages[post.lang]}
        </TableCell>
    </TableRow>
}
export default PostRow;