import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueSuccessSnack } from '../../features/snackbar';
import { addTagGroup } from '../../features/tag';

const DIALOG_ID = "newTagGroup"
const DIALOG_WIDTH = "sm"

function NewTagGroupDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const show = useSelector((state) => state.dialog[DIALOG_ID])

    const [name, setName] = useState("");

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(addTagGroup({name, project})).then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Tag folder saved"))    
        }).finally(() => {
            setLoading(false);
        });
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    useEffect(() => {
        setLoading(false);
    }, [show])

    useEffect(() => {
        setName("");
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Create tag folder
            </DialogTitle>
            <DialogContent dividers={false}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField 
                            autoFocus 
                            fullWidth 
                            value={name}
                            label="Group Name" 
                            helperText="The label to identify the tag folder"
                            onChange={e => setName(e.target.value)} 
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="button" onClick={save}>Create Tag Folder</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default NewTagGroupDialog;