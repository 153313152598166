import { AccountCircle, Logout, Settings } from "@mui/icons-material";
import { Divider, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../features/user";
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle';
import { useNavigate } from "react-router-dom";

export default function UserMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const darkMode = useSelector(state => state.app.ui.darkMode);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        dispatch(logout());
        navigate("/")
    }
    // const handleDarkMode = () => {
    //     setTimeout(() => dispatch(toggleDarkMode()),150);
    // }
    return <>
        <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
        >

            <AccountCircle fontSize="large"/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => navigate("/profile")}>
                <ListItemIcon>
                    <SupervisedUserCircle fontSize="small" />
                </ListItemIcon>
                Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigate("/profile/settings")}>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
            </MenuItem>
            {/* <MenuItem onClick={handleDarkMode}>
                <ListItemIcon>
                    {darkMode ? <Brightness7Icon fontSize="small" /> : <Brightness2Icon fontSize="small" />}
                </ListItemIcon>
                {darkMode ? "Light Mode" : "Dark Mode"}
            </MenuItem> */}
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>

    </>
}