import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSuccessSnack } from '../../../features/snackbar';
import VirtualForm from '../../common/VirtualForm';

const DIALOG_WIDTH = "sm"

function WidgetSettingsDialog({open, config, settings, onClose, onSave}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({

    });

    const save = e => {
        e && e.preventDefault();
        dispatch(enqueueSuccessSnack("Widget settings saved"))
        onSave && onSave(form);
    }

    useEffect(() => {
        setLoading(false);
    }, [open])

    useEffect(() => {
        setForm(settings || {});
    }, [open, settings])

    return <Dialog
        open={!!open}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Widget settings
            </DialogTitle>
            <DialogContent dividers={false}>
                <Grid container spacing={2}>
                    <VirtualForm
                        fields={config || {}}
                        data={form}
                        onChange={data => setForm(data)}                     
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit" onClick={save}>Save</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default WidgetSettingsDialog;