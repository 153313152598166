import { ThirtyFpsSelect } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
        this.setState({
            hasError: true,
            error,
            errorInfo
        })
    }
  
    render() {
        // console.log(this.state)
        return <>
            {this.state.hasError && <Alert
                severity="error"
                style={{
                    // position: 'fixed',
                    // width: '100%',
                    // bottom: 0,
                    // left: 0
                }}
            ><details>
                Something went wrong.<br/>
                {this.state.error && this.state.error.toString()}<br/>
                <pre>
                    {this.state.errorInfo && this.state.errorInfo.componentStack}
                </pre>
            </details></Alert> }
            {!this.state.hasError && this.props.children}
        </>
    }
}
export default ErrorBoundary;