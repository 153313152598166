import { useEffect } from "react";
import ProjectWizard from "../../components/project/ProjectWizard";
import MainContainer from "../../containers/MainContainer";

function NewProjectPage(){
    useEffect(() => {
        
    },[]);
    // const dummyData = {
    //     name: "Test",
    //     label: 'test',
    //     jobs: [],
    //     tags:[],
    //     dashboard: {
    //         name: 'Home',
    //         filters: {},
    //         timeFilters: {
    //             interval: 30
    //         },
    //         type: 'dynamic',
    //         elements: []
    //     }
    // }
    return <MainContainer>
        <ProjectWizard voidable />
    </MainContainer>
}
export default NewProjectPage;