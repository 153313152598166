import {  FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import priorities from '../../config/priorities';
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(priorities).map(key => {
    return {
        value: key,
        text: priorities[key]
    }
});

function PrioritySelect({
    hideMultiple=false,
    value, 
    onChange,
    size,
    error=false,
    label="Priority", 
    hideHelpAdornment=false,
    displayEmpty=false,
    placeholder="",
    disabled=false, 
    required=true,
    showDontChange=false
}){
    const _options = (required ? [...options] : [
        {value: showDontChange ? "" : 'any', text: showDontChange ? "Don't change" : 'Any'},
        ...options
    ]).filter(opt => {
        if(hideMultiple){
            return opt.value!="-1";
        }
        return true;
    });
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            displayEmpty={displayEmpty}
            required={required}
            disabled={disabled}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            endAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="priority" />}
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : "Any"
            }}

        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
    </FormControl>
}
export default PrioritySelect;