import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise, updateArray } from '../common';

export const slice = createSlice({
    name: 'alert',
    initialState: {
        bulkToggleCount: 0,
        list: [],
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        
        setAlerts: (state, action) => {
            try{
                state.list = action.payload;
            }catch(E){
                console.error(E)
            }
        },

        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listAlerts.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.list = payload;
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteAlert.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
            }
        })
        

        builder.addCase(deactivateAlert.fulfilled, (state, action) => {
            const alertId = action.meta.arg;
            state.bulkToggleCount = state.bulkToggleCount+1;
            if(Array.isArray(alertId)){
                state.list = (state.list || []).map(a =>  {
                    if(alertId.indexOf(a.id)!==-1){
                        a.active = false;
                    }
                    return a;
                })
            }else{
                const alert = {
                    ...state.list.find(a =>  a.id === alertId),
                    active: false
                }
                state.list = updateArray(state.list, alert);
            }
        })

        builder.addCase(activateAlert.fulfilled, (state, action) => {
            const alertId = action.meta.arg;
            state.bulkToggleCount = state.bulkToggleCount+1;
            if(Array.isArray(alertId)){
                state.list = (state.list || []).map(a =>  {
                    if(alertId.indexOf(a.id)!==-1){
                        a.active = true;
                    }
                    return a;
                })
            }else{
                const alert = {
                    ...state.list.find(a =>  a.id === alertId),
                    active: true
                }
                state.list = updateArray(state.list, alert);
            }
        })
        
        builder.addCase(editAlert.fulfilled, (state, action) => {
            const {payload} = action;
            const parsedAlert = payload;
            state.list = updateArray(state.list, parsedAlert);
        })
        
        builder.addCase(addAlert.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
        })
      }
    
})


export const deleteAlert = createAsyncThunk('alert/delete', async (alert, store) => {
    const {id} = alert;
    return axios.post("/alert/delete", {alert_id: id}).then(r => r.data);
});


export const editAlert = createAsyncThunk('alert/edit', async (alert, store) => {
    return axios.post("/alert/edit/" + alert.id, alert).then(r => r.data);
});


export const addAlert = createAsyncThunk('alert/add', async (data, store) => {
    const {alert, project} = data;
    return commonApiPromise(axios.post("/alert/add/" + project.id, alert), store)
});


export const activateAlert = createAsyncThunk('alert/on', async (alert, store) => {
    return axios.post("/alert/on", {
        alert_id: alert
    }).then(r => r.data);
});


export const deactivateAlert = createAsyncThunk('alert/off', async (alert, store) => {
    return axios.post("/alert/off", {
        alert_id: alert
    }).then(r => r.data);
});


export const listAlerts = createAsyncThunk('alerts/list', async (projectId, store) => {
    return commonApiPromise(axios.get("/alert/list/" + projectId), store)
})

export const { setError, setLoading, setAlerts } = slice.actions

export default slice.reducer
