import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { editReport } from '../../features/report';
import { validateReport } from '../../validator/report';
import { hasErrors } from '../../common';
import ReportForm from './ReportForm';
import PostFilterForm from '../post/PostFilterForm';
import AlertLayoutForm from './AlertLayoutForm';
import SlideEditorForm from './SlideEditorForm';
import { Close } from '@mui/icons-material';

const DIALOG_ID = "editReport"
const DIALOG_WIDTH = "lg"

function EditReportDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const reports = useSelector(state => state.report.list);
    const reportId = useSelector((state) => state.dialog[DIALOG_ID])
    const show = !!reportId;
    const report = reportId ? reports.find(j => j.id === reportId) : null;
    const [errors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {

        e && e.preventDefault() && e.stopPropagation();
        const errors = validateReport(form);

        if(hasErrors(errors)){
            return;
        }
        setLoading(true);
        dispatch(editReport(form))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Report saved"))    
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm(report);
    }, [report, reportId]);

    useEffect(() => {
        setActiveTab(0)
    }, [show]);

    const config = form && form.config || {query: {}};
    const type = report && report.config && report.config.type;
    if(!show)
        return null;

    
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
    >
        <form onSubmit={save}>
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                Edit report
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            
            <DialogContent dividers={false} style={{
                minHeight: '60vh'
            }}>
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab label="General info" />
                    <Tab label="Filters" />
                    {type === "alert" && <Tab label="Layout" /> }
                    {type === "report" && <Tab label="Slide editor" /> }
                </Tabs>
                <Box pt={3} pr={3}>
                    {activeTab === 0 && <>
                        <ReportForm hideType form={form} onChange={setForm} errors={errors} />
                    </>}    
                    {activeTab === 1 && <>
                        <PostFilterForm key="1" form={config.query} onChange={query => setForm({...form, config: {...config, query}})} errors={errors} />
                    </>}

                    {activeTab === 2 && type === "alert" && <>
                        <AlertLayoutForm template={config.template} onChange={template => setForm({...form, config: {...config, template}})} errors={errors} />
                    </>}
                    {activeTab === 2 && type === "report" && <>
                        <SlideEditorForm report={form} form={config} onChange={config => setForm({...form, config})} errors={errors} />
                    </>}
                </Box>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Save Report</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditReportDialog;