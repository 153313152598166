const subscriptionTypes = {
    "whitelist": 'Whitelist',
    "source": 'Source',
    "all": 'All'
};
function getSubscriptionTypeLabel(type){
    return subscriptionTypes[type] || "--";
}
export default subscriptionTypes;
export {
    getSubscriptionTypeLabel
};