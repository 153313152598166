const tiers = {
    "4": "User",
    "3": "Editor",
    "2": "Superuser",
    "1": "Administrator"
}
const getTierById = id => {
    return tiers[id + ""] || "--";
}
export default tiers;
export {getTierById};