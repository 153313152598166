const jobplatforms = {
    "wh": "Web (All)",
    // "wn": '-- News',
    // "wf": '-- Forums',
    // "wb": '-- Blogs',
    "nftw": 'Twitter Search',
    "nftc": 'Twitter Channel',
    "nf": 'Facebook Search',
    "nc": 'Facebook Channel',
    "ig": 'Instagram Search',
    "ic": 'Instagram Channel',
    "tt": 'TikTok Search',
    "tc": "TikTok Channel",
    "in": 'Linkedin Search',
    "inm": 'Linkedin Member'
    // "rd": 'Reddit',
    // "yt": 'Youtube',
};
const getJobPlatformLabel = (id) => {
    const label = jobplatforms[id];
    return label ? label.replace("--","").trim() : id
}
export default jobplatforms;
export {getJobPlatformLabel};