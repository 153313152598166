import { Help } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

function HelpAdornment({helpKey}){
    const userSettings = useSelector(state => state.user.profile.settings);
    const helpStrings = useSelector(state => state.app.helpStrings);
    const helpString = (helpStrings && helpStrings[helpKey]) || helpKey;
    if(userSettings && parseInt(userSettings.hideHelp)===1)
        return null;
    return <InputAdornment sx={{position: 'absolute', right: -24, bottom: 0, cursor: 'help'}} position="end">
        <Tooltip title={helpString}>
            <Help fontSize="small" />
        </Tooltip>
    </InputAdornment>
}
export default HelpAdornment;