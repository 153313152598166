import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import TagFilterSelect from "../../common/TagFilterSelect";
import BooleanSelect from "../../common/BooleanSelect";
import PostFieldSelect from "../../common/PostFieldSelect";
import HelpAdornment from "../../common/HelpAdornment";
import AlertFrequencySelect from "../../common/AlertFrequencySelect";

function AlertForm({form, onChange, errors}){
    const alertForm = form || {};
    const setAlertForm = f => {
        onChange && onChange(f);
    }
console.log(alertForm)
    return <Grid container spacing={4} columnSpacing={3}>
        <Grid item xs={12}>
            <TextField
                fullWidth
                size="small"
                required
                autoFocus
                error={!!errors.name}
                variant="standard"
                label="Alert name"
                value={alertForm.name || ""}
                onChange={e => setAlertForm({...alertForm, name: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertName"} />
                }}

            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                size="small"
                required
                error={!!errors.subject}
                label="Email subject"
                value={alertForm.subject || ""}
                onChange={e => setAlertForm({...alertForm, subject: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertSubject"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                type="number"
                required
                error={!!errors.maxItems}
                variant="standard"
                label="Max items"
                value={alertForm.maxItems || ""}
                onChange={e => setAlertForm({...alertForm, maxItems: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertMaxItems"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <AlertFrequencySelect
                fullWidth
                required
                error={!!errors.frequency}
                value={alertForm.frequency || ""}
                onChange={frequency => setAlertForm({...alertForm, frequency})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertFrequency"} />
                }}

            />
        </Grid>
        <Grid item xs={12}>
            <TagFilterSelect
                fullWidth
                required
                error={!!errors.tags}
                value={alertForm.tags || []}
                onChange={tags => setAlertForm({...alertForm, tags})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertTags"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <BooleanSelect
                fullWidth
                required
                label="Active"
                value={alertForm.active || 0}
                onChange={active => setAlertForm({...alertForm, active})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertActive"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <PostFieldSelect
                fullWidth
                required
                label="Order by"
                value={alertForm.orderBy || ""}
                onChange={orderBy => setAlertForm({...alertForm, orderBy})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"alertOrderBy"} />
                }}

            />
        </Grid>
    </Grid>
}

export default AlertForm;