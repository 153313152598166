import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteSourceDialog from "./dialog/DeleteSourceDialog";
import EditSourceDialog from "./dialog/EditSourceDialog";
import NewSourceDialog from "./dialog/NewSourceDialog";
import SourceButtonBar from "./SourceButtonBar";
import SourceTable from "./SourceTable";
import { listSources } from "../../../features/source";

function SourceAlertManagement(){
    const dispatch = useDispatch();
    const sources = useSelector(state => state.source.list);
    const project = useSelector(state => state.project.single);
    const toggleCount = useSelector(state => state.source.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, setSearch] = useState("");

    
    const filteredSources = (sources || [])
    .filter(j => {
        const res = !search || j.name.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });


    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!sources.find(r => r.id === s);
        }));
    }, [sources]);

    useEffect(() => {
        dispatch(listSources(project.id))
    }, [project]);



    useEffect(() => {
        setSelection([]);
    },[toggleCount]);
    
    return <>
        <DeleteSourceDialog />
        <EditSourceDialog />
        <NewSourceDialog />

        <SourceButtonBar selection={selection} search={search} setSearch={setSearch} />
        <SourceTable sources={filteredSources} selection={selection} setSelection={setSelection} />
    </>
}
export default SourceAlertManagement;