import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivateAlertDialog from "./dialog/ActivateAlertDialog";
import DeactivateAlertDialog from "./dialog/DeactivateAlertDialog";
import DeleteAlertDialog from "./dialog/DeleteAlertDialog";
import EditAlertDialog from "./dialog/EditAlertDialog";
import NewAlertDialog from "./dialog/NewAlertDialog";
import AlertButtonBar from "./AlertButtonBar";
import AlertTable from "./AlertTable";
import { listAlerts } from "../../../features/alert";

function WhitelistAlertManagement(){
    const dispatch = useDispatch();
    const alerts = useSelector(state => state.alert.list);
    const project = useSelector(state => state.project.single);
    const toggleCount = useSelector(state => state.alert.bulkToggleCount);
    const [selection, setSelection] = useState([]);
    const [search, setSearch] = useState("");

    
    const filteredAlerts = (alerts || [])
    .filter(j => {
        const res = !search || j.name.toLowerCase().indexOf(search.toLowerCase())!==-1
        return res;
    });


    useEffect(() => {
        setSelection((selection || []).filter(s => {
            return !!alerts.find(r => r.id === s);
        }));
    }, [alerts]);

    useEffect(() => {
        dispatch(listAlerts(project.id))
    }, [project]);



    useEffect(() => {
        setSelection([]);
    },[toggleCount]);
    
    return <>
        <DeleteAlertDialog />
        <ActivateAlertDialog />
        <DeactivateAlertDialog />
        <EditAlertDialog />
        <NewAlertDialog />

        <AlertButtonBar selection={selection} search={search} setSearch={setSearch} />
        <AlertTable alerts={filteredAlerts} selection={selection} setSelection={setSelection} />
    </>
}
export default WhitelistAlertManagement;