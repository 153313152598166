import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { clearPosts, clearSelection, editPosts, searchPost } from '../../features/post';
import { enqueueSuccessSnack } from '../../features/snackbar';
import LanguageSelect from '../common/LanguageSelect';
import PrioritySelect from '../common/PrioritySelect';
import SentimentSelect from '../common/SentimentSelect';
import TagFilterSelect from '../common/TagFilterSelect';

const DIALOG_ID = "editPosts"
const DIALOG_WIDTH = "md"

function EditPostsDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const show = useSelector((state) => state.dialog[DIALOG_ID])
    const project = useSelector(state => state.project.single);
    const posts = useSelector(state => state.post.filteredList);
    const selected = useSelector(state => state.post.selected);
    const filters = useSelector(state => state.post.filters);
    const [form, setForm] = useState({});
    const [strategy, setStrategy] = useState("selection");

    const handleApply = e => {
        switch(strategy){
            case 'selection': handleApplySelection(e); break;
            case 'page': handleApplyPage(e); break;
            case 'result': handleApplyResult(e); break;
            default: handleApplySelection(e); break; //as a default apply to selection
        }
    }

    const handleApplyPage = e => {
        save(e, {
            selectedids: posts.map(p => p.uuid),
            ...form
        })
    }
    const handleApplyResult = e => {
        save(e, {
            ...filters,
            ...form
        })
    }
    const handleApplySelection = e => {
        save(e, {
            selectedids: selected,
            ...form
        })
    }

    const save = (e,params) => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(clearSelection());
        dispatch(clearPosts());
        dispatch(editPosts({
            ...params,
            project_label: project.label
        })).then(r => {
            setTimeout(() => {
                // Elastic sembra non reagire immediatamente all'update
                setLoading(false);
                dispatch(enqueueSuccessSnack(r.payload.count + " post(s) saved"))
                dispatch(searchPost(filters));
                dispatch(closeDialog(DIALOG_ID));    
            },1500);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    useEffect(() => {
        setLoading(false);
    }, [show])

    useEffect(() => {
        setForm({})
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Edit posts
            </DialogTitle>
            <DialogContent dividers={false}>
                <Box pt={6} pb={6}>
                    <Grid container spacing={4}>
                        <Grid item sm={6}>
                            <TagFilterSelect 
                                fullWidth
                                label="Add tags" 
                                value={form.addtags || []} 
                                placeholder="type in tag name(s)"
                                displayEmpty
                                onChange={t => setForm({...form, addtags: t})}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TagFilterSelect 
                                fullWidth
                                label="Remove tags" 
                                value={form.deltags || []} 
                                placeholder="type in tag name(s)"
                                displayEmpty
                                onChange={t => setForm({...form, deltags: t})}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <SentimentSelect 
                                fullWidth
                                hideHelpAdornment
                                label="Change sentiment to" 
                                value={form.setsentiment || ""} 
                                placeholder="Don't change"
                                required={false}
                                showDontChange={true}
                                showAny={false}
                                displayEmpty
                                onChange={t => setForm({...form, setsentiment: t})}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <LanguageSelect 
                                required={false}
                                showDontChange
                                fullWidth
                                hideHelpAdornment
                                label="Change language to" 
                                value={form.setlang || ""} 
                                placeholder="Don't change"
                                displayEmpty
                                onChange={t => setForm({...form, setlang: t})}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <PrioritySelect 
                                required={false}
                                showDontChange
                                fullWidth
                                hideHelpAdornment
                                label="Change priority to" 
                                value={form.setpriority || ""} 
                                placeholder="Don't change"
                                displayEmpty
                                onChange={t => setForm({...form, setpriority: t})}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <RadioGroup value={strategy} onChange={e => setStrategy(e.target.value)}>
                                <FormControlLabel value={"selection"} control={<Radio />} label="Apply to selection" />
                                <FormControlLabel value={"page"} control={<Radio />} label="Apply to page" />
                                <FormControlLabel value={"result"} control={<Radio />} label="Apply to all results (max 50.000 posts)" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Box>
                {loading && <Box><LinearProgress /></Box>}
            </DialogContent>
            <DialogActions>

                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button onClick={onClose} >Cancel</Button>
                    <Stack direction={"row"} spacing={2}>
                        <Button variant="contained" color="secondary" type="button" onClick={handleApply}>Apply</Button>
                    </Stack>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditPostsDialog;