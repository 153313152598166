import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadConfig } from "../../features/report";
import VirtualForm from '../../components/common/VirtualForm';


function SlotSettingsDialog({onClose, open, onSave, widgetType, data}){
    const config = useSelector(state => state.report.config);
    const project = useSelector(state => state.project.single);
    const dispatch = useDispatch();
    const [form, setForm] = useState(data);
    useEffect(() => {
        if(!config){
            dispatch(loadConfig(project.label));
        }
    },[]);

    useEffect(() => {
        setForm(data);
    }, [data]);

    if(!config){
        return null;
    }
    const widgetConfig = config.find(c => c.id === widgetType);
    return <Dialog 
        open={open} 
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        >
        <DialogTitle>Edit settings</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                {widgetConfig && widgetConfig.config && <VirtualForm fields={widgetConfig && widgetConfig.config} data={form} onChange={f => setForm(f)} /> }
            </Grid>
        </DialogContent>
        <DialogActions>
            <Box display="flex" justifyContent={"space-between"} width={1}>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={() => onSave(form)}>Save</Button>
            </Box>
        </DialogActions>
    </Dialog>
}
export default SlotSettingsDialog;