import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { addUser } from '../../features/user';
import UserForm from './UserForm';
import { hasErrors } from '../../common';
import { validateUser } from '../../validator/user';

const DIALOG_ID = "newUser"
const DIALOG_WIDTH = "md"

function NewUserDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const show = !!useSelector((state) => state.dialog[DIALOG_ID])
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateUser(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(addUser(form))
        .then(r => {
            if(r.payload){
                dispatch(closeDialog(DIALOG_ID));
                dispatch(enqueueSuccessSnack("User saved"))    
            }
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm({
            tier: 4,
            active: true
        });
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Create user
            </DialogTitle>
            <DialogContent dividers={false}>

                <Box pr={3}>
                    <UserForm form={form} onChange={setForm} errors={errors} />
                </Box> 
                
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Create User</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default NewUserDialog;