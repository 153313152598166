import { Alert, Box, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../containers/MainContainer";
import { useParams } from 'react-router';
import React,{ useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import ProjectTabs from "../../components/project/ProjectTabs";
import ProjectTab from "../../components/project/ProjectTab";
import PostFilterInlinePanel from "../../components/post/PostFilterInlinePanel";
import { loadProject, setSingle, updateTab, setActiveTab } from "../../features/project";
import EditProjectDialog from "../../components/project/EditProjectDialog";
import CommonFab from "../../components/common/CommonFab";
import { setTagGroups, setTags } from "../../features/tag";
import { setJobs } from "../../features/job";
import EditDashboardDialog from "../../components/dashboard/EditDashboardDialog";
import NewReportDialog from "../../components/report/NewReportDialog";
import { openDialog } from "../../features/dialog";
import AddFeedDialog from "../../components/feed/AddFeedDialog";
import DatasetDialog from "../../components/project/DatasetDialog";
import DownloadCsvDialog from "../../components/project/DownloadCsvDialog";

function ProjectPage(){
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const list = useSelector(state => state.project.list);
    // const [project, setProject] = useState();
    const params = useParams();
    // console.log("params",params)

    const activeTab = parseInt(project && project.tabs && project.tabs.length > 0 ? params.tab || project.tabs[0].id : 0);

    // Dispatch the action to save the activeTab to the global state
    useEffect(() => {
        dispatch(setActiveTab(activeTab));
    }, [activeTab, dispatch]);

    useEffect(() => {
        dispatch(loadProject(params.label)).then(resp => {
            if(resp && resp.payload){
                dispatch(setTagGroups(resp.payload.tagfolders));
                dispatch(setTags(resp.payload.tags));
                dispatch(setJobs(resp.payload.jobs));
            }
        });
    },[params.label, list, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSingle(null));
        }
    }, []);

    if(!project){
        return <MainContainer>
            <LinearProgress />
        </MainContainer>;
    }


        
    const tabs = (project.tabs || []);
    const tab = tabs.find(t => t.id === activeTab) || project.tabs[0] || {};
    const filters = (tab.config && tab.config.filters) || {}

    const handleSaveFilters = _filters => {
        dispatch(updateTab({
            id: tab.id,
            filters: _filters
        }))
    }

    // console.log(filters);

    return <MainContainer>
        <DatasetDialog />
        <DownloadCsvDialog />
        <EditProjectDialog />
        
        <AddFeedDialog />
        <EditDashboardDialog />

        <NewReportDialog />
        
        {list === null && <>
            <LinearProgress />
        </>}

        {project && <>
            <PageTitle title={project.name + (project.archived ? " - archived" : "")} />
            
            <ProjectTabs project={project} activeTab={activeTab} />

            {tab.id ? <Box pb={6}>
                <PostFilterInlinePanel tab={tab} project={project} data={filters} onChange={handleSaveFilters} />
                <ProjectTab project={project} tab={tab} />
            </Box> : <>
                <Alert severity="warning">Project misconfigured</Alert>
            </>}

        </>}
        <CommonFab onClick={() => dispatch(openDialog("addFeed"))} />
    </MainContainer>
}
export default ProjectPage;