import { Box, Button, Typography } from "@mui/material";
import PostFilterForm from "../../../post/PostFilterForm";

function DashboardFilters({handleNext, onChange, form, wizard=true}){
    const config = form.config || {};
    
    const setForm = filters => {
        onChange({
            ...form,
            config: {
                ...config,
                filters
            }
        })
    }
    const margin = 4;
    return <>
        {wizard && <Box sx={{mt: margin, mb: margin}}>
            <Typography color="primary.main" variant="h4">Create a filter for your dashboard</Typography>
            <Typography color="primary.main" variant="caption">You can edit/redefine filters anytime.</Typography>
        </Box>}
        <PostFilterForm date={false} form={config.filters ? config.filters || {} : {}} onChange={_form => setForm(_form)} />
        
        {wizard && <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleNext}>
                Next: Select data visualizations
            </Button>
        </Box>}
    </>

}
export default DashboardFilters;