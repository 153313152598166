import { Link, Typography } from "@mui/material";
import React from "react";

export default function Copyright(){
    return <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }} >
        {'Copyright © '}
        <Link target="_blank" color="inherit" href="https://buzztech.it">
            Buzztech
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
}