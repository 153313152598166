
function SeparatorRow({report, row, index, onHide}){
    return <tr onClick={() => onHide(index)}>
        <td>
            <hr style={{
                    backgroundColor: report.config.template.templateColors.primary,
                    color: report.config.template.templateColors.primary,
                    height: 3

            }} />
        </td>
    </tr>;
}
export default SeparatorRow;