import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadProjectList } from "../../features/project";
import { Close, CloudDownload, ExpandLess, ExpandMore, Folder, Group, MiscellaneousServices, Person, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { toggleMenu } from "../../features/app";


function MenuItem({item, open, depth=0}){
    const navigate = useNavigate();
    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const handleClick = (e) => {
        e.stopPropagation();
        setSubMenuOpen(!subMenuOpen);
    }
    const textColor = {color: '#FFFFFF'};
    const inner = <ListItemButton
        onClick={() => navigate(item.link)}
        sx={{
            // minHeight: 28,
            p: 0,
            pt: 0.5, 
            pb: 0.5, 
            lineHeight: 2,
            borderBottom: depth,
            // justifyContent: open ? 'initial' : 'center',
            justifyContent: 'initial',
            mt: depth === 0 ? 3 : 0
            //px: 2.5,
        }}
    >
        {item.icon && <ListItemIcon
            sx={{
                ...textColor,
                minWidth: 0,
                // mr: open ? 3 : 'auto',
                mr: 3,
                justifyContent: 'center',
            }}
        >
            {item.icon}
        </ListItemIcon> }
        <ListItemText 
            primary={item.label} 
            sx={{
                pl: depth * 6,            
                fontWeight: depth === 0 ? 'bold' : 'normal',
                ...textColor
            }}
        />
        {item.items &&  <IconButton onClick={handleClick} sx={{pr: 0}}>
            {subMenuOpen ? <ExpandLess sx={textColor}/> : <ExpandMore sx={textColor} />}
        </IconButton>
}
    </ListItemButton>;
    return <>
        {/* {open ? inner : 
        <Tooltip 
            title={item.label} 
            placement="right"
        >
            {inner}
        </Tooltip> } */}
        {inner}

        {item.items && <>
            <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{color: '#FFFFFF'}}>
                    {item.items.map((subitem, index) => {
                        return <MenuItem item={subitem} key={index} depth={depth+1} ></MenuItem>
                    })}
                </List>
            </Collapse>
        </>}
    </>
}

export default function Sidebar({open}){
    const isAdmin = useSelector(state => state.user.isAdmin);
    const projects = useSelector(state => state.project.list);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadProjectList());
    },[dispatch]);

    const handleDrawerOpen = () => {
        dispatch(toggleMenu(true));
    };

    const handleDrawerClose = () => {
        dispatch(toggleMenu(false));
    };
    const items = [
        {
            label: 'MY PROJECTS',
            icon: <Folder />,
            link: '/projects',
            items: projects && projects.filter(p => !p.archived).map(p => {
                return {
                    label: p.name,
                    link: '/project/' + p.label
                    
                }
            })
        }
    ]
    if(isAdmin){
        items.push({
            label: 'USERS',
            icon: <Person />,
            link: '/admin/users'
        });
        items.push({
            label: 'GROUPS',
            icon: <Group />,
            link: '/admin/groups'
        });
        items.push({
            label: 'JOBS',
            icon: <CloudDownload />,
            link: '/admin/jobs'
        });

        items.push({
            label: 'SETTINGS',
            icon: <Settings />,
            link: '/admin/settings'
        });
        items.push({
            label: 'SERVICE STATUS',
            icon: <MiscellaneousServices />,
            link: '/admin/status'
        });
    }
    
    return <ClickAwayListener onClickAway={handleDrawerClose}>
        <List>
            <Box justifyContent={"flex-end"} display="flex" pr={1} mb={4}>
                <IconButton
                    onClick={() => open ? handleDrawerClose() : handleDrawerOpen()}
                    color="white"
                >
                    {open ? <Close fontSize="large" /> : <MenuIcon fontSize="large"/>}
                </IconButton>
            </Box>
            <Box pl={3} pr={3}>
                <Box  sx={{zoom: 0.8}}>
                    {open && items.map((item, index) => {
                        return <MenuItem open={open} item={item} key={index}></MenuItem>
                    })}
                </Box>
            </Box>
        </List>
    </ClickAwayListener>
}