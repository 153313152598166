import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";
import { getTierById } from "../../config/tiers";

function UserTable({users, selection, setSelection}){
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const projects = useSelector(state => state.project.list);

    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width={40}>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != users.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(users.map(r => r.userid));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Full name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Last login</TableCell>
                    <TableCell>Last password change</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Groups</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((user,i) => {
                    const selected = selection.indexOf(user.userid) !== -1;
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(user.userid)} />
                        </TableCell>
                        <TableCell width={40}>{user.userid}</TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            {user.user_full_name}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{getTierById(user.tier)}</TableCell>
                        <TableCell>
                            {user.last_login ? moment(user.last_login * 1000).fromNow() : "Never"}
                        </TableCell>
                        <TableCell>
                            {user.last_password_change ? moment(user.last_password_change * 1000).fromNow() : "Never"}
                        </TableCell>
                        <TableCell>{user.active ? "Yes" : "No"}</TableCell>
                        <TableCell>{(user.groups || []).map(g => <div key={g.id}>{g.label}</div>)}</TableCell>
                        
                        <TableCell align="right">
                            <Tooltip title={user.active ? "Deactivate" : "Activate"}>
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: user.active ? "deactivateUser" : "activateUser", props: user.userid}));
                                }}>
                                    {user.active ? <Stop /> : <PlayArrow /> }
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={() => {
                                    dispatch(openDialog({id: "editUser", props: user.userid}));
                                }}><Edit /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton size="small"  onClick={() => {
                                    dispatch(openDialog({id: "deleteUser", props: user.userid}));
                                }}><Delete /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default UserTable;