import { Button, Grid, LinearProgress, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { nextStep, setStep, skipStep } from "../../features/projectwizard";
import NewTagGroupDialog from "../tag/NewTagGroupDialog";
import ProjectDashboardsStep from "./wizard/ProjectDashboardsStep";
import ProjectInfoStep from "./wizard/ProjectInfoStep";
import ProjectJobStep from "./wizard/ProjectJobsStep";
import ProjectReviewStep from "./wizard/ProjectReviewStep";
import ProjectTagsStep from "./wizard/ProjectTagsStep";


function ProjectWizard({ voidable=false, data }){
    const dispatch = useDispatch();
    const [form, setForm] = useState({});
    const activeStep = useSelector(state => state.projectWizard.step);
    const loading = useSelector(state => state.projectWizard.loading);

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(-1);
    }

    const handleNext = (e) => {
        e && e.preventDefault && e.preventDefault();
        if(activeStep < maxStep){
            // Next step
            dispatch(nextStep(form))
            return;
        }
//        alert("Salvo");
        // Save
    }

    const handleSkip = (e) => {
        e && e.preventDefault && e.preventDefault();
        dispatch(skipStep())
    }

    const steps = [
        {
            label: 'Basic project info',
            text: 'Insert the project name and basic options'
        },
        {
            label: 'Define jobs',
            text: 'Start defining the scope of your search (you can add more later on)'
        },
        {
            label: 'Create and organize tags',
            text: 'Define tag folders, names and rules'
        },
        {
            label: 'Create dashboards',
            text: 'Select filters and data visualizations'
        },
        {
            label: 'Start collecting data',
            text: ''
        },
    ]
    const maxStep = steps.length;

    useEffect(() => {
        dispatch(setStep(0));
        setForm(data || {});
    },[data, dispatch]);

    const stepContentProps = {
        handleNext,
        handleSkip,
        form,
        step: activeStep,
        onChange: f => setForm({...form, ...f})
    }

// console.log(form);

    return <>
        {/* DIALOGS */}
        <NewTagGroupDialog />

        <Typography color="primary.main" variant="h1" sx={{pb: 4}}>
            {form.id ? form.name : "New Project"}
        </Typography>

        <Grid container>
            <Grid item sm={4} lg={3} sx={{pr: 6}}>
                <Stepper orientation="vertical" activeStep={activeStep} sx={{
                            
                    '& .MuiStepLabel-root .Mui-active': {
                        color: 'secondary.dark', // circle color (ACTIVE)
                    },
                }}>
                    {steps.map((step, index) => {

                        return <Step key={index}>
                            <StepLabel onClick={() => activeStep > index && dispatch(setStep(index))}>
                                {step.label}
                            </StepLabel>
                            {activeStep === index &&
                                <StepContent>
                                    <Typography variant="caption">{step.text}</Typography>
                                </StepContent>
                            }   
                        </Step>
                    })}
                </Stepper>

            </Grid>

            <Grid item sm={8} lg={9} >
                <Box 
                    maxWidth={"md"} 
                    onSubmit={handleNext}
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    {loading && <LinearProgress />}

                    <Typography color="primary.main" variant="h2" sx={{fontWeight: 'normal', pb: 4}}>
                        {steps[activeStep].label}
                    </Typography>
                    

                    {activeStep === 0 && <ProjectInfoStep {...stepContentProps}/>}
                    {activeStep === 1 && <ProjectJobStep {...stepContentProps}/>}
                    {activeStep === 2 && <ProjectTagsStep {...stepContentProps}/>}
                    {activeStep === 3 && <ProjectDashboardsStep {...stepContentProps}/>}
                    {activeStep === 4 && <ProjectReviewStep {...stepContentProps}/>}


                </Box>
            </Grid>
        </Grid>
        <Box mt={4} display="flex" justifyContent={"space-between"}>
            
            {voidable && <Button variant="outlined" color="secondary" onClick={handleCancel}>Cancel</Button>}
            {/* <Button variant="contained" color="primary" onClick={handleNext}>Next</Button> */}
        </Box>
    </>
}
export default ProjectWizard;