import { Add, Delete, Edit, FilterAlt, Settings } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hasProperties } from "../../common";

const MyBox = ({children}) => {

    return <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        sx={{height: '100%', width: '100%'}}
    >{children}</Box>;
}

function SlotPreviewBox({slot, slotIndex, report, handleSelectChart, handleDelete, handleOpenFilters, handleOpenSettings, zoom}){
    const {slotConfig} = slot;
    const theme = useTheme();
    const config = useSelector(state => state.report.config);
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState(false);
    const slotStyle = {
        border: '1px solid #CCC'
    }
    

    useEffect(() => {
        setContent(null);
        if(!slot || !report)
            return;
        if(slot.contentConfig && slot.contentConfig.id){
            setLoading(true);
            axios.post("/report/slotPreview",{
                slot: {
                    ...slot,
                    query: {
                        ...report.config.query || {},
                        ...slot.query || {},
                        project_id: report.projectId
                    }
                },
                report_id: report.id,
            })
            .then(r => setContent(r.data))
            .finally(() => setLoading(false))
        }
    },[slot, slotIndex, report]);

    
    const isImage = content && content.type==="image";
    const widgetTypeSettings = config && config.find(c => c.id === (slot.contentConfig && slot.contentConfig.id));
    return <Box 
        key={slotIndex} 
        sx={{
            p: 4,
            position: 'absolute',
            left: slotConfig.x * zoom,
            top: slotConfig.y * zoom,
            width: slotConfig.w * zoom,
            height: slotConfig.h * zoom,
            ...slotStyle
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {loading ? <MyBox>
            <CircularProgress />
        </MyBox> : <>
            {content && hover && <Box 
                sx={{
                    width: '100%',
                    position: 'absolute', 
                    right: 0, 
                    top: -0,
                    // backgroundColor: alpha(theme.palette.secondary.main,0.7)
                }} 
                justifyContent="flex-end"
                display="flex"
            >
                {widgetTypeSettings && hasProperties(widgetTypeSettings.config) && <Tooltip title="Edit settings"><IconButton onClick={handleOpenSettings} size="small"><Settings color="primary" /></IconButton></Tooltip>}
                <Tooltip title="Edit filters"><IconButton onClick={handleOpenFilters} size="small"><FilterAlt color="primary" /></IconButton></Tooltip>
                <Tooltip title="Change chart type"><IconButton onClick={handleSelectChart} size="small"><Edit color="primary" /></IconButton></Tooltip>
                <Tooltip title="Remove widget"><IconButton onClick={handleDelete} size="small"><Delete color="error" /></IconButton></Tooltip>
            </Box> }
            {content && isImage && <img style={{maxWidth: '100%', maxHeight: '100%'}} src={content.image} /> }
            {content && !isImage && <div style={{overflow: 'hidden', maxWidth:'100%'}} dangerouslySetInnerHTML={{__html: content}}  /> }
            {!content && <>
                <MyBox>
                    <IconButton onClick={handleSelectChart}><Add sx={{fontSize: 96}} /></IconButton>
                </MyBox>
            </>}
        </>}
    </Box>
};

export default SlotPreviewBox;