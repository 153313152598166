import { Avatar, Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from "react-router-dom";

export default function ForgotPasswordForm(){
    const handleSubmit = e => {

    }
    return <Card sx={{
        p: 4,
        marginTop: 8,
    }}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Recover password
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link to="/" variant="body1">
                            Back to login
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Card>
}