import { Container } from "@mui/material";
import Copyright from "../../components/common/Copyright";
import ForgotPasswordForm from "../../components/user/ForgotPasswordForm";
import MainContainer from "../../containers/MainContainer";

export default function ForgotPasswordPage(){
    return <MainContainer>

        <Container component="main" maxWidth="xs">
            <ForgotPasswordForm />
            <Copyright />
        </Container>
    </MainContainer>
}