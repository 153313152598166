import cronstrue from 'cronstrue';

const alertfrequencyArray = [
    "* * * * ? *",
    "0 * * * *",
    "0 * * * *",
    "30 5 * * 1,6",
    "0 15 10 ? * *",
];
const alertfrequency = alertfrequencyArray.reduce((carry, item) => {
    try{
        carry[item] = cronstrue.toString(item);
    }catch(E){
        console.error(E);
        carry[item] = item;
    }
    return carry;
}, {})
console.log(alertfrequency)
const getAlertFrequencyLabel = (id) => {
    const label = alertfrequency[id];
    return label || "";
}

export default alertfrequency;
export {getAlertFrequencyLabel};