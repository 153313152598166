import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CommonFab({
    link, 
    onClick=null, 
    icon=<Add/>,
    color="primary",
    label="add"
}){
    const navigate = useNavigate();
    return <Fab 
        color={color}
        aria-label={label}
        onClick={onClick ? onClick : () => {
            navigate(link);
        }}
        sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(3),
            right: (theme) => theme.spacing(3)
        }}          
    >
        {icon}
    </Fab>
}
export default CommonFab