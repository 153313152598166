import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing/LandingPage";
import LoginPage from "./pages/login";
import ForgotPasswordPage from "./pages/forgotpassword";
import ProjectsPage from "./pages/projects/ProjectsPage";
import NewProjectPage from "./pages/projects/NewProjectPage";
import ProjectPage from "./pages/projects/ProjectPage";
import AlertView from "./components/report/AlertView";
import JobsAdminPage from "./pages/admin/JobsAdminPage";
import UsersAdminPage from "./pages/admin/UsersAdminPage";
import ProfilePage from "./pages/profile/ProfilePage";
// import EditProjectDialog from "./components/project/EditProjectDialog";
import EditProjectPage from "./pages/projects/EditProjectPage";
import SettingsPage from "./pages/admin/SettingsPage";
import ServiceStatusPage from "./pages/admin/ServiceStatusPage";
import GroupsAdminPage from "./pages/admin/GroupsAdminPage";

import ExternalAppPage from "./pages/projects/ExternalApp";

export default function AppRouter(){

    const user = useSelector(state => state.user.profile);
    const isAdmin = useSelector(state => state.user.isAdmin);
    return <>
        <BrowserRouter>
            {user && <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/profile/:section" element={<ProfilePage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/project/:label" element={<ProjectPage />} />
                <Route path="/project/:label/edit" element={<EditProjectPage />} />
                <Route path="/project/:label/:tab" element={<ProjectPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/new" element={<NewProjectPage />} />
                {isAdmin && <Route path="/admin/jobs" element={<JobsAdminPage />} /> }
                {isAdmin && <Route path="/admin/users" element={<UsersAdminPage />} />}
                {isAdmin && <Route path="/admin/groups" element={<GroupsAdminPage />} />}
                {isAdmin && <Route path="/admin/settings/:section" element={<SettingsPage />} />}
                {isAdmin && <Route path="/admin/settings" element={<SettingsPage />} />}
                {isAdmin && <Route path="/admin/status" element={<ServiceStatusPage />} />}

                <Route path="/alert-view/:reportId" element={<AlertView />} />
                
                <Route path="/app/:appId" element={<ExternalAppPage />} />
            </Routes>}
                
            {!user && <Routes>
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="*"  element={<LoginPage />} />
            </Routes> }
        </BrowserRouter>
    </>
}