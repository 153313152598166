import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise, updateArray } from '../common';
import project from './project';

export const slice = createSlice({
    name: 'source',
    initialState: {
        bulkToggleCount: 0,
        list: [],
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        
        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listSources.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.list = payload;
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteSource.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
            }
        })
        
        builder.addCase(editSource.fulfilled, (state, action) => {
            const {payload} = action;
            const parsedSource = payload;
            state.list = updateArray(state.list, parsedSource);
        })
        
        builder.addCase(addSource.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
        })
      }
    
})


export const deleteSource = createAsyncThunk('source/delete', async (source, store) => {
    const {id, project} = source;
    return axios.post("/alert/source_delete/" + project.id, {source_id: id}).then(r => r.data);
});


export const editSource = createAsyncThunk('source/edit', async (data, store) => {
    const {source, project} = data;
    return axios.post("/alert/source_edit/" + source.id + "/" + project.id, source).then(r => r.data);
});


export const addSource = createAsyncThunk('source/add', async (data, store) => {
    const {source, project} = data;
    return commonApiPromise(axios.post("/alert/source_add/" + project.id, source), store)
});


export const listSources = createAsyncThunk('sources/list', async (projectId, store) => {
    return commonApiPromise(axios.get("/alert/source_list/" + projectId), store)
})

export const { setError, setLoading, setSources } = slice.actions

export default slice.reducer
