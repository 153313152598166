import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { editTag } from '../../features/tag';
import TagGroupSelect from '../common/TagGroupSelect';

const DIALOG_ID = "editTag"
const DIALOG_WIDTH = "md"

function EditTagDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const tagId = useSelector((state) => state.dialog[DIALOG_ID])
    const tags = useSelector(state => state.tag.list);
    const tag = tags.find(t => t.id === tagId);
    const show = !!tagId;
    const [errors, setErrors] = useState({});

    const [label, setLabel] = useState("");
    const [folder, setFolder] = useState("");

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();

        if(!folder || !label){
            setErrors({
                folder: !folder,
                label: !label,
            })
            return;
        }
        setErrors({})

        setLoading(true);
        dispatch(editTag({
            id: tagId,
            project,
            label,
            folder
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Tag saved"))
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    useEffect(() => {
        if(tag){
            setLabel(tag.label)
            setFolder(tag.folder ? tag.folder.id : "")
        }else{
            setLabel("")
            setFolder("")
        }
        setLoading(false);
    }, [tag])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle>
                Edit tag
            </DialogTitle>
            <DialogContent dividers={false}>

                <Grid container spacing={2} pb={4} pt={2}>
                    <Grid item sm={12}>
                        <TextField 
                            autoFocus 
                            fullWidth 
                            value={label}
                            label="Tag label"
                            helperText="The label to identify the tag"
                            onChange={e => setLabel(e.target.value)} 
                            error={errors.label}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TagGroupSelect
                            error={errors.folder}
                            placeholder="Select tag folder"
                            label="Tag folder"
                            value={folder}
                            onChange={e => setFolder(e)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Save</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditTagDialog;