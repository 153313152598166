import { Add } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hasTier } from "../../common";
import ProjectCard from "../../components/project/ProjectCard";
import ProjectWizard from "../../components/project/ProjectWizard";
import MainContainer from "../../containers/MainContainer";

function ProjectsPage(){
    const list = useSelector(state => state.project.list);
    const user = useSelector(state => state.user.profile);
    const archived = (list || []).filter(p => p.archived);
    const active = (list || []).filter(p => !p.archived);
    const navigate = useNavigate();
    return <MainContainer>
        {list === null && <>
            <LinearProgress />
        </>}
        
        {list && list.length === 0 && <>
            <ProjectWizard />
        </>}

        {list && list.length > 0 && <>
            <Box display="flex" justifyContent={"space-between"} alignItems="center">
                <Typography sx={{mb: 4}} color="primary" variant="h2">Your projects</Typography>
                {hasTier(2, user) && <Button 
                    sx={{
                        borderRadius: 4
                    }} 
                    onClick={() => navigate("/projects/new")} 
                    size="small" 
                    variant="contained" 
                    color="blu"
                >
                    <Add />
                    New project
                </Button> }
            </Box>
            <Grid container spacing={3}>
                {active && active.map(project => {
                    return <Grid key={project.id} item sm={4} lg={3}>
                        <ProjectCard project={project} />
                    </Grid>
                })}
            </Grid>
            {archived.length > 0 && <Grid mt={4} container spacing={3}>
                <Grid sm={12} item>
                    <Typography variant="h4">Archived projects</Typography>
                </Grid>
                {archived && archived.map(project => {
                    return <Grid key={project.id} item sm={4} lg={3}>
                        <ProjectCard project={project} />
                    </Grid>
                })}
            </Grid>}
        </>}
        
        {/* <CommonFab link="/projects/new" /> */}
    </MainContainer>
}
export default ProjectsPage;