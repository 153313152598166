import { FilterAlt, MoreVert } from "@mui/icons-material";
import { Button, Chip, FormControlLabel, Grid, IconButton, Menu, MenuItem, Paper, Radio, RadioGroup, Slider, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { intervalLabel } from "../../common";
import { openDialog } from "../../features/dialog";
import postfilters from "../../config/postfilters";
import FilterBySelect from "../common/FilterBySelect";
import LanguageSelect from "../common/LanguageSelect";
import PrioritySelect from "../common/PrioritySelect";
import SentimentSelect from "../common/SentimentSelect";
import PlatformSelect from "../common/PlatformSelect";
import TagFilterOperatorSelect from "../common/TagFilterOperatorSelect";
import TagFilterSelect from "../common/TagFilterSelect";
import { useDispatch } from "react-redux";

function PostFilterInlinePanel({data, tab, onChange, project}){
    const [filters, _setFilters] = useState({});
    const [addForm, setAddForm] = useState({
    });
    const [newFilterOpen, setNewFilterOpen] = useState(false);

    const theme = useTheme();
    
    const setFilters = filters => {
        _setFilters(filters);
        onChange && onChange(filters);
    }

    useEffect(() => {
        _setFilters(data);
    },[data]);

    const handleDeleteFilter = type => {
        const newFilters = {...filters}
        delete newFilters[type]
        setFilters(newFilters);
    }

    const renderFilter = (filterType, filter, index) => {
        const postFilter = postfilters[filterType];
        if(!postFilter){
            // console.error("Non trovo " + filter.type);
            return null;
        }
        const ChipIcon = postFilter.icon;
        var chipLabel = postFilter.label(filter, project);
        if (!chipLabel){
            chipLabel = "Any";
        } 
        return <Chip
            onClick={() => {
                handleOpenFilterSelector(filterType, filter)
            }}
            sx={{m: 0.5}}
            icon={<ChipIcon />}
            key={index}
            label = {chipLabel}
            onDelete={() => handleDeleteFilter(filterType)}
        />
    }

    const handleOpenFilterSelector = (field, value) => {
        setNewFilterOpen(true);
        if(!field || typeof field !== "string"){
            field = "text_search"
        }
        setAddForm({
            field,
            value
        });
    }
    const handleApplyNewFilter = () => {
        setNewFilterOpen(false);
        if(addForm.value){
            setFilters({
                ...filters,
                [addForm.field]: addForm.value
            })
        }else{
            handleDeleteFilter(addForm.field);
        }
        
    }
    const handleCancelNewFilter = () => {
        setNewFilterOpen(false);
    }
    
    return <Box 
        position="relative"
        display="flex"
        
        alignItems="center"
        alignContent={"center"}
        justifyContent="space-between"
        sx={{
            height: 88,
            borderBottom: 1,
            borderBottomColor: theme.palette.primary.main
        }}
    >
        <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" mr={1} sx={{width: 120}}>
                {Object.keys(filters).length > 0 ? "Active filters:" : "No filter set."}
            </Typography>
            <Box display="flex" alignItems="center" flexWrap={"wrap"} flexDirection="rows">            
                {Object.keys(filters).map((filterType, index) => renderFilter(filterType, filters[filterType], index))}
            </Box>
        </Box>
        <Paper 
            elevation={3}
            sx={{
                display: newFilterOpen ? 'flex' : "none",
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'absolute',
                zIndex: 1060,
                bgcolor: 'white',
                right: -1,
                top: 86,
                width: 800,
                height: 300,
                p: 2
            }}
        >
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <FilterBySelect
                        value={addForm.field || ""}
                        onChange={e => setAddForm({...addForm, field: e, value: filters[e]})}
                    />
                </Grid>
                <Grid item sm={0.5}></Grid>
                <Grid item sm={7}>
                    {addForm.field === "text_search" && <TextField
                        fullWidth
                        size="small"
                        label="Text"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e.target.value})}
                    />}

                    {addForm.field === "sentiment" && <SentimentSelect
                        fullWidth
                        showDontChange={false}
                        showAny={true}
                        size="small"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e})}
                    />}

                    {addForm.field === "platform" && <PlatformSelect
                        fullWidth
                        size="small"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e})}
                    />}

                    {addForm.field === "priority" && <PrioritySelect
                        fullWidth
                        size="small"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e})}
                    />}

                    {addForm.field === "language" && <LanguageSelect
                        fullWidth
                        size="small"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e})}
                    />}

                    {addForm.field === "author" &&  <TextField
                        fullWidth
                        size="small"
                        label="Author"
                        value={addForm.value || ""}
                        onChange={e => setAddForm({...addForm, value: e.target.value})}
                    />}

                    {addForm.field === "date" && <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                type="date"
                                label="From"
                                value={addForm.value ? addForm.value.start || "" : ""}
                                onChange={e => setAddForm({
                                    ...addForm, 
                                    value: {...addForm.value || {}, start: e.target.value}
                                })}
                            />

                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                label="To"
                                value={addForm.value ? addForm.value.end || "" : ""}
                                onChange={e => setAddForm({
                                    ...addForm, 
                                    value: {...addForm.value || {}, end: e.target.value}
                                })}
                            />
                        </Grid>
                    </Grid>}

                    {addForm.field === "interval" && <Grid container spacing={2} mt={0.1}>
                        <Grid item sm={12} >
                            <Slider
                                valueLabelDisplay="on"
                                marks={[{
                                    value: 1,
                                    label: 'a day'
                                },{
                                    value: 360,
                                    label: 'a year'
                                }]}
                                value={addForm.value || 1}
                                min={1}
                                step={1}
                                max={360}
                                getAriaValueText={value => intervalLabel(value)}
                                valueLabelFormat={value => intervalLabel(value)}
                                onChange={e => setAddForm({...addForm, value: e.target.value})}
                                
                            />

                        </Grid>
                    </Grid>}


                    {addForm.field === "tag" && <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <RadioGroup 
                                row
                                value={(addForm.value && addForm.value.type) || "default"}
                                onChange={e => setAddForm({
                                    ...addForm,
                                    value: {
                                        ...addForm.value || {},
                                        value: e.target.value === "reverse" ? ["9999"] : [],
                                        type: e.target.value
                                    }
                                })}
                            >
                                <FormControlLabel value="default" control={<Radio size="small" />} label="Filter posts with tags" />
                                <FormControlLabel value="reverse" control={<Radio size="small" />} label="Filter posts without tags" />
                            </RadioGroup>
                        </Grid>
                        {(!addForm.value || addForm.value.type!=="reverse") && [
                            <Grid item sm={12} key={1}>
                                <TagFilterSelect
                                    fullWidth
                                    value={addForm.value ? addForm.value.value || [] : []}
                                    onChange={e => setAddForm({
                                        ...addForm, 
                                        value: {...addForm.value || {}, value: e}
                                    })}
                                />

                            </Grid>,
                            <Grid item sm={12} key={2}>
                                <TagFilterOperatorSelect
                                    fullWidth
                                    value={addForm.value ? addForm.value.operator || "" : ""}
                                    onChange={e => setAddForm({
                                        ...addForm, 
                                        value: {...addForm.value || {}, operator: e}
                                    })}
                                />
                            </Grid>
                        ]}
                    </Grid>}
                </Grid>
            </Grid>
            <Box display="flex" justifyContent={"flex-end"} gap={2}>
                <Button onClick={handleCancelNewFilter} variant="contained" color="primary">Cancel</Button>
                <Button onClick={handleApplyNewFilter} variant="contained" color="secondary">Apply filter</Button>
            </Box>
        </Paper>
        <Box display="flex" alignItems="center" justifyContent="center">
            <Button 
                sx={{
                    borderRadius: 4,
                    width: 160

                }} 
                onClick={handleOpenFilterSelector} 
                size="small" 
                variant="contained" 
                color="secondary"
            >
                <FilterAlt />
                Add filter
            </Button>
            <ToolsMenu project={project} tab={tab} />
        </Box>
    </Box>;
}


function ToolsMenu({project, tab}){
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (dialog) => {
        handleClose();
        dispatch(openDialog(dialog));
    }
    return <>
        <IconButton
            aria-controls={open ? 'add-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <MoreVert color="primary" fontSize={"medium"} />
        </IconButton>
        <Menu
            id="add-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'add-button',
            }}
        >
            <MenuItem onClick={() => handleMenuClick({id: "editDashboard", props: tab.id})}>Edit Dashboard</MenuItem>
            <MenuItem onClick={() => handleMenuClick("dataset")}>View dataset</MenuItem>
            <MenuItem onClick={() => handleMenuClick("downloadCsv")}>Download CSV</MenuItem>
        </Menu>

    </>
    
}

export default PostFilterInlinePanel;