import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import ProjectInfoForm from './ProjectInfoForm';
import JobManagement from '../job/JobManagement';
import DashboardManagement from '../dashboard/DashboardManagement';
import ReportManagement from '../report/ReportManagement';
import TagManagement from '../tag/TagManagement';
import ProjectOptionsForm from './ProjectOptionsForm';
import ToggleProjectStatusForm from './ToggleProjectStatusForm';
import AlertManagement from '../alert/AlertManagement';
const DIALOG_ID = "editProject"
const DIALOG_WIDTH = "lg"

function EditProjectDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const show = useSelector((state) => state.dialog[DIALOG_ID])
    const project = useSelector(state => state.project.single);
    const [activeSection, setActiveSection] = useState(0);

    const sections = [{
        component: <ProjectInfoForm key="projectForm" form={project} disableLabel />,
        title: 'Project info'
    },{
        component: <TagManagement sentimentView={false} key="tagManagement" />,
        title: 'Tags'
    },{
        component: <JobManagement key="jobManagement" />,
        title: 'Jobs'
    },{
        component: <AlertManagement key="alertManagement" />,
        title: 'Alert'
    },{
        component: <ReportManagement key="reportManagement" />,
        title: 'Report'
    },{
        component: <DashboardManagement key="dashboardManagement" />,
        title: 'Dashboard'
    },{
        component: <ProjectOptionsForm key="projectOptions" form={project} />,
        title: 'Project Options'
    },{
        component: <ToggleProjectStatusForm key="projectStatus" form={project} />,
        title: project.archived ? 'Activate project' : "Archive project"
    }]
    
    if(project.has_autosentiment){
        sections.splice( 2, 0, {
            component: <TagManagement sentimentView key="sentimentManagement" />,
            title: 'Sentiment'
        } );

    }
    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    useEffect(() => {
        setLoading(false);
    }, [show])

    const Component = sections[activeSection].component;
    
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        PaperProps={{
            sx: {
              height: '90%'
            }
        }}
    >
        <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            {project.name} - {sections[activeSection].title}
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        
        <DialogContent dividers={false} sx={{minHeight: 400}}>
            <Grid container spacing={4} wrap="nowrap">
                <Grid item minWidth={230}>
                    <Tabs
                        orientation="vertical"
                        value={activeSection}
                        onChange={(e, v) => setActiveSection(v)}
                        sx={{ 
                            borderRight: 1, 
                            borderColor: 'divider',
                            pr: 2,
                            '& .MuiTabs-indicator': {
                                left: 0
                            }
                        }}
                    >
                        {sections.map((s, i) => <Tab sx={{alignItems: 'flex-start'}} label={s.title} key={i} />)}
                    </Tabs>
                </Grid>
                <Grid item flexGrow={1} pr={2}>
                    <Box gap={4} display="flex" flexDirection={"column"}>
                        {Component}
                    </Box>
                </Grid>
            </Grid>
            {loading && <Box><LinearProgress /></Box>}
        </DialogContent>
    </Dialog>
}

export default EditProjectDialog;