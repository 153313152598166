import { Autorenew, Favorite, Message, Reddit } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { parseURL,prettyTruncate } from "../../../common";
import PostDateElement from "../element/PostDateElement";
// import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function RedditPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    // if(post.parent){

    // } else {
        
    // }
    const title = post.is_rt ? "Comment: " + post.title : "Post: " + post.title;

    function extractSubreddit(url) {
        // Regular expression to match the subreddit part of the URL
        const regex = /reddit\.com\/r\/([^\/]+)/;
        
        // Execute the regular expression on the URL
        const match = url.match(regex);
        
        // Check if there was a match and return the subreddit name
        if (match && match[1]) {
            return `r/${match[1]}`;
        } else {
            return null; // Return null if no match found
        }
    }

    return <>
        <Box p={padding}>
            <Typography sx={{fontSize:"small", fontWeight:"bold", color:"#999999"}}>{extractSubreddit(post.full_uri)}</Typography>
            <Divider />
            <PostTitleElement title={title} post={post} />
            <Typography sx={{fontSize:"small", fontWeight:"bold", color:"#999999"}}>{'u/' + post.user_screenname}</Typography>
            <PostTextElement text={prettyTruncate(text,200,'...')} post={post} />
            <PostDateElement post={post} />
        </Box>

        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">

            <Box display="flex" alignItems={"center"} mr={1}>
                <Favorite sx={{color: '#CC0000', marginRight: 0.5}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_likes || 0}</span>

                <Message sx={{color: '#999999', marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_comments || 0}</span>

                {/* <Autorenew sx={{marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_shares || 0}</span> */}
            </Box>

            <Reddit sx={{color: '#ff4500'}}/>

        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default RedditPostCard;