import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProject } from "../../features/project";
import WhitelistAlertManagement from "./whitelist/WhitelistAlertManagement";
import SourceAlertManagement from "./source/SourceAlertManagement";
import SubscriberAlertManagement from "./subscriber/SubscriberAlertManagement";

function AlertManagement(){
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const project = useSelector(state => state.project.single);
    const activateAlerting = () => {

        dispatch(editProject({
            ...project,
            has_alerting: 1
        }));
    }

    if(!project || !project.has_alerting){
        return <Box mt={12} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography variant="h5">Project alerting is not active.</Typography>
            <Typography variant="subtitle1">Activate it to enable alerting features.</Typography>

            <Button sx={{mt: 3}} variant="contained" color="success" onClick={activateAlerting}>Activate alerting</Button>
        </Box>
    }

    return <>
        <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
            <Tab label="Whitelist alerts" />
            <Tab label="Sources" />
            <Tab label="Subscribers" />
        </Tabs>
        {activeTab === 0 && <WhitelistAlertManagement />}
        {activeTab === 1 && <SourceAlertManagement />}
        {activeTab === 2 && <SubscriberAlertManagement />}
    </>
}
export default AlertManagement;