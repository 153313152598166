import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { addChannel } from '../../features/netfeedr';
import ChannelForm from './ChannelForm';
import { hasErrors } from '../../common';

const DIALOG_ID = "newChannel"
const DIALOG_WIDTH = "md"

function NewChannelDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const show = !!useSelector((state) => state.dialog[DIALOG_ID])
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();

        setLoading(true);
        dispatch(addChannel(form))
        .then(r => {
            if(r.payload){
                if(r.payload.error){
                    dispatch(enqueueErrorSnack(r.payload.error));
                }else{
                    dispatch(closeDialog(DIALOG_ID));
                    dispatch(enqueueSuccessSnack("Channel saved"))    
                }
            }
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm({
            tier: 4,
            active: true
        });
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Create channel
            </DialogTitle>
            <DialogContent dividers={false}>

                <Box pr={3}>
                    <ChannelForm form={form} onChange={setForm} errors={errors} />
                </Box> 
                
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Create Channel</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default NewChannelDialog;