import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../../features/snackbar';
import { editJob } from '../../../features/job';
import JobForm from '../JobForm';
import { validateProjectJob } from '../../../validator/project';
import { hasErrors } from '../../../common';

const DIALOG_ID = "editJob"
const DIALOG_WIDTH = "md"

function EditJobDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const jobs = useSelector(state => {
        return [...state.job.all || [], ...state.job.list || []];
    });
    const jobId = useSelector((state) => state.dialog[DIALOG_ID])
    const show = !!jobId;
    const job = jobId ? jobs.find(j => j.id === jobId) : null;
    const [errors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateProjectJob(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(editJob(form))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Job saved"))    
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm(job);
    }, [job, jobId]);

    // useEffect(() => {
    //     if(!jobs){
    //         if(project){
    //             dispatch(allJobs());
    //         }else{

    //         }
    //     }
    // }, [])
    
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Edit job
            </DialogTitle>
            <DialogContent dividers={false}>
                <Box pr={3}>
                    <JobForm form={form || job} onChange={setForm} errors={errors} />
                </Box>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Save Job</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditJobDialog;