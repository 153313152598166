import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadQueryData } from "../../features/project";
import ProjectDashboardTab from "./tab/ProjectDashboardTab";
import ProjectFeedTab from "./tab/ProjectFeedTab";

function ProjectTab(props){
    const {tab,project} = props;
    const currentView = useSelector(state => state.dashboard.tabViews[tab.id]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadQueryData({project, tab}));
    },[tab,project])

    if(!tab)
        return null;
    switch(currentView){
        case "charts": return <ProjectDashboardTab {...props} />
        case "posts": 
        default: return <ProjectFeedTab {...props} />
        // default: return <Alert severity="error">Invalid view: {tab.type}</Alert>
    }
}

export default ProjectTab;