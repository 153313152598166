import { Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import DashboardBuilder from "./dashboard/DashboardBuilder";
import DashboardFilters from "./dashboard/DashboardFilters";
import DashboardInfo from "./dashboard/DashboardInfo";

const steps = [{
    label: 'Define a time interval'
},
{
    label: 'Create a filter'
},
{
    label: 'Select data visualizations'
},

]

function ProjectDashboardsStep({onChange, form,handleSkip, handleNext}){

    const [activeStep, setActiveStep] = useState (0)

    const _handleNext = () => {
        if(activeStep === steps.length-1){
            // Parent wizard
            handleNext();
        }else{
            setActiveStep(activeStep+1);
        }
    }
    const stepProps = {
        handleNext: _handleNext,
        form,
        handleSkip,
        onChange
    };
    return <>
        <Stepper alternativeLabel activeStep={activeStep} sx={{
            
            '& .MuiStepLabel-root .Mui-active': {
                color: 'secondary.dark', // circle color (ACTIVE)
            },
        }}>
            {steps.map((step, index) => {

                return <Step key={index}>
                    <StepLabel>
                        {step.label}
                    </StepLabel>

                </Step>
            })}
        </Stepper>
        <>
            {activeStep === 0 && <DashboardInfo {...stepProps} /> }
            
            {activeStep === 1 && <DashboardFilters  {...{
                ...stepProps, 
                onChange: dashboard => onChange({...form, dashboard}),
                form: form.dashboard
            }}  /> }

            {activeStep === 2 && <DashboardBuilder {...{
                ...stepProps, 
                onChange: dashboard => onChange({...form, dashboard}),
                form: form.dashboard
            }} /> }
        </>
    </>
}
export default ProjectDashboardsStep;