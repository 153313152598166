import { Box, Typography } from "@mui/material";
import { useDrag } from "react-dnd";

function WidgetBoxField({ config, elements, isDragging, showPreview }) {
    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: "widget",
            item: config,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }),
        [config, elements]
    )

    return <Box ref={dragRef} style={{ 
        opacity,
        borderColor: '#CCC',
        borderWidth: 1,
        padding: 4, 
        paddingLeft: 8,
        borderStyle: 'solid',
        marginBottom: 4,
        cursor: 'move'
     }} >
        {showPreview && <img alt={config.title} style={{ maxWidth: '100%' }} src={"/assets/img/report/settings/" + config.id + ".png"} />}
        <Typography variant="caption">{config.title}</Typography>
    </Box>
}
export default WidgetBoxField;