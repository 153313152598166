import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, openDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { addReport } from '../../features/report';
import { validateReport } from '../../validator/report';
import { hasErrors } from '../../common';
import ReportForm from './ReportForm';

const DIALOG_ID = "newReport"
const DIALOG_WIDTH = "sm"

function NewReportDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const show = !!useSelector((state) => state.dialog[DIALOG_ID])
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = validateReport(form);
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(addReport({
            project,
            report: form
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Report created"))    
            dispatch(openDialog({id: "editReport", props: r.payload.id}));
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm({});
    }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Create report
            </DialogTitle>
            <DialogContent dividers={false}>

                <Box pr={3}>
                    <ReportForm form={form} onChange={setForm} errors={errors} />
                </Box> 
                
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">Create Report</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default NewReportDialog;