import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageLabel } from '../../config/languages';
import { getPlatformLabel } from '../../config/platforms';
import { getSentimentLabel } from '../../config/sentiments';
import { closeDialog } from '../../features/dialog';

const DIALOG_ID = "dataset"
const DIALOG_WIDTH = "lg"

function DatasetDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const show = useSelector((state) => state.dialog[DIALOG_ID])
    const project = useSelector(state => state.project.single);
    const queryData = useSelector(state => state.project.queryData);
    
    
    
    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    useEffect(() => {
        setLoading(false);
    }, [show])

    if(!queryData){
        return null;
    }
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        PaperProps={{
            sx: {
              height: '90%'
            }
        }}
    >
        <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            {project.name} - Dataset
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        
        <DialogContent dividers={false} sx={{minHeight: 400}}>
            <Grid container>
                <Grid item sm={3}>
                    <h3>Platform</h3>
                    {queryData.platform.map(e => {
                        return <Typography key={e.name} variant="subtitle1">{getPlatformLabel(e.name)}: {e.value}</Typography>
                    })}
                </Grid>
                <Grid item sm={3}>
                    <h3>Sentiment</h3>
                    {queryData.sentiment.map(e => {
                        return <Typography key={e.name} variant="subtitle1">{getSentimentLabel(e.name)}: {e.value}</Typography>
                    })}
                </Grid>
                <Grid item sm={3}>
                    <h3>Language</h3>
                    {queryData.lang.map(e => {
                        return <Typography key={e.name} variant="subtitle1">{getLanguageLabel(e.name)}: {e.value}</Typography>
                    })}
                </Grid>
                <Grid item sm={3}>
                    <h3>Engagement</h3>
                    <Typography variant="subtitle1"> Total engagement: {queryData.engagement}</Typography>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

export default DatasetDialog;