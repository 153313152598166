import { Reply, Comment, LinkedIn, ThumbUp } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import { parseURL, prettyTruncate } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function LinkedinPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    const title = post.title || post.user_screenname;
    return <>
        <Box p={padding}>
            <PostTitleElement title={title} post={post} />
        </Box>

        <PostImageElement post={post}/>
        
        <Box p={padding}>
        <PostTextElement text={prettyTruncate(text,300)} post={post} />

            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">

            { <Box display="flex" alignItems={"center"} mr={1}>
                <ThumbUp sx={{fontSize: 14, color: '#0e76a8', marginRight: 0.5}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_likes}</span>
                
                <Comment sx={{fontSize: 14, color: '#0e76a8', marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_comments}</span>

                <Reply sx={{ fontSize: 14, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_shares}</span>
            </Box> }

            <LinkedIn sx={{color: '#0e76a8'}}/>

        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default LinkedinPostCard;