import React, { useEffect, useRef } from 'react';
import './App.css';
import AppRouter from './AppRouter';
import { ThemeProvider } from '@mui/material/styles';
import themeBase from './theme';
import useNotifier from './useNotifier';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme } from '@mui/material/styles';
import { logout } from './features/user';
import { loadApplications, loadHelpStrings } from './features/app';

// 15 minutes
const autoLogoutTime = 15 * 60 * 1000;

function App() {
    
    useNotifier();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);

    // if(user){
    //     console.log(user);
    // } else {
    //     console.log("NO USER");
    // }

    const logoutTimerIdRef = useRef(null);
    
    const logoutUser = () => {
        dispatch(logout());
    }
    
    useEffect(() => {
        dispatch(loadHelpStrings());
        dispatch(loadApplications());

        const autoLogout = () => {
            if(user && parseInt(user.tier) > 1){ // Only for non-superadmin
                if (document.visibilityState === 'hidden') {
                    const timeOutId = window.setTimeout(logoutUser, autoLogoutTime);
                    logoutTimerIdRef.current = timeOutId;
                } else {
                    window.clearTimeout(logoutTimerIdRef.current);
                }
            }
        };

        document.addEventListener('visibilitychange', autoLogout);

        return () => {
            document.removeEventListener('visibilitychange', autoLogout);
        };
    }, []);

    const darkMode = false; //useSelector(state => state.app.ui.darkMode);
    const mode = darkMode ? "dark" : "light";
    const theme = React.useMemo(
        () => {
            return createTheme({
                ...themeBase,
                palette: {
                    ...themeBase.palette,
                    mode
                }
            })
        },
        [mode]
    );
    
    return (
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    );
}

export default App;
