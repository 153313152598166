import { StackedLineChart, ViewComfy } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setTabView } from "../../features/dashboard";

function ProjectTabLabel({ tab, id, index, activeTab, innerRef, isDragging, draggableStyle, ...props }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentView = useSelector(state => state.dashboard.tabViews[id]);
    const [hover, setHover] = useState(false);
    const active = id === parseInt(activeTab);
    let Icon = StackedLineChart;
    const color = active ? theme.palette.primary.main : theme.palette.primary.contrastText;

    const textColor = active ? "primary" : "primary.contrastText";
    if (currentView !== "charts") {
        Icon = ViewComfy;
    }

    const style = {
        cursor: 'pointer',
        bgcolor: active ? "secondary.main" : "primary.main",
        mr: 0.5,
        p: 1,
        minWidth: 120
    }
    if (active) {
        style.borderBottom = 1;
        style.borderColor = theme.palette.secondary.dark
    }
    if(isDragging){
        
    }
    const myProps = {
        ...props,
        style: {
            ...props.style,
            cursor: 'pointer'
        }
    }
    return <Box 
        {...myProps} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)} 
        ref={innerRef} 
        sx={style} 
        key={index} 
        alignItems="center" 
        display="flex" 
        flexDirection="column"
    >
        <Tooltip title={"Switch to " + (currentView !== "charts" ? "charts" : "posts")}>
            <IconButton onClick={() => {
                dispatch(setTabView({
                    tab: id,
                    view: currentView !== "charts" ? "charts" : "posts"
                }))
            }}><Icon htmlColor={color} fontSize={"medium"} /></IconButton>
        </Tooltip>
        <Typography mt={1} textTransform={"uppercase"} variant="subtitle2" color={textColor}>{tab.label}</Typography>
        {/* <Box gap={2} mt={1} height={20} display="flex" alignItems="space-evenly">
            {active && <>
                <Button onClick={() => onChangeView("posts")} variant={view!=="charts" ? "contained" : "outlined"}  size="small">Posts</Button>
                <Button onClick={() => onChangeView("charts")} variant={view==="charts" ? "contained" : "outlined"} size="small">Charts</Button>
            </> }
        </Box> */}
    </Box>

}
export default ProjectTabLabel;