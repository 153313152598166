import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { editGroup, resetGroupPassword } from '../../features/group';
import GroupForm from './GroupForm';
import { hasErrors } from '../../common';

const DIALOG_ID = "editGroup"
const DIALOG_WIDTH = "md"

function EditGroupDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const groups = useSelector(state => state.group.list);
    const groupId = useSelector((state) => state.dialog[DIALOG_ID])

    const show = !!groupId;
    const group = groupId ? groups.find(j => j.id === groupId) : null;
    const [errors] = useState({});
    const [form, setForm] = useState({});

    const save = e => {
        e && e.preventDefault() && e.stopPropagation();
        const errors = {}
        if(hasErrors(errors)){
            return;
        }

        setLoading(true);
        dispatch(editGroup(form))
        .then(r => {
            if(r && r.payload){
                if(r.payload.error){
                    dispatch(enqueueErrorSnack(r.payload.error));
                }else{
                    dispatch(closeDialog(DIALOG_ID));
                    dispatch(enqueueSuccessSnack("Group saved"));
                }
            }
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    

    useEffect(() => {
        setForm(group);
    }, [group, groupId]);

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={save}>
            <DialogTitle>
                Edit group
            </DialogTitle>
            <DialogContent dividers={false}>
                <Box pr={3}>
                    <GroupForm form={form || group || {}} onChange={setForm} errors={errors} />
                </Box>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Stack gap={1} direction="row">
                        <Button variant="contained" color="secondary" type="submit">Save Group</Button>
                    </Stack>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditGroupDialog;