import { useEffect, useState } from "react";
import { enqueueErrorSnack } from "./features/snackbar";

const humanizeDuration = require("humanize-duration");
const thirtyDays = 30 * 24 * 60 * 60 * 1000
const oneDay = 86400 * 1000;
const myHumanizer = humanizeDuration.humanizer({
    unitMeasures: {
        y: oneDay * 365,
        mo: thirtyDays,
        w: oneDay * 7,
        d: oneDay,
        h: oneDay / 24,
        m: 60000,
        s: 1000,
        ms: 1
    }
})

export function prettyTruncate(str, length=100, ending="...") {
    return str.length > length ? str.substring(0, length - ending.length) + ending : str;
}

export function intervalLabel(value) {
    return myHumanizer((value || 1) * 86400000, { units: ['y', 'mo', 'd'] })
}

export function hasTier(tier, user){
    return user && user.tier && parseInt(user.tier) <= tier;
}

export function formToJson(formEl) {
    const data = new FormData(formEl);
    const object = {};
    data.forEach(function (value, key) {
        object[key] = value;
    });
    return object;
}
export function commonApiPromise(promise, store){
    return promise
    .then(response => {
        const data = response.data;
        if(data.error){
            store && store.dispatch(enqueueErrorSnack(data.error));
            return false;
        }
        return data;
    })
    .catch((e) => {
        if(e.response && e.response.status===403){
            return false;
        }
        store && store.dispatch(enqueueErrorSnack(e.message))
        return false;
    })
}
export function validateEmail(email){
    return String(email)
    .toLowerCase()
    .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
  
export function updateArray(array, element, idProp = "id") {
    try{
        return (array || []).map(el => {
            if (el[idProp] === element[idProp]) {
//console.info("Sostituisco ", JSON.parse(JSON.stringify(el)), "con", element);
                return element;
            }
            return el;
        });
    }catch(E){
        console.error(E);
        return array;
    }
}

export function hasErrors(errors) {
    return hasProperties(errors);
}
export function hasProperties(obj) {
    return Object.keys(obj).filter(e => obj[e]).length > 0;
}
export function parseURL(string) {
    if(string){
        return string.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g, function (url) {
            return "<a target='_blank' href='" + url + "'>" + url + "</a>";
        });
    } else {
        return "";
    }
};
// Auto-link Twitter usernames in a string
// Usage: mystring.parseTwitterUsername()
export function parseTwitterUsername(string) {
    return string.replace(/[@]+[A-Za-z0-9-_]+/g, function (u) {
        var username = u.replace("@", "");
        return "<a target='_blank' href='http://twitter.com/" + username + "'>" + u + "</a>";
        //        return u.link('' + username);
    });
};
// Auto-link Twitter hashtags in a string
// Usage: mystring.parseTwitterHashtag()
export function parseTwitterHashtag(string) {
    return string.replace(/[#]+[A-Za-z0-9-_]+/g, function (t) {
        var tag = t.replace("#", "%23");
        return "<a target='_blank' href='http://twitter.com/search?src=typd&q=" + tag + "'>" + t + "</a>";
    });
};


// Auto-link Instagram usernames in a string
// Usage: mystring.parseIntagramUsername()
export function parseInstagramUsername(string) {
    return string.replace(/[@]+[A-Za-z0-9-_]+/g, function (u) {
        var username = u.replace("@", "");
        return "<a target='_blank' href='http://instagram.com/" + username + "'>" + u + "</a>";
        //        return u.link('' + username);
    });
};
// Auto-link Instagram hashtags in a string
// Usage: mystring.parseInstagramHashtag()
export function parseInstagramHashtag(string) {
    return string.replace(/[#]+[A-Za-z0-9-_]+/g, function (t) {
        var tag = t.replace("#", "");
        return "<a target='_blank' href='http://instagram.com/explore/tags/" + tag + "'>" + t + "</a>";
    });
};


// Auto-link TikTok usernames in a string
// Usage: mystring.parseTikTokUsername()
export function parseTikTokUsername(string) {
    return string.replace(/[@]+[A-Za-z0-9-_]+/g, function (u) {
        var username = u.replace("@", "");
        return "<a target='_blank' href='https://www.tiktok.com/@" + username + "'>" + u + "</a>";
        //        return u.link('' + username);
    });
};
// Auto-link TikTok hashtags in a string
// Usage: mystring.parseTikTokHashtag()
export function parseTikTokHashtag(string) {
    return string.replace(/[#]+[A-Za-z0-9-_]+/g, function (t) {
        var tag = t.replace("#", "");
        return "<a target='_blank' href='https://www.tiktok.com/tag/" + tag + "'>" + t + "</a>";
    });
};

export function formatNumber(number) {
    if(!number){
        return '0';
    }
    // Less than 1,000
    if (number < 1000) {
      return number.toString();
    }
    // Thousands
    else if (number >= 1000 && number < 1000000) {
      if (number < 10000) {
        // Less than 10,000 (thousands with two decimals)
        return (number / 1000).toFixed(2) + 'k';
      } else if (number < 100000) {
        // Less than 100,000 (tens of thousands with one decimal)
        return (number / 1000).toFixed(1) + 'k';
      } else {
        // Hundreds of thousands (no decimals)
        return Math.round(number / 1000) + 'k';
      }
    }
    // Millions
    else if (number >= 1000000 && number < 1000000000) {
      if (number < 10000000) {
        // Less than 10 million (millions with two decimals)
        return (number / 1000000).toFixed(2) + 'M';
      } else if (number < 100000000) {
        // Less than 100 million (tens of millions with one decimal)
        return (number / 1000000).toFixed(1) + 'M';
      } else {
        // Hundreds of millions (no decimals)
        return Math.round(number / 1000000) + 'M';
      }
    }
    // For numbers >= 1 billion, you can extend this function similarly.
    else {
      return number.toString();
    }
  }

export const useContainerDimensions = myRef => {
    const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    const init = () => {

        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }
    useEffect(() => {
        init();
    }, [myRef])


    return dimensions;
};
