import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../../../features/snackbar';
import { deleteSource } from '../../../../features/source';

const DIALOG_ID = "deleteSource"
const DIALOG_WIDTH = "sm"

function DeleteSourceDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const sourceId = useSelector((state) => state.dialog[DIALOG_ID])
    const sources = useSelector(state => state.source.list || state.source.all);
    const source = sources && sources.find(el => el.id === sourceId);

    const sourceLabel =  Array.isArray(sourceId) ? "selected" : (source ? source.name : "");
    const show = !!sourceId;

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(deleteSource({
            project,
            id: sourceId
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Source deleted"))
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle>
                Delete source
            </DialogTitle>
            <DialogContent dividers={false}>
                <Typography variant="subtitle1">Are you sure you want to delete the <b>{sourceLabel}</b> source?</Typography>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="error" type="submit">Delete</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default DeleteSourceDialog;