import HC_exporting from 'highcharts/modules/exporting'
import HC_data from 'highcharts/modules/export-data'
import HC_wc from 'highcharts/modules/wordcloud'
import HC_tm from 'highcharts/modules/treemap'
import HC_ng from 'highcharts/modules/networkgraph'
import HC_sg from 'highcharts/modules/streamgraph'
import HC_sankey from 'highcharts/modules/sankey'
import HC_sl from 'highcharts/modules/series-label'
import HC_more from 'highcharts/highcharts-more';
import Highcharts from 'highcharts'
import HighchartsColorAxis from "highcharts/modules/coloraxis"; 

// init the module
HC_tm(Highcharts)
HC_more(Highcharts);
HC_exporting(Highcharts)
HC_data(Highcharts)
HC_wc(Highcharts)
HC_sankey(Highcharts)
HC_ng(Highcharts)
HC_sl(Highcharts)
HC_sg(Highcharts)
HighchartsColorAxis(Highcharts);

Highcharts.setOptions({
    colors:['#3f5ca1','#3c74b3','#418cc1','#51a3cd','#69b9d7','#85cfe1','#a5e5ec','#81dad8','#62cebe','#4bc19f','#41b37c','#44a455','#4d9429'],
    credits: {
        text: 'buzztech.it',
        href: 'https://buzztech.it',
        style: {
            color: '#909090',
            fontSize: '10px',
            fontWeight: 'bold'
        }
    },
    exporting: {
        // sourceWidth: 1200,
        // sourceHeight: 600,
        scale: 1.5,
        chartOptions:{
            title:{text:''},
            subTitle:{text:''}
        }
    }
});
Highcharts.addEvent(Highcharts.Axis, 'afterInit', function () {
    const logarithmic = this.logarithmic;

    if (logarithmic && this.options.allowNegativeLog) {

        // Avoid errors on negative numbers on a log axis
        this.positiveValuesOnly = false;

        // Override the converter functions
        logarithmic.log2lin = num => {
            const isNegative = num < 0;

            let adjustedNum = Math.abs(num);

            if (adjustedNum < 10) {
                adjustedNum += (10 - adjustedNum) / 10;
            }

            const result = Math.log(adjustedNum) / Math.LN10;
            return isNegative ? -result : result;
        };

        logarithmic.lin2log = num => {
            const isNegative = num < 0;

            let result = Math.pow(10, Math.abs(num));
            if (result < 10) {
                result = (10 * (result - 1)) / (10 - 1);
            }
            return isNegative ? -result : result;
        };
    }
});


Highcharts.wrap(Highcharts.Chart.prototype, 'contextMenu', function(proceed) {
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  
    // Correct for chart position
    var pos = Highcharts.offset(this.container);
    var defaultPadding = -36; // default from menuStyle option: https://api.highcharts.com/highcharts/navigation.menuStyle
    this.exportContextMenu.style.top = (parseInt(this.exportContextMenu.style.top) + pos.top) + 'px';
    this.exportContextMenu.style.left = (pos.left + this.chartWidth - this.exportMenuWidth - parseInt(this.exportContextMenu.style.padding) - defaultPadding) + 'px';
    this.exportContextMenu.style.width = this.exportMenuWidth + 'px';
  
    // Move it to the body
    Highcharts.doc.body.appendChild(this.exportContextMenu);
  });
  