import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { hasErrors } from '../common';
import { validateProjectWizard } from '../validator/project';

export const slice = createSlice({
    name: 'projectWizard',
    initialState: {
        loading: false,
        errors: {

        },
        step: 0,
        form: {

        }
    },
    reducers: {
        skipStep: (state) => {
            state.step = state.step+1;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
    },
    extraReducers(builder) {


      }
    
})

export const nextStep = createAsyncThunk('projectWizard/nextStep', async (project, store) => {
    const state = store.getState();
    const apiKey = state.user.apiKey;
    const step = state.projectWizard.step;
    const errors = validateProjectWizard(project, step);
    let response = Promise.resolve();
    if(step === 0 && !errors.label){
        store.dispatch(slice.actions.setLoading(true));
        response = axios.post("/project/checkLabel/" + apiKey, {
            label: project.label,
            id: project.id
        }).then(r => {
            store.dispatch(slice.actions.setLoading(false));
            if(r.data && r.data.exists){
                errors.label = "Project label already exists";
            }
        })
    }
    return response.then(() => {
        store.dispatch(slice.actions.setErrors(errors));
        // debugger;
        if(!hasErrors(errors)){
            // Non ci sono errori
            store.dispatch(slice.actions.setStep(state.projectWizard.step+1));
        }
    })
})

export const { setErrors, setLoading, setStep, skipStep } = slice.actions

export default slice.reducer
