import { Box, Button, FormControlLabel, FormGroup, LinearProgress, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editProject } from "../../features/project";
import { enqueueSuccessSnack } from "../../features/snackbar";

function ProjectOptionsForm({form, onChange, errors={}}){
    const [loading, setLoading] = useState(false);
    const [internalForm, setInternalForm] = useState(form);
    const dispatch = useDispatch();

    const setForm = f => onChange ? onChange(f) : setInternalForm(f);

    useEffect(() => {
        setInternalForm(form);
    },[form])

    const handleSubmit = e => {
        setLoading(true);
        e.preventDefault();
        dispatch(editProject(internalForm)).then(r => {
            dispatch(enqueueSuccessSnack("Project saved"));
            setLoading(false)
        });
    }

    const flags = {
        has_autosentiment: 'Enable auto-sentiment',
        has_aisentiment: 'Enable AI auto-sentiment',
        has_engagement_updates: 'Enable engagement updates',
        has_image_cache: 'Enable image caching',
        has_alerting: 'Enable alerting'
    };
    const handleChange = (e, flag) => {
        const checked = e.target.checked;
        setForm({
            ...internalForm,
            [flag]: checked
        })
    }

    return <>
        <FormGroup>
            {Object.keys(flags).map(flag => {
                return <FormControlLabel key={flag} control={<Switch checked={!!internalForm[flag]} onChange={e => handleChange(e, flag)} />} label={flags[flag]} />
            })}
        </FormGroup>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4}}>
            {!loading && <Button variant="contained" color="secondary" type="submit" onClick={handleSubmit}>Save Project</Button>}
            {loading && <LinearProgress />}
        </Box>
    </>
}
export default ProjectOptionsForm;