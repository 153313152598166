import { Delete, DragHandle, Settings } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import WidgetSettingsDialog from "./WidgetSettingsDialog";

function WidgetElement({config, settings, onUpdateSettings, onDelete, showPreview, move, id, index}){
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: "element",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            move(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: "element",
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    
    const [openDialog, setOpenDialog] = useState(false);
    const style = {
        border: '1px dotted #CCC',
        p: 2,
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    }
    const buttonStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        mt: 1
    }
    const handleOpenSettings = () => {
        setOpenDialog(true);
    }

    const onSaveSettings = data => {
        setOpenDialog(false);
        onUpdateSettings(data);
    }
    
    const hasConfig = JSON.stringify(config.config) !== JSON.stringify({});
    return <Box style={{ opacity }} mb={2} ref={ref} data-handler-id={handlerId}>
        <Box sx={style}>
            <WidgetSettingsDialog 
                onClose={() => setOpenDialog(false)} 
                open={openDialog} 
                config={config.config} 
                settings={settings} 
                onSave={onSaveSettings} 
            />
            <Box sx={buttonStyle}>
                {hasConfig && <Tooltip title="Configure widget settings"><IconButton onClick={handleOpenSettings} size="small">
                    <Settings/>
                </IconButton></Tooltip>}

                <Tooltip title="Remove widget">
                    <IconButton onClick={onDelete} size="small">
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
            <Tooltip title="Drag to reorder">
                <DragHandle sx={{
                    cursor: 'move',
                    mr: 2
                }}/>
            </Tooltip>
            <Typography variant="subtitle2">{config.title}</Typography>
            {showPreview && <img alt={config.title} style={{ maxWidth: '100%' }} src={"/assets/img/report/settings/" + config.id + ".png"} />}
        </Box>
    </Box>
}

export default WidgetElement;