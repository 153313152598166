import { Settings } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BooleanSelect from "../../components/common/BooleanSelect";
import TimezoneSelect from "../../components/common/TimezoneSelect";
import UserForm from "../../components/user/UserForm";
import { enqueueSuccessSnack } from "../../features/snackbar";
import { editUserSettings } from "../../features/user";

function UserSettings(){
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);
    const [form, setForm] = useState(user);
    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(editUserSettings(form))
        .then(r => {
            if(r && r.payload){
                dispatch(enqueueSuccessSnack("Settings updated"));
            }
        })
    }

    useEffect(() => {
        setForm(user.settings || {});
    }, [user])

    return <>
        <Container component="main" maxWidth="sm">
            <Typography mb={4} variant="subtitle2">Edit your settings:</Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                    <TimezoneSelect
                        value={form.timezone || ""}
                        onChange={t => setForm({
                            ...form,
                            timezone: t
                        })}
                        label="Timezone"

                    />

                    <BooleanSelect
                        value={form.hideHelp || 0}
                        onChange={t => setForm({
                            ...form,
                            hideHelp: t
                        })}
                        label="Hide inline help"
                    />
                </Stack>

                <Box mt={4} display="flex" justifyContent="center">
                    <Button type="submit" variant="contained" color="secondary">Save</Button>
                </Box>
            </form>
        </Container>
    </>
}
export default UserSettings;