import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise, updateArray } from '../common';

export const slice = createSlice({
    name: 'subscriber',
    initialState: {
        bulkToggleCount: 0,
        list: [],
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        
        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listSubscribers.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.list = payload;
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteSubscriber.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
            }
        })
        
        builder.addCase(editSubscriber.fulfilled, (state, action) => {
            const {payload} = action;
            if(payload.id)
                state.list = updateArray(state.list, payload);
        })
        
        builder.addCase(addSubscriber.fulfilled, (state, action) => {
            const {payload} = action
            if(payload.id){
                state.list = [
                    ...state.list,
                    payload
                ];
            }
        })
      }
    
})


export const deleteSubscriber = createAsyncThunk('subscriber/delete', async (subscriber, store) => {
    const {id, project} = subscriber;
    return commonApiPromise(axios.post("/alert/subscriber_delete/" + project.id, {subscriber_id: id}), store)
});


export const editSubscriber = createAsyncThunk('subscriber/edit', async (data, store) => {
    const {subscriber, project} = data;
    return commonApiPromise(axios.post("/alert/subscriber_edit/" + project.id, {
        subscription: subscriber.subscription,
        id: subscriber.id,
        user: subscriber.user.userid || subscriber.user
    }), store)
});


export const addSubscriber = createAsyncThunk('subscriber/add', async (data, store) => {
    const {subscriber, project} = data;
    return commonApiPromise(axios.post("/alert/subscriber_add/" + project.id, subscriber), store)
});


export const listSubscribers = createAsyncThunk('subscribers/list', async (projectId, store) => {
    return commonApiPromise(axios.get("/alert/subscriber_list/" + projectId), store)
})

export const { setError, setLoading, setSubscribers } = slice.actions

export default slice.reducer
