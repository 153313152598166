// import { Box, Grid, LinearProgress, Pagination, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState, react } from "react";
import { useDispatch, useSelector } from "react-redux";
// import MainContainer from "../../containers/MainContainer";
// import AuthenticatedContainer from "../../containers/AuthenticatedContainer";
// import store from "../store";
// console.log(store.getState())
import { useParams } from "react-router-dom";
// import NavBar from "../../components/navbar/NavBar";
import MainContainer from "../../containers/MainContainer";
import { loadApplications, loadHelpStrings } from '../../features/app';

export default function ExtAppPage(){
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadApplications());
    }, [dispatch]);

    var apps = useSelector(state => state.app.applications);
    if(apps.length === 0){
         apps = JSON.parse(localStorage.getItem("apps"));
    }
    // console.log("state",useSelector(state=>state));
    // console.log("apps:|:|:",apps)
    let {appId} = useParams();
    appId = parseInt(appId);
    // console.log("appid",appId);
    // const myApp = apps[appId];
    const myApp = apps.find((app) => app.app_id === appId);
    // console.log("myApp:|:|:",myApp)
    
    return (
        <MainContainer showMenu={false}>
     <div style={{ height:"calc(90vh)" }}>
     <iframe style={{border:"0"}} width="100%" height="100%" title="app" src={myApp.url + "?" + localStorage.getItem("apiKey")}></iframe>
     </div>
        </MainContainer>
    )
}
