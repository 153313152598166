import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { commonApiPromise, updateArray } from '../common';

export const slice = createSlice({
    name: 'netfeedrs',
    initialState: {
        bulkToggleCount: 0,
        list: null,
        filters: {

        },
        loading: false,
        error: false,

    },
    reducers: {
        
        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listChannels.fulfilled, (state, action) => {
            const {payload} = action;
            try{
                state.list = payload;
            }catch(E){
                console.log(E)
            }
        })


        builder.addCase(deleteChannel.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = (state.list || []).filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = (state.list || []).filter(t => t.id !== meta.arg.id);
            }
        })
        
        builder.addCase(addChannel.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
        })
      }
    
})


export const deleteChannel = createAsyncThunk('channel/delete', async (id, store) => {
    return axios.post("/netfeedr/channel_delete", {id}).then(r => r.data);
});


export const addChannel = createAsyncThunk('channel/add', async (netfeedrs, store) => {
    return commonApiPromise(axios.post("/netfeedr/channel_add", netfeedrs), store)
});


export const listChannels = createAsyncThunk('channel/list', async (nil, store) => {
    return commonApiPromise(axios.get("/netfeedr/channel_list"), store);
})

export const { setError, setLoading } = slice.actions

export default slice.reducer
