import {  FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import languages from "../../config/languages";
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(languages).map(key => {
    return {
        value: key,
        text: languages[key]
    }
});

function LanguageSelect({
    value, 
    onChange,
    size,
    label="Language", 
    helpKey,
    error=false,
    disabled=false, 
    required=false, 
    displayEmpty=false,
    placeholder="",
    hideHelpAdornment=false,
    showUndefined=false,
    showDontChange=false
}){
    let _options = options;
    if(showUndefined){
        _options = [
            {value: 'UND', text: 'Undefined'},
            ...options
        ];
    }
    if(showDontChange){
        _options = [
            {value: '', text: 'Don\'t change'},
            ...options
        ]
    }
    // if(required){

    //always give the option of no lang selection
    if(true){
        _options = [
            {value: '', text: 'Any'},
            ...options
        ];
    }
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>

        <Select 
            displayEmpty={displayEmpty}
            disabled={disabled}
            required={required}
            error={error}
            size={size}
            fullWidth
            value={value || ""}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : "Any"
            }}

            endAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey={helpKey || "language"} />}
        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
    </FormControl>
}
export default LanguageSelect;