import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueErrorSnack, enqueueSnack } from './snackbar';
import Highcharts from 'highcharts'
import { commonApiPromise } from '../common';
const moment = require('moment-timezone'); //moment-timezone


const API_KEY_KEY = "apiKey";



const setUserTimezone = (user) => {
    Highcharts.setOptions({
        time: {
            getTimezoneOffset: function (timestamp) {
                var zone = user.settings ? user.settings.timezone : "Europe/Rome",
                timezoneOffset = -(moment.tz(timestamp, zone).utcOffset())
                ;
    
                return timezoneOffset;
            }
        }
      });
      
}

export const slice = createSlice({
    name: 'user',
    initialState: {
        apiKey: localStorage.getItem(API_KEY_KEY),
        profile: null,
        all: null
    },
    reducers: {
        logout: (state, action) => {
            state.apiKey = null;
            state.profile = null;
            localStorage.removeItem(API_KEY_KEY)
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers(builder) {

        const editUserCase = (state, action) => {
            const {payload} = action
            if(payload){
                state.all = state.all.map(u => {
                    if(u.userid === payload.userid){
                        return payload;
                    }
                    return u;
                });
            }
        };

        builder.addCase(allUsers.fulfilled, (state, action) => {
            const {payload} = action
            state.all = payload;
        })
        builder.addCase(addUser.fulfilled, (state, action) => {
            const {payload} = action
            if(payload.userid){
                state.all = [...state.all, payload];
            }
        })

        builder.addCase(deleteUser.fulfilled, (state, action) => {
            const {payload} = action;
            if(payload){
                state.all = state.all.filter(u => {
                    if(Array.isArray(action.meta.arg)){
                        return action.meta.arg.indexOf(u.userid)===-1;
                    }else{
                        return u.userid !== action.meta.arg
                    }
                });
            }
        })
        builder.addCase(activateUser.fulfilled, (state, action) => {
            const {payload} = action;
            if(payload){
                state.all = state.all.map(u => {
                    if(action.meta.arg.indexOf(u.userid)===-1){
                        return u;
                    }else{
                        return {
                            ...u,
                            active: true
                        }
                    }
                });
            }
        })
        builder.addCase(deactivateUser.fulfilled, (state, action) => {
            const {payload} = action;
            if(payload){
                state.all = state.all.map(u => {
                    if(action.meta.arg.indexOf(u.userid)===-1){
                        return u;
                    }else{
                        return {
                            ...u,
                            active: false
                        }
                    }
                });
            }
        })

        builder.addCase(editUser.fulfilled, editUserCase)
        builder.addCase(editUserSettings.fulfilled, (state, action) => {
            const newUser = {
                ...state.profile,
                settings: action.payload
            };
            state.profile = newUser;
            setUserTimezone(newUser);
        });

        builder.addCase(login.fulfilled, (state, action) => {
            const {payload} = action;
            // console.log("payload", payload)
            if(payload.userid){
                state.profile = payload;
                state.isAdmin = parseInt(payload.tier) < 3;
                state.apiKey = payload.api_key;
                setUserTimezone(payload);

                axios.defaults.headers["X_API_KEY"] = payload.api_key;
                axios.defaults.headers.common["X_API_KEY"] = payload.api_key;

                localStorage.setItem(API_KEY_KEY,state.apiKey);
            }
        })
        
        builder.addCase(autoLogin.fulfilled, (state, action) => {
            const {payload} = action;
            if(payload.userid){
                state.profile = payload;
                state.isAdmin = parseInt(payload.tier) < 3;
                setUserTimezone(payload);

                axios.defaults.headers.common["X_API_KEY"] = state.apiKey;
                axios.defaults.headers["X_API_KEY"] = state.apiKey;

                // console.log("state.apiKey",state.apiKey)
                // console.log("axios.defaults.headers",axios.defaults.headers);

            }else{
                localStorage.removeItem(API_KEY_KEY);
                state.apiKey = null;
                axios.defaults.headers["X_API_KEY"] = null;
            }
        })
      }
    
})


export const allUsers = createAsyncThunk('users/all', async (filters, store) => {
    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));
    return commonApiPromise(axios.get("/user/all"), store)
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const resetUserPassword = createAsyncThunk('users/resetPassword', async (userid, store) => {
    return commonApiPromise(axios.get("/user/resetpassword/" + userid), store);
})

export const addUser = createAsyncThunk('users/add', async (data, store) => {
    return commonApiPromise(axios.post("/user/new", data), store);
});

export const editUser = createAsyncThunk('users/edit', async (data, store) => {
    const {userid, ...user} = data;
    return commonApiPromise(axios.post("/user/edit/" + userid, user), store);
});

export const editUserSettings = createAsyncThunk('users/settings', async (data, store) => {
    return commonApiPromise(axios.post("/user/editsettings", data), store);
});

export const updateFbKey = createAsyncThunk('users/updateFbKey', async (data, store) => {
    return commonApiPromise(axios.post("/user/updatefbkey", data), store);
});

export const activateUser = createAsyncThunk('users/activate', async (userid, store) => {
    return commonApiPromise(axios.post("/user/toggleactive/1", {userid: userid}), store);
});

export const deactivateUser = createAsyncThunk('users/deactivate', async (userid, store) => {
    return commonApiPromise(axios.post("/user/toggleactive/0", {userid: userid}), store);
});

export const deleteUser = createAsyncThunk('users/delete', async (id, store) => {
    if(Array.isArray(id)){
        return commonApiPromise(axios.post("/user/delete", {userid: id}), store);
    }else{
        return commonApiPromise(axios.post("/user/delete/" + id), store);
    }
});


export const login = createAsyncThunk('user/login', async (loginData, store) => {
    store.dispatch(slice.actions.setLoading(true));
    store.dispatch(slice.actions.setError(false));
    return axios.post("/user/login", loginData)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const autoLogin = createAsyncThunk('user/autoLogin', async (loginData, store) => {
    const state = store.getState();
    store.dispatch(slice.actions.setLoading(true));
    store.dispatch(slice.actions.setError(false));
    const apiKey = state.user.apiKey;
    return axios.get("/user/me/" + apiKey)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const { setError, setLoading, logout } = slice.actions

export default slice.reducer
