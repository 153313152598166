import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { hasErrors } from "../../../../common";
import { validateDashboardInfo, validateDashboardTimeSpan } from "../../../../validator/project";
import DashboardFormInfo from "../../../dashboard/DashboardFormInfo";
import DashboardFormTimeSpan from "../../../dashboard/DashboardFormTimeSpan";

function DashboardInfo({handleNext, handleSkip, onChange, form}){
    const [errors, setErrors] = useState({});
    const margin = 4;
    const dashboard = form.dashboard || {};
    const handleSubmit = () => {
        const _errors = {
            ...validateDashboardInfo(dashboard),
            ...validateDashboardTimeSpan(dashboard)
        };
        setErrors(_errors);
        if(hasErrors(_errors)){
            return;
        }
        handleNext();
    }

    return <>
        <Box sx={{mt: margin, mb: margin}}>
            <DashboardFormInfo form={dashboard} onChange={d => onChange({...form, dashboard: d})} errors={errors} />
        </Box>
        <Typography color="primary.main" variant="h4">Select your time interval</Typography>
        <Typography variant="subtitle1">You can change it anytime</Typography>

        <Box sx={{mt: margin, mb: margin}}>
            <DashboardFormTimeSpan form={dashboard} onChange={d => onChange({...form, dashboard: d})} errors={errors} />
        </Box>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 6}} gap={2}>
            <Button variant="contained" color="primary" onClick={handleSkip}>Skip dashboard creation</Button>
            <Button variant="contained" color="secondary" onClick={handleSubmit}>Next: create a filter</Button>
        </Box>
    </>
}
export default DashboardInfo;