import { Close } from '@mui/icons-material';
import { Dialog,  DialogContent, DialogTitle, IconButton, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import BatchFeedForm from './BatchFeedForm';
import FeedForm from './FeedForm';

const DIALOG_ID = "addFeed"
const DIALOG_WIDTH = "lg"

function AddFeedDialog() {
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const show = useSelector((state) => state.dialog[DIALOG_ID])
    const [feed, setFeed] = useState({});

    const [activeTab, setActiveTab] = useState(0);

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }


    const handleSubmit = e => {
        e.preventDefault();
    }    

    useEffect(() => {
        setFeed({});
    },[show]);
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        PaperProps={{
        }}
    >
        <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            {project.name} - Insert Feed
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
    
        <DialogContent dividers={false} sx={{minHeight: 400}}>

            <Tabs
                value={activeTab}
                onChange={(e, v) => setActiveTab(v)}
            >
                <Tab label="Batch insert" />
                <Tab label="Single feed" />
            </Tabs>
            {activeTab === 1 && <>
                <FeedForm form={feed} onChange={f => setFeed(f)} />
            </>}
            {activeTab === 0 && <>
                <BatchFeedForm />
            </>}
        </DialogContent>
        {/* <DialogActions>
            {loading && <LinearProgress />}
            {!loading && <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                <Button variant="contained" color="secondary" type="submit">Save</Button>
            </Box>}
        </DialogActions> */}
    </Dialog>
}

export default AddFeedDialog;