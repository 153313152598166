import { common } from '@mui/material/colors';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { updateArray } from '../common';
import { enqueueSnack } from './snackbar';

export const slice = createSlice({
    name: 'report',
    initialState: {
        list: [],
        filters: {

        },
        single: null,
        loading: false,
        error: false,

    },
    reducers: {
        setSingle: (state, action) => {
            state.single = action.payload;
        },

        setError: (state, action) => {
            state.error = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(listReports.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = payload.data;
        })

        builder.addCase(loadConfig.fulfilled, (state, action) => {
            const {payload} = action;
            state.config = payload;
        })
        

        builder.addCase(deleteReport.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = state.list.filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = state.list.filter(t => t.id !== meta.arg.id);
            }
        })
        

        builder.addCase(editReport.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = updateArray(state.list, payload);
        })
        
        builder.addCase(addReport.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
            
        })
        builder.addCase(cloneReport.fulfilled, (state, action) => {
            const {payload} = action
            state.list = [
                ...state.list,
                payload
            ];
            
        })
      }
    
})


export const deleteReport = createAsyncThunk('report/delete', async (report, store) => {
    const {id, project} = report;
    return axios.get("/report/delete/" + id).then(r => r.data);
});


export const editReport = createAsyncThunk('report/edit', async (report, store) => {
    return axios.post("/report/update/" + report.id, report).then(r => r.data);
});

export const cloneReport = createAsyncThunk('report/clone', async (id, store) => {
    return axios.get("/report/clone/" + id).then(r => r.data);
});



export const loadConfig = createAsyncThunk('report/config', async (projectLabel, store) => {
    return axios.get("/report/getconfig/" + projectLabel)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const addReport = createAsyncThunk('report/add', async (data, store) => {
    const {report, project} = data;
    const {type, ...otherProps} = report;
    return axios.post("/report/create", {
        project_id: project.id,
        ...otherProps,
        config: {
            type
        }
    }).then(r => r.data);
});


export const listReports = createAsyncThunk('report/list', async (filters, store) => {

    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));

    return axios.get("/report/list/")
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const { setError, setLoading, setJobs } = slice.actions

export default slice.reducer
