import {createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
    name: 'dialog',
    initialState: {
        
    },
    reducers: {
        openDialog: (state, action) => {
            const {payload} = action;
            if(typeof payload === "string"){
                state[action.payload] = true
            }else if(typeof payload === "object"){
                const {id, ...props} = payload;
                state[id] = payload.props || props;
            }
        },
        closeDialog: (state, action) => {
            state[action.payload] = false
        }
    }
})
export const { openDialog, closeDialog } = slice.actions

export default slice.reducer
