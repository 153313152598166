import {  IconButton, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { toggleMenu } from "../../features/app";
import NavBarRight from "./NavBarRight";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const AppBar = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: 'white',
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // }),
}));

export default function NavBar({open, showMenu}){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        dispatch(toggleMenu(true));
    };

    const handleDrawerClose = () => {
        dispatch(toggleMenu(false));
    };
    return <AppBar position="fixed" open={open}>
        <Toolbar sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {showMenu && <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => open ? handleDrawerClose() : handleDrawerOpen()}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        // ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton> }
                <img alt="Home" onClick={() => navigate("/")} src="/assets/img/buzztech-logo.png" style={{
                    cursor: 'pointer',
                    height: 84,
                    marginLeft: 40
                }} />
            </Box>

            <NavBarRight />
        </Toolbar>
    </AppBar>
}