import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { deleteDashboard } from '../../features/project';
import { Close } from '@mui/icons-material';

const DIALOG_ID = "deleteDashboard"
const DIALOG_WIDTH = "sm"

function DeleteDashboardDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const dashboardId = useSelector((state) => state.dialog[DIALOG_ID])
    const dashboards = project.tabs;
    const dashboard = dashboards.find(el => el.id === dashboardId);
    const dashboardLabel = dashboard ? dashboard.label : "";
    const show = !!dashboardId;

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(deleteDashboard({
            project,
            dashboard: dashboardId
        }))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Dashboard deleted"))
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                Delete dashboard
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={false}>
                <Typography variant="subtitle1">Are you sure you want to delete the <b>{dashboardLabel}</b> dashboard?</Typography>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="error" type="submit">Delete</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default DeleteDashboardDialog;