import { FormControlLabel, Grid, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";

function ChannelForm({form, onChange, type="admin"}){
    const isSelf = type==="self";
    return <>
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextField
                    autoFocus
                    fullWidth
                    required
                    size="small"
                    label="Profile id or Profile name"
                    value={form.channelId || ""}
                    onChange={e => onChange({...form, channelId: e.target.value})}
                />
            </Grid>
            <Grid item sm={12}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    label="Friendly name"
                    value={form.channelName || ""}
                    onChange={e => onChange({...form, channelName: e.target.value})}
                />
            </Grid>
            <Grid item sm={12}>
                <Typography variant="subtitle1">Platform</Typography>

                <RadioGroup
                    ali
                    value={form.platform || ""}
                    onChange={(e) => {
                        const platform = e.target.value;
                        onChange({
                            ...form, 
                            platform
                        })
                    }}
                >
                    <FormControlLabel value="instagram" control={<Radio />} label="Instagram" />
                    <FormControlLabel value="facebook" control={<Radio />} label="Facebook" />
                    <FormControlLabel value="twitter" control={<Radio />} label="Twitter" />
                </RadioGroup>
            </Grid>
        </Grid>
    </>
}
export default ChannelForm;