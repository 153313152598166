import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueSnack } from './snackbar';

export const slice = createSlice({
    name: 'tag',
    initialState: {
        groups: [],
        list: [],
        rules: [],
        loading: false,
        error: false,

    },
    reducers: {
        setTagGroups: (state, action) => {
            state.groups = action.payload;
        },
        
        setError: (state, action) => {
            state.error = action.payload;
        },
        
        setRules: (state, action) => {
            state.rules = action.payload;
        },

        setLoading: (state, action) => {
            state.loading = action.payload;
        },

        setTags: (state, action) => {
            state.list = action.payload;
        }
    },
    
    extraReducers(builder) {
        builder.addCase(addTagGroup.fulfilled, (state, action) => {
            const {payload} = action;
            state.groups = [...state.groups, payload];
            state.recentlyAddedGroup = payload;
        })

        builder.addCase(editTagGroup.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = state.list.map(tag => {
                if(tag.folder && tag.folder.id === payload.id){
                    tag.folder.label = payload.label;
                }
                return tag;
            })
            state.groups = state.groups.map(g => {
                if(g.id === payload.id){
                    return payload;
                }
                return g;
            });
        })

        builder.addCase(addTag.fulfilled, (state, action) => {
            const {payload} = action;
            state.list = [
                ...state.list || [],
                payload   
            ]
        })

        builder.addCase(editTag.fulfilled, (state, action) => {
            const {meta, payload} = action;
            state.list = state.list.map(tag => {
                if(tag.id === meta.arg.id){
                    tag = payload;
                }
                return tag;
            })
        })

        builder.addCase(deleteTag.fulfilled, (state, action) => {
            const {meta} = action
            if(Array.isArray(meta.arg.id)){
                state.list = state.list.filter(t => meta.arg.id.indexOf(t.id)===-1);
            }else{
                state.list = state.list.filter(t => t.id !== meta.arg.id);
            }
        })

        builder.addCase(addRule.fulfilled, (state, action) => {
            const {payload, meta} = action;
            state.list = state.list.map(tag => {
                if(tag.id === meta.arg.tagId){
                    tag.rules = [
                        ...tag.rules,
                        {
                            ...meta.arg.rule,
                            id: payload.id
                        }
                    ] 
                }
                return tag;
            })
        })
        builder.addCase(editRule.fulfilled, (state, action) => {
            const {meta} = action;
            state.list = state.list.map(tag => {
                if(tag.id === meta.arg.tagId){
                    tag.rules = tag.rules.map(r => {
                        if(r.id === meta.arg.rule.id){
                            return meta.arg.rule;
                        }
                        return r;
                    })
                }
                return tag;
            })
        })
        builder.addCase(deleteRule.fulfilled, (state, action) => {
            const {meta} = action;
            state.list = state.list.map(tag => {
                if(tag.id === meta.arg.tag.id){
                    tag.rules = tag.rules.filter(rule => rule.id !== meta.arg.id);
                }
                return tag;
            });
        })

    }
    
})


// Tags
export const addTag = createAsyncThunk('tag/addTag', async (tag, store) => {
    const {label, project, folder} = tag;
    return axios.post("/tag/new", {
        project_id: project.id,
        tag_label: label,
        tag_folder_id: folder
    }).then(r => r.data);
});

export const deleteTag = createAsyncThunk('tag/deleteTag', async (tag, store) => {
    const {id, project} = tag;
    return axios.post("/tag/delete", {
        project_id: project.id,
        tag_id: id
    }).then(r => r.data);
});

export const addRule = createAsyncThunk('tag/addRule', async (action, store) => {
    const {project, rule, tagId} = action;
    
    return axios.post("/tag/newrule",{
        project_id: project.id,
        tag_id: tagId,
        tag_field: rule.field,
        tag_value: rule.value,
        tag_rule: rule.rule,
    }).then(r => r.data);
});

export const deleteRule = createAsyncThunk('tag/deleteRule', async (action, store) => {
    const {project, id} = action;
    
    return axios.post("/tag/deleterule",{
        project_id: project.id,
        rule_id: id
    }).then(r => r.data);
});

export const editRule = createAsyncThunk('tag/editRule', async (action, store) => {
    const {project, rule, tagId} = action;
    
    return axios.post("/tag/saverule",{
        project_id: project.id,
        tag_id: tagId,
        tag_field: rule.field,
        tag_value: rule.value,
        tag_rule: rule.rule,
        rule_id: rule.id
    }).then(r => r.data);
});

export const editTag = createAsyncThunk('tag/editTag', async (action, store) => {
    const {label, id, folder, project} = action;
    return axios.post("/tag/edit/", {
        project_id: project.id,
        tag_label: label,
        tag_id: id,
        tag_folder_id: folder
    }).then(r => r.data);

});

// Tag Groups
export const addTagGroup = createAsyncThunk('tag/addTagGroup', async (action, store) => {
    const {name, project} = action;
    const group = {name};
    if(project){
        return axios.post("/tagfolder/new/", {
            project_id: project.id,
            label: name
        }).then(r => r.data);
    }else{
        return Promise.resolve().then(r => group)
    }

});

export const editTagGroup = createAsyncThunk('tag/editTagGroup', async (action, store) => {
    const {label, id, project} = action;
    return axios.post("/tagfolder/save/", {
        project_id: project.id,
        folder: {
            label,
            id
        }
    }).then(r => r.data);

});

export const listTagGroups = createAsyncThunk('tag/listGroup', async (projectId, store) => {
    if(!projectId){
        store.dispatch(slice.actions.setTagGroups([]));
        return;
    }
    const state = store.getState();
    const apiKey = state.user.apiKey;
    return axios.get("/tag/listGroup/" + projectId + "/" + apiKey)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        store.dispatch(slice.actions.setTagGroups)
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})

export const { setError, setLoading, setTagGroups, setTags, setRules} = slice.actions

export default slice.reducer
