import { Grid } from "@mui/material";
import SubscriptionTypeSelect from "../../common/SubscriptionTypeSelect";
import UserSelect from "../../common/UserSelect";

function SubscriberForm({form, onChange, errors}){
    const subscriberForm = form || {};
    const setSubscriberForm = f => {
        onChange && onChange(f);
    }

    return <Grid container spacing={4} columnSpacing={3} mt={0} mb={2}>
        <Grid item xs={12}>
            <UserSelect
                disabled={!!subscriberForm.id}
                value={subscriberForm.user || ""}
                onChange={user => setSubscriberForm({...subscriberForm, user})}

            />
        </Grid>
        <Grid item xs={12}>
            <SubscriptionTypeSelect
                value={subscriberForm.subscription || ""}
                onChange={subscription => setSubscriberForm({...subscriberForm, subscription})}

            />
        </Grid>
    </Grid>
}

export default SubscriberForm;