import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import GeneralSettingsSection from "./settings/GeneralSettingsSection";
import NetfeedrSection from "./settings/NetfeedrSection";

export default function SettingsPage(){
    const params = useParams();
    let section = (params.section || "general");
    const navigate = useNavigate();
    
    const sections = {
        general: {
            component: <GeneralSettingsSection key="general" />,
            title: 'General Settings'
        },
        netfeedr: {
            component: <NetfeedrSection key="netfeedr" />,
            title: 'Netfeedr Channels'
        }
    }
    if(!sections[section]){
        section = "general";
    }
    const sectionIndex = Object.keys(sections).indexOf(section);
    const Component = sections[section].component;
    
    return <MainContainer>
        <Grid container spacing={4} flexWrap="nowrap">
            <Grid item minWidth={240}>
                <Tabs
                    orientation="vertical"
                    value={sectionIndex}
                    onChange={(e, v) => navigate(v === "general" ? "/admin/settings" : "/admin/settings/" + Object.keys(sections)[v])}
                    sx={{ 
                        borderRight: 1, 
                        borderColor: 'divider',
                        pr: 2,
                        '& .MuiTabs-indicator': {
                            left: 0
                        }
                    }}
                >
                    {Object.keys(sections).map((s, i) => <Tab sx={{alignItems: 'flex-start'}} label={sections[s].title} key={i} />)}
                </Tabs>
            </Grid>
            <Grid item flexGrow={1} pr={2}>
                <Box gap={4} display="flex" flexDirection={"column"}>
                    {Component}
                </Box>
            </Grid>
        </Grid>
    </MainContainer>
}