import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Delete, Edit, PlayArrow, Stop } from "@mui/icons-material";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../features/dialog";
import { getTierById } from "../../config/tiers";

function ChannelTable({channels, selection, setSelection}){
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.single);
    const projects = useSelector(state => state.project.list);

    const toggleSelect = id => {
        let _selection = [];
        if(selection.indexOf(id)!==-1){
            _selection = selection.filter(s => s !== id);
        }else{
            _selection = [
                ...selection,
                id
            ]
        }
        setSelection(_selection);
    }
    return <>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width={40}>
                        <Checkbox 
                            checked={selection.length > 0}
                            indeterminate={selection.length > 0 && selection.length != channels.length} 
                            onChange={(e) => {
                                if(e.target.checked){
                                    setSelection(channels.map(r => r.channelid));
                                }else{
                                    setSelection([])
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Channel ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {channels.map((channel,i) => {
                    const selected = selection.indexOf(channel.id) !== -1;
                    return <TableRow key={i} hover>
                        <TableCell>
                            <Checkbox checked={!!selected} onChange={() => toggleSelect(channel.id)} />
                        </TableCell>
                        <TableCell width={140}>{channel.id}</TableCell>
                        <TableCell width={70}>{channel.platform}</TableCell>
                        <TableCell width={140}>{channel.channelId}</TableCell>
                        <TableCell style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word"
                        }}>
                            {channel.channelName}
                        </TableCell>
                        <TableCell align="right" width={100}>
                            <Tooltip title="Delete">
                                <IconButton size="small"  onClick={() => {
                                    dispatch(openDialog({id: "deleteChannel", props: channel.id}));
                                }}><Delete /></IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

export default ChannelTable;