import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const options = Intl.supportedValuesOf('timeZone').map(t => ({
    value: t,
    text: t
}));

function TimezoneSelect({
    value, 
    onChange,
    label,
    size, 
    disabled=false, 
    helperText="",
    error=false,
    displayEmpty=true,
    placeholder="",
    required=true
}){
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            required={required}
            displayEmpty={displayEmpty}
            error={error}
            disabled={disabled}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                return v ? options.find(o => o.value === v).text : placeholder
            }}
        >
            {options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>

        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default TimezoneSelect;