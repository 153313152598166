function validateProjectJob(form){
    let errors = {}
    errors.label = !form.label;
    // errors.search = !form.search;
    errors.freq = !form.freq;
    errors.type = !form.type;
    // errors.searchLang = !form.searchLang;
    return errors;
}
function validateProjectSource(form){
    let errors = {}
    return errors;
}
function validateProjectAlert(form){
    let errors = {}
    // errors.label = !form.label;
    // errors.search = !form.search;
    // errors.freq = !form.freq;
    // errors.type = !form.type;
    // errors.searchLang = !form.searchLang;
    return errors;
}

function validateDashboardTimeSpan(form){
    const errors = {};
    const config = form.config || {};
    const filters = config.filters || {};
    const type = config.spanType || "fixed";
    if(type === "fixed"){
        if(!filters.date){
            errors.start = errors.end = true
        }else{
            if(!filters.date.start){
                errors.start = true
            }
            if(!filters.date.end){
                errors.end = true
            }
        }
    }
    return errors;
}
function validateDashboardInfo(form){
    const errors = {};
    errors.label = !form.label;
    return errors;
}
function validateProjectWizard(form, step, subStep=null){
    let errors = {};
    switch(step){
        case 0:
            errors.name = !form.name;
            errors.label = !form.label;
            break;
        case 1:
            errors = validateProjectJob(form);
            break;
        case 2:
            if(subStep===1){
                errors.name = !form.name;
                errors.group = !form.group;
            }else{
                errors.rule = !form.rule;
                errors.field = !form.field;
                errors.value = !form.value;
            }
            break;
        case 3: 
            if(subStep===0){
                errors = {
                    ...validateDashboardTimeSpan(form),
                    ...validateDashboardInfo(form)
                }
                break;
            }
            break;
        default: break;
    }
    return errors;
}
export {
    validateProjectWizard,
    validateProjectSource, 
    validateProjectJob, 
    validateProjectAlert, 
    validateDashboardInfo, 
    validateDashboardTimeSpan
};