import {  CheckCircle, CheckCircleOutlined } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToSelection, removeFromSelection } from "../../features/post";
import GenericPostCard from "./card/GenericPostCard";
import TwitterPostCard from "./card/TwitterPostCard";
import InstagramPostCard from "./card/InstagramPostCard";
import TikTokPostCard from "./card/TikTokPostCard";
import FacebookPostCard from "./card/FacebookPostCard";
import NewsPostCard from "./card/NewsPostCard";
import BlogPostCard from "./card/BlogPostCard";
import ForumPostCard from "./card/ForumPostCard";

import LinkedinPostCard from "./card/LinkedinPostCard";
import RedditPostCard from "./card/RedditPostCard";
import ReviewsPostCard from "./card/ReviewsPostCard";
import YoutubePostCard from "./card/YoutubePostCard";

function PostCard({post, project}){
    const dispatch = useDispatch();
    // Selected posts uuids
    const selectedPosts = useSelector(state => state.post.selected);
    // Are we selecting posts?
    const selecting = selectedPosts && selectedPosts.length > 0;
    // Is this post selected?
    const selected = selecting && selectedPosts.indexOf(post.uuid) !== -1;

    const [hover, setHover] = useState(false);
    const platform = post.platform;
    const padding = 1;
    let Component = GenericPostCard;
    if(platform === "tw")
        Component = TwitterPostCard;
    if(platform === "ig")
        Component = InstagramPostCard;
    if(platform === "tt")
        Component = TikTokPostCard;
    if(platform === "nf" || platform === "nc" || platform === "ty")
        Component = FacebookPostCard;
    if(platform === "wn")
        Component = NewsPostCard;
    if(platform === "wb")
        Component = BlogPostCard;
    if(platform === "wf")
        Component = ForumPostCard;

    if(platform === "in")
        Component = LinkedinPostCard;
    if(platform === "rd")
        Component = RedditPostCard;
    if(platform === "rv")
        Component = ReviewsPostCard;
    if(platform === "yt")
        Component = YoutubePostCard;
        
    const toggleSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(selected){
            dispatch(removeFromSelection(post.uuid));
        }else{
            dispatch(addToSelection(post.uuid));
        }

    }
    const paperStyle = {
        cursor: 'pointer',
    }
    if(selected){
        paperStyle.borderColor= (theme) => selected ? theme.palette.secondary.main : theme.palette.divider;
        paperStyle.borderStyle = 'solid';
    }
    return <Paper 
        elevation={2} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        onClick={selecting ? toggleSelect : null}
        style={selecting ? paperStyle : null}
    >
        <Box 
            pt={padding} 
            pl={padding} 
            pr={padding} 
            sx={{height: 25, cursor: 'pointer'}}
            onClick={toggleSelect}
        >
            {(hover || selecting) && <>
                {selected ? 
                <CheckCircle fontSize="small" color="success" /> : 
                <CheckCircleOutlined fontSize="small" /> }
            </>}
        </Box>
        <Component post={post} project={project} padding={padding} />
    </Paper>
}
export default PostCard;