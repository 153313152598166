import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import PostFilterForm from "../post/PostFilterForm";


function SlotFiltersDialog({onClose, open, onSave, data}){
    const [form, setForm] = useState(data);
    
    useEffect(() => {
        setForm(data);
    }, [data]);

    return <Dialog 
        open={open} 
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        >
        <DialogTitle>Edit widget filters</DialogTitle>
        <DialogContent>
            <PostFilterForm 
                form={form}
                onChange={f => setForm(f)}
                onSubmit={() => onSave(form)}
            />
        </DialogContent>
        <DialogActions>
            <Box display="flex" justifyContent={"space-between"} width={1}>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={() => onSave(form)}>Save</Button>
            </Box>
        </DialogActions>
    </Dialog>
}
export default SlotFiltersDialog;