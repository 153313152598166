import { Box, Card, CardContent, CardHeader, FormControlLabel, Grid, Radio, RadioGroup, Slider, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { intervalLabel } from "../../common";
import LanguageSelect from "../common/LanguageSelect";
import PlatformSelect from "../common/PlatformSelect";
import PrioritySelect from "../common/PrioritySelect";
import SentimentSelect from "../common/SentimentSelect";
import TagFilterOperatorSelect from "../common/TagFilterOperatorSelect";
import TagFilterSelect from "../common/TagFilterSelect";

function PostFilterForm({onChange, onSubmit, form}){
    const [_form, setForm] = useState({});

    const handleChange = (key, value) => {
        const _form = {...form, [key]: value}
        onChange && onChange(_form);
        setForm(_form)
    }
    const handleDoubleChange = (key1, value1, key2, value2) => {
        const _form = {...form, [key1]: value1, [key2]: value2}
        onChange && onChange(_form);
        setForm(_form)
    }
    // const handleSubmit = e => {
    //     e.preventDefault();

    //     onSubmit && onSubmit(form);
    // }
    useEffect(() => {
        setForm(form || {});
    }, [form]);

    return <Grid container spacing={2}>
        <Grid item sm={12}>

            <Card>
                <CardContent>
                    <Grid container spacing={4}>

                        <Grid item sm={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                size="small"
                                value={_form.text_search || ""}
                                onChange={e => handleChange("text_search", e.target.value)}
                                label="Filter by text"
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <SentimentSelect
                                hideHelpAdornment
                                displayEmpty
                                required={false}
                                placeholder="All"
                                value={_form.sentiment || ""}
                                onChange={value => handleChange("sentiment", value)}
                                label="Filter by sentiment"
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <PlatformSelect
                                hideHelpAdornment
                                displayEmpty
                                required={false}
                                placeholder="All"
                                value={_form.platform || ""}
                                onChange={value => handleChange("platform", value)}
                                label="Filter by platform"
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <LanguageSelect
                                hideHelpAdornment
                                displayEmpty
                                required={false}
                                placeholder="All"
                                value={_form.language || ""}
                                onChange={value => handleChange("language", value)}
                                label="Filter by language"
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <PrioritySelect
                                hideHelpAdornment
                                displayEmpty
                                required={false}
                                placeholder="All"
                                value={_form.priority || ""}
                                onChange={value => handleChange("priority", value)}
                                label="Filter by priority"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item sm={6}>
            <Card>
                <CardHeader sx={{pb: 0}} title="Tags"></CardHeader>
                <CardContent sx={{pb: 0, pt: 0}}>
                    <Stack spacing={2}>

                        <RadioGroup 
                            row
                            value={_form.tag_type || "default"}
                            onChange={e => {
                                handleDoubleChange("tag_type",e.target.value,"tags", e.target.value === "reverse" ? ["9999"] : []);
                            }}
                        >
                            <FormControlLabel value="default" control={<Radio size="small" />} label="Filter posts with tags" />
                            <FormControlLabel value="reverse" control={<Radio size="small" />} label="Filter posts without tags" />
                        </RadioGroup>
                        {_form.tag_type !== "reverse" && <>
                        
                            <TagFilterSelect
                                hideHelpAdornment
                                displayEmpty
                                placeholder="Select tags"
                                size="small"
                                fullWidth
                                value={_form.tags || []}
                                onChange={tags => handleChange("tags", tags)}
                                label="Filter by tags"
                            />

                            <TagFilterOperatorSelect
                                fullWidth
                                required={false}
                                value={_form.tags_operator || ""}
                                onChange={e => handleChange("tags_operator", e)}
                            />
                        </>}
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
        <Grid item sm={6}>

            <Card>
                <CardHeader sx={{pb: 0}} title="Date"></CardHeader>
                <CardContent sx={{pb: 0, pt: 0}}>
                    <Stack spacing={2}>

                        <RadioGroup 
                            row
                            value={_form.date_type || "interval"}
                            onChange={e => {
                                const type = e.target.value;
                                const newform = {..._form};
                                newform.date_type = type;
                                if(type==="interval"){
                                    delete newform.starttimestamp;
                                    delete newform.endtimestamp;
                                }else{
                                    delete newform.interval;
                                }
                                onChange && onChange(newform);
                                setForm(newform);
                            }}
                        >
                            <FormControlLabel value="interval" control={<Radio size="small" />} label="Dynamic time span" />
                            <FormControlLabel value="default" control={<Radio size="small" />} label="Fixed time span" />
                        </RadioGroup>

                        {(!_form.date_type || _form.date_type==="interval") && <>
                            <Box px={8} pt={2} pb={4.2}>
                                <Slider
                                    valueLabelDisplay="on"
                                    marks={[{
                                        value: 1,
                                        label: 'a day'
                                    },{
                                        value: 360,
                                        label: 'a year'
                                    }]}
                                    value={_form.interval || 1}
                                    min={1}
                                    step={1}
                                    max={360}
                                    getAriaValueText={value => intervalLabel(value)}
                                    valueLabelFormat={value => intervalLabel(value)}
                                    onChange={e => handleChange("interval", e.target.value)}
                                />
                            </Box>
                        </>}
                        {_form.date_type==="default" && <>
                            
                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                label="Date from"
                                value={_form.starttimestamp  || ""}

                                onChange={e => handleChange("starttimestamp",e.target.value)}           
                            />

                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                label="Date to"
                                value={_form.endtimestamp  || ""}
                                onChange={e => handleChange("endtimestamp",e.target.value)}
                            />
                        </>}



                    </Stack>
                </CardContent>
            </Card>
        </Grid>
        

    </Grid>;
}
export default PostFilterForm;