import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueSnack } from './snackbar';

export const slice = createSlice({
    name: 'dashboard',
    initialState: {
        config: null,
        tabViews: {}
    },
    reducers: {
        setTabView: (state, {payload}) => {
            const {tab, view} = payload;
            state.tabViews[tab] = view;
        }
    },
    extraReducers(builder) {
        builder.addCase(loadConfig.fulfilled, (state, action) => {
            const {payload} = action;
            payload.sort((a,b) => {
                return a.title.localeCompare(b.title);
            })
            state.config = payload;
        })
        

      }
    
})

export const loadConfig = createAsyncThunk('dashboard/config', async (projectLabel, store) => {
    return axios.get("/dashboard/getconfig/" + projectLabel)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})


export const updateElementSettings = createAsyncThunk('dashboard/updateElementSettings', async (data={
    tabId: null,
    elementIndex: 0,
    settings: {}
}, store) => {
    const {tabId, elementIndex, settings} = data;
    return axios.post("/dashboard/updateElementSettings/" + tabId + "/" + elementIndex, settings)
    .then((response) => {
        const data = response.data;
        if(data.error){
            store.dispatch(enqueueSnack({
                message: data.error,
                options: {
                    variant: 'error'
                }
            }))
        }
        return data;
    })
    .catch((e) => {
        store.dispatch(enqueueSnack({
            message: e.message,
            options: {
                variant: 'error'
            }
        }))
    })
    .finally(() => {
        
    })
})

export const { setError, setTabView } = slice.actions

export default slice.reducer
