import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const options = [{
    value: "0",
    text: "No"
},{
    value: "1",
    text: 'Yes'
}];

function BooleanSelect({
    value, 
    onChange,
    label,
    size, 
    disabled=false, 
    helperText="",
    error=false,
    displayEmpty=true,
    placeholder="",
    required=true
}){
    const val = value===true || value==="1" || value===1 ? "1":"0";
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!val} error={error} >{label} </InputLabel>
        <Select 
            required={required}
            displayEmpty={displayEmpty}
            error={error}
            disabled={disabled}
            size={size}
            fullWidth
            value={val}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return "No";
                return v ? options.find(o => o.value === v).text : placeholder
            }}

        >
            {options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>

        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default BooleanSelect;