import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";

function VirtualForm({fields, data, onChange, onSubmit}){
    const [form, setForm] = useState({});
    useEffect(() => {
        setForm(data);
    },[data]);
    const _onChange = (field, value) => {
        const _form = {
            ...form,
            [field]: value
        };
        setForm(_form)
        onChange && onChange(_form)
    }
    // console.log(fields);
    const inner = Object.keys(fields).map(fieldId => {
        const field = fields[fieldId];
        const value = data[fieldId] || field["default"]

        return <Grid sm={12} item key={fieldId}>
            {field.type === "select" && <FormControl fullWidth>
                <InputLabel required={!!field.required}>{field.label}</InputLabel>    
                <Select
                    fullWidth
                    required={!!field.required}
                    value={value}
                    onChange={e => _onChange(fieldId, e.target.value)}
                >
                    {Object.keys(field.options).map(oName => {
                        const text = field.options[oName];
                        return <MenuItem key={oName} value={oName}>{text}</MenuItem>
                    })}
                </Select>
            </FormControl>}
            
            {(!field.type || field.type === "text" || field.type === "textarea" || field.type === "number") && <TextField
                fullWidth
                type={field.type || "text"}
                required={!!field.required}
                label={field.label}
                multiline={field.type==="textarea"}
                rows={field.type==="textarea" ? 3 : 1}
                value={value}
                onChange={e => _onChange(fieldId, e.target.value)}
            />}
        </Grid>
    });
    if(onSubmit){
        return <form onSubmit={onSubmit}>
            {inner}
        </form>
    }
    return inner;
}
export default VirtualForm;