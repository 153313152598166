import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

import alertfrequency from "../../config/alertfrequency";
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(alertfrequency).map(key => {
    return {
        value: key,
        text: alertfrequency[key]
    }
});
function AlertFrequencySelect({
    value, 
    onChange,
    size,
    displayEmpty=false,
    placeholder="",
    helperText,
    error=false,
    label="Alert Frequency", 
    helpKey="alertFrequency",
    disabled=false, 
    hideHelpAdornment=false,
    required=true, 
}){
    const _options = required ? [...options] : [
        {value: 'any', text: 'Any'},
        ...options
    ];
console.log(value)
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            error={error}
            disabled={disabled}
            displayEmpty={displayEmpty}
            required={required}
            size={size}
            fullWidth
            value={value || ""}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = _options.find(o => o.value === v);
                return o ? o.text : ""
            }}
            endAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey={helpKey} />}
        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
        
        {helperText && <FormHelperText>{helperText}</FormHelperText>
}
    </FormControl>
}
export default AlertFrequencySelect;