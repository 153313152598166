import {  FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

import sentiments from '../../config/sentiments';
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(sentiments).map(key => {
    return {
        value: key,
        text: sentiments[key]
    }
});

function SentimentSelect({
    value, 
    onChange,
    size,
    error=false,
    label="Sentiment", 
    hideHelpAdornment=false,
    displayEmpty=false,
    placeholder="",
    disabled=false, 
    required=true,
    showDontChange=false,
    showAny=false
}){
    var _options;
    if (showDontChange){
        _options = [
            {value:"",text:"Don't change"},
            ...options]
        } else {
            _options = [
                ...options
            ]
        }
        if(showAny){
            _options = [
                {value:"any",text:"Any"},
                ...options]
            } else {
                _options = [
                    ...options
                ]
            }
    
    // console.log(_options)
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            displayEmpty={displayEmpty}
            disabled={disabled}
            required={required}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            endAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="sentiment" />}
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v);
                return o ? o.text : "Any"
            }}

        >
            {_options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>
    </FormControl>
}
export default SentimentSelect;