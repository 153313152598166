import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasErrors } from '../../common';
import { closeDialog, openDialog } from '../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../features/snackbar';
import { addRule, editRule } from '../../features/tag';
import TagRulePostFieldSelect from '../common/TagRulePostFieldSelect';
import TagRuleSelect from '../common/TagRuleSelect';

const DIALOG_ID = "editTagRules"
const DIALOG_WIDTH = "md"

function EditTagRulesDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const tagId = useSelector((state) => state.dialog[DIALOG_ID])
    const tags = useSelector(state => state.tag.list);
    const tag = tags.find(t => t.id === tagId);
    const show = !!tagId;
    const [errors, setErrors] = useState({});
    const [rule, setRule] = useState(null);
    const rules = tag && tag.rules;

    useEffect(() => {
        if(!rules || rules.length === 0){
            handleAddRule();
        }else{
            setRule(null);
        }
        setLoading(false);
    }, [rules])


    const handleAddRule = () => {
        setRule({
            field: '',
            value: '',
            rule: '',
        })
    }

    const handleSaveRule = () => {
        setErrors({
            rule: !rule.rule,
            value: !rule.value,
            field: !rule.field,
        })
        if(hasErrors(errors)){
            return;
        }
        setLoading(true);
        let promise = null;
        const payload = {
            project,
            rule,
            tagId
        };
        if(!rule.id){
            promise = dispatch(addRule(payload));
        }else{
            promise = dispatch(editRule(payload));
        }
        promise
        .then(r => {
            dispatch(enqueueSuccessSnack("Tag rule saved"))
            setRule(null);
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        if(rule){
            handleSaveRule();
        }else{
            handleAddRule();
        }
        return;
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    if(!tag)
        return null;
    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
    >
        <form onSubmit={handleSubmit} noValidate>
            <DialogTitle>
                {rule ? <>
                    Apply tag {tag.label} to any feed if:
                </> : <>
                    Edit tag rules
                </>}
            </DialogTitle>
            <DialogContent dividers={false}>
                {rule && <>
                    <Grid container spacing={4}>
                        <Grid item md={12}>
                            <TagRulePostFieldSelect
                                fullWidth
                                error={errors.field}
                                helperText="The field to verify the condition for"
                                value={rule.field}
                                onChange={field => setRule({...rule, field})}
                                required={true}
                                placeholder="Select field"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TagRuleSelect
                                fullWidth
                                error={errors.rule}
                                helperText="The rule to verify the condition with"
                                value={rule.rule || ""}
                                onChange={_rule => setRule({...rule, rule: _rule})}
                                required={true}
                                placeholder="Select rule type"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                fullWidth
                                multiline={true}
                                rows={3}
                                label="Tagging value"
                                placeholder="Insert tagging value"
                                helperText="The value to check your rule against"
                                error={errors.value}
                                value={rule.value}
                                onChange={e => setRule({...rule, value: e.target.value})}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </>}

                {!rule && <>
                
                    {!rules || rules.length === 0 ? (<>
                        <Typography>No rules have been defined. <span className="link" onClick={handleAddRule}>Create rule</span></Typography>
                    </>) : (<>
                        <Table size='small'>
                            {/* <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Rule
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead> */}
                            <TableBody>
                                {rules && rules.map(r => {
                                    return <TableRow key={r.id} hover>
                                        <TableCell className='link' onClick={() => setRule(r)}>
                                            {r.field} {r.rule} {r.value}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <IconButton size="small"  onClick={() => {
                                                dispatch(openDialog({id: "deleteTagRule", props: {
                                                    rule: r,
                                                    tag: tag
                                                }}));
                                            }}><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </>)}
                </>}

                {loading && <LinearProgress />}

            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">{rule ? "Save" : "Add Rule"}</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default EditTagRulesDialog;