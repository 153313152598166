import React, { useEffect, useRef } from 'react';

import { Grid, Paper, Typography } from "@mui/material";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import { loadApplications, loadHelpStrings } from '../../features/app';



export default function LandingPage(){

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadApplications());
    }, [dispatch]);

    const apps = useSelector(state => state.app.applications);
    const user = useSelector(state => state.user.profile);

    // console.log("state",useSelector(state=>state));
    // console.log("apps--",apps)

    const p = 12;
    return <MainContainer showMenu={false}>
        <Grid container spacing={4} pr={p*2} pl={p}>
            {apps.map((app,i) => {
                const img = <img alt={app.title} src={app.icon} style={{maxWidth: '100%'}}/>;
                return <Grid key={i} item sm={4}>
                    <Paper sx={dimOnFalse(app.enabled)} elevation={4}>

                        {app.external ? <>
                            <a href={app.enabled ? "/app/"+app.app_id : "#"}>{img}</a>
                        </> : <>
                            <Link to={app.enabled ? app.url : "#"}>{img}</Link>
                        </>}
                        
                        <Typography p={2} variant="h5">{app.title}</Typography>
                        {
                            user.tier === 1 ? <>
                            <Typography p={3} variant="p"><small>{app.adminTitle}</small></Typography>
                            </> : <>
                            </>
                        }
                    </Paper>
                </Grid>
            })}
        </Grid>
    </MainContainer>
}

const dimOnFalse = (flag) => {
    return {
        opacity: flag ? 1 : 0.45
    }
}
