import { TextField } from "@mui/material";

function DashboardFormInfo({form, onChange, errors={}}){
    return <>
        <TextField
            error={errors.label}
            value={form.label || ""} 
            helperText="The label to identify the dashboard"
            autoFocus
            required
            fullWidth
            placeholder="My dashboard name"
            label="Dashboard name"
            onChange={e => onChange({...form, label: e.target.value})}
        />
    </>
}
export default DashboardFormInfo;