const jobfrequency = {
    60: "1 minutes",
    300: "5 minutes",
    900: "15 minutes",
    1800: "30 minutes",
    3600: "1 hour",
    7200: "2 hours",
    14400: "4 hours",
    21600: "6 hours",
    28800: "8 hours",
    43200: "12 hours",
    64800: "18 hours",
    86400: "24 hours",
    172800: "48 hours"
};
const getJobFrequencyLabel = (id) => {
    const label = jobfrequency[id];
    return label || id;
}

export default jobfrequency;
export {getJobFrequencyLabel};

