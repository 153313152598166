import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import FacebookLogin from "./FacebookLogin";
import ProfileInfo from "./ProfileInfo";
import UserSettings from "./UserSettings";

export default function ProfilePage(){
    const params = useParams();
    let section = (params.section || "profile");
    const navigate = useNavigate();
    
    const sections = {
        profile: {
            component: <ProfileInfo key="profile" />,
            title: 'Profile info'
        },
        facebook: {
            component: <FacebookLogin key="facebook" />,
            title: 'Facebook Login'
        },
        settings: {
            component: <UserSettings key="settings" />,
            title: 'Settings'
        }
    }
    if(!sections[section]){
        section = "profile";
    }
    const sectionIndex = Object.keys(sections).indexOf(section);
    const Component = sections[section].component;
    
    return <MainContainer>
        <Grid container spacing={4}>
            <Grid item>
                <Tabs
                    orientation="vertical"
                    value={sectionIndex}
                    onChange={(e, v) => navigate(v === "profile" ? "/profile" : "/profile/" + Object.keys(sections)[v])}
                    sx={{ 
                        borderRight: 1, 
                        borderColor: 'divider',
                        pr: 2,
                        '& .MuiTabs-indicator': {
                            left: 0
                        }
                    }}
                >
                    {Object.keys(sections).map((s, i) => <Tab sx={{alignItems: 'flex-start'}} label={sections[s].title} key={i} />)}
                </Tabs>
            </Grid>
            <Grid item flexGrow={1} pr={2}>
                <Box gap={4} display="flex" flexDirection={"column"}>
                    {Component}
                </Box>
            </Grid>
        </Grid>
    </MainContainer>
}