import {  FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import tiers from '../../config/tiers';
import HelpAdornment from "./HelpAdornment";

const options = Object.keys(tiers).map(key => {
    return {
        value: key,
        text: tiers[key]
    }
});

function UserTierSelect({
    value, 
    onChange,
    size,
    label="Tier", 
    disabled=false, 
    helperText="",
    error=false,
    displayEmpty=true,
    hideHelpAdornment=false,
    placeholder="",
    required=true
}){
    return<FormControl sx={{minWidth: 150}} fullWidth size="small">
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
        <Select 
            required={required}
            displayEmpty={displayEmpty}
            error={error}
            disabled={disabled}
            size={size}
            fullWidth
            value={value}
            label={label} 
            onChange={(e) => {onChange(e.target.value)}} 
            renderValue={v => {
                if(!v)
                    return placeholder;
                const o = options.find(o => o.value === v+"");
                return o ? o.text : ""
            }}            
            startAdornment={hideHelpAdornment ? null : <HelpAdornment helpKey="userTier" />}
        >
            {options.map(o => { 
                return <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>            
            })}

        </Select>

        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText> }
    </FormControl>
}
export default UserTierSelect;