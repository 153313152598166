import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../features/dialog';
import { enqueueErrorSnack, enqueueSuccessSnack } from '../../../features/snackbar';
import { activateJob } from '../../../features/job';

const DIALOG_ID = "activateJob"
const DIALOG_WIDTH = "sm"

function ActivateJobDialog() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const project = useSelector(state => state.project.single);
    const jobId = useSelector((state) => state.dialog[DIALOG_ID])
    const jobs = useSelector(state => state.job.list || state.job.all);
    const job = jobs && jobs.find(el => el.id === jobId);
    
    const jobLabel =  Array.isArray(jobId) ? "selected" : (job ? job.label : "");
    const show = !!jobId;

    const handleSubmit = e => {
        e && e.preventDefault() && e.stopPropagation();
        setLoading(true);
        dispatch(activateJob(jobId))
        .then(r => {
            dispatch(closeDialog(DIALOG_ID));
            dispatch(enqueueSuccessSnack("Job activated"))
        })
        .catch(e => {
            dispatch(enqueueErrorSnack("Error: " + e))    
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const onClose = () => {
        dispatch(closeDialog(DIALOG_ID));
    }
    // useEffect(() => {
    //     setLoading(false);
    // }, [show])

    return <Dialog
        open={!!show}
        onClose={onClose}
        fullWidth
        maxWidth={DIALOG_WIDTH}
        
    >
        <form onSubmit={handleSubmit}>
            <DialogTitle>
                Activate job
            </DialogTitle>
            <DialogContent dividers={false}>
                <Typography variant="subtitle1">Are you sure you want to activate the <b>{jobLabel}</b> job?</Typography>
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                {!loading && <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Button sx={{marginRight: 2}} onClick={onClose} >Cancel</Button>
                    <Button variant="contained" color="success" type="submit">Activate</Button>
                </Box>}
            </DialogActions>
        </form>
    </Dialog>
}

export default ActivateJobDialog;