import axios from "axios";
import { useEffect, useState } from "react";
import { prettyTruncate } from "../../../common";

function FeedsRow({report, row, index, hiddenElements, onHide}){
    const [feeds, setFeeds] = useState(null);
    useEffect(() => {
        if(report && row){
            var postData = {
                ...report.config.query,  //aggiungi la query generale
                project_id: report.project_id,
                tags_operator:'and',
                limit: row.contentConfig.displayFeeds,
                orderBy:'engagement'
            };
            if(row.contentConfig.filterByLanguage){
                postData.lang = row.contentConfig.language;
            }

            postData.tags = [...postData.tags || [],row.contentConfig.tagId];
            postData.tagstring = postData.tags.join();
            delete postData.tags;
            postData.tags = postData.tagstring;
            delete postData.tagstring;

            
            axios.post('/post/search/', postData).then(r => {
                setFeeds(r.data.posts);
            })
        }
    },[report, row, index]);
    const {contentConfig: {options}} = row;
    return feeds && feeds.map((post,findex) => {
        const key = index + "_" + findex;
        if(hiddenElements.find(h => h === key))
            return null;
        let titleColor = report.config.template.templateColors.primary;
        let textColor = report.config.template.templateColors.text;
        let titleStyle = {
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: report.config.template.templateFonts.headings,
            color: titleColor
        };

        if(options.renderTitleAsText){
            titleStyle = {
                fontSize: 16,
                fontWeight:"normal",
                fontFamily: report.config.template.templateFonts.headings,
                color: titleColor
            }
        }
        if(options.colorBySentiment===true){
            switch(post.sentiment){
                case 'pos':
                    titleColor = report.config.template.templateColors.sentimentPos;
                    textColor = report.config.template.templateColors.sentimentPos;
                    break;
                case 'neu':
                    titleColor = report.config.template.templateColors.sentimentNeu;
                    textColor = report.config.template.templateColors.sentimentNeu;
                    break;
                case 'neg':
                    titleColor = report.config.template.templateColors.sentimentNeg;
                    textColor = report.config.template.templateColors.sentimentNeg;
                    break;
            }
        }
        return <tr className="feeds" key={key} onClick={() => onHide(key)}>
            <td style={{
                padding:10,
                borderBottom: "1px solid " + report.config.template.templateColors.primary
            }}>
                {post.title && !options.hideTitle && <h3 style={titleStyle}>{post.title}</h3>}

                {!options.hideTimestamp && <div style={{
                    fontSize: 12,
                    fontFamily: report.config.template.templateFonts.text,
                    color: report.config.template.templateColors.secondary
                }}>
                    {post.post_time}
                </div>}

                {post.post_text && !options.hideBody && <p style={{
                    fontSize: 14,
                    color: textColor,
                    fontFamily: report.config.template.templateFonts.text,
                }}>
                    {prettyTruncate(post.post_text, 260, "...")}
                </p>}

                <div>
                    <a target="_blank" href={post.full_uri} style={{
                        fontSize: 12,
                        fontFamily: report.config.template.templateFonts.text,
                        color: report.config.template.templateColors.secondary
                    }}>
                        {prettyTruncate(post.full_uri, 50)}
                    </a>
                </div>
                {!options.hideEngagement && <div>
                    <p style={{
                        fontSize: 12,
                        fontFamily: report.config.template.templateFonts.text,
                        color: report.config.template.templateColors.highlight
                    }}>
                        <strong>Engagement score: </strong> {Math.round(post.engagement)}
                    </p>
                </div>}
            </td>
        </tr>;
    });
}

export default FeedsRow;