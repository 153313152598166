import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Input, ListSubheader } from '@mui/material';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const tags = [
//     'Tag 1',
//     'Tag 2',
//     'Tag 3',
//     'Tag 4',
//     'Tag 5'
// ];

export default function TagFilterSelect({
    label="Tag", 
    error=false,
    required=false,
    fullWidth=false,
    displayEmpty=false,
    placeholder="",
    size="small",
    onChange, 
    value
}) {
    const tags = useSelector(state => state.tag.list) || [];

    const handleDelete = (toRemove) => {
        const val = typeof value === 'string' ? value.split(',') : value;
        onChange(val.filter(v => v !== toRemove))
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const list = (tags || []).reduce((carry,item) => {
        const folderLabel = item.folder && item.folder.label;
        if(!carry[folderLabel]){
            carry[folderLabel] = [];
        }
        carry[folderLabel].push(item);
        return carry;
    },{});

    const folders = Object.keys(list);
    folders.sort();

    return (
        <FormControl size={size} fullWidth={fullWidth}>
        <InputLabel required={required} shrink={(displayEmpty && !!placeholder) || !!value} error={error} >{label} </InputLabel>
            <Select
                displayEmpty={displayEmpty}
                size={size}
                variant="standard"
                required={required}
                fullWidth={fullWidth}
                multiple
                value={value}
                onChange={handleChange}
                input={<Input label={label} />}
                renderValue={(selected) => {

                    if((!selected || selected.length === 0) && displayEmpty){
                        return placeholder;
                    }
                    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((tagId) => {
                            const tag = tags.find(t => t.id === (tagId.id || tagId))
                            return <Chip 
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onDelete={() => handleDelete(tag.id)} 
                                key={tag.id} 
                                label={tag.label} 
                                size={size} 
                            />
                        })}
                    </Box>
                }}
                MenuProps={MenuProps}
            >
                {folders.map((folderName) => {
                    return [
                        <ListSubheader key={"folder_" + folderName}><b>{folderName}</b></ListSubheader>,
                        list[folderName].map(tag => (
                            <MenuItem
                                key={tag.id}
                                value={tag.id}
                            >
                                {tag.label}
                            </MenuItem>
                        ))
                    ]
                })}
            </Select>
        </FormControl>
    );
}
