import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { enqueueErrorSnack, enqueueSnack } from './snackbar';
import { commonApiPromise, updateArray } from '../common';
import EditGroupDialog from '../components/group/EditGroupDialog';




export const slice = createSlice({
    name: 'group',
    initialState: {
        list: null
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers(builder) {


        builder.addCase(listGroups.fulfilled, (state, action) => {
            const {payload} = action
            state.list = payload;
        })
        
        builder.addCase(editGroup.fulfilled, (state, action) => {

            const {payload} = action
            if(payload){
                state.list = updateArray(state.list, payload)
            }
        });

      }
    
})


export const listGroups = createAsyncThunk('groups/all', async (filters, store) => {
    store.dispatch(slice.actions.setError(false));
    store.dispatch(slice.actions.setLoading(true));
    return commonApiPromise(axios.get("/group/all"), store)
    .finally(() => {
        store.dispatch(slice.actions.setLoading(false));
    })
})


export const editGroup = createAsyncThunk('groups/edit', async (data, store) => {
    return commonApiPromise(axios.post("/group/edit", data), store);
});

export const { setError, setLoading, logout } = slice.actions

export default slice.reducer
