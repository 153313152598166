import { OpenInFull } from "@mui/icons-material";
import { Box, Button, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import slugify from "slugify";
import { editProject } from "../../features/project";
import { enqueueSuccessSnack } from "../../features/snackbar";

function ProjectInfoForm({form, onChange, onSave, disableLabel=false, errors={}}){
    const [loading, setLoading] = useState(false);
    const [internalForm, setInternalForm] = useState(form);
    const dispatch = useDispatch();
    const s = (s) => slugify(s || "","_").toLowerCase();

    const setForm = f => onChange ? onChange(f) : setInternalForm(f);

    const handleChangeName = e => {
        const value = e.target.value;
        const label = internalForm.label;
        const name = internalForm.name;
        const _form = {...internalForm};
        
        if(!disableLabel && s(name) === (label || "")){
            _form.label = s(value);
        }
        _form.name = value;
        setForm(_form);
    }

    useEffect(() => {
        setInternalForm(form);
    },[form])

    const handleSubmit = e => {
        if(onSave){
            onSave(e);
            return;
        }
        setLoading(true);
        e.preventDefault();
        dispatch(editProject(internalForm)).then(r => {
            dispatch(enqueueSuccessSnack("Project saved"));
            setLoading(false)
        });
    }

    return <>

        <TextField
            fullWidth
            required
            autoFocus
            error={!!errors.name}
            variant="standard"
            label="Project name"
            helperText={"The name you’re identifying the project with"}
            value={internalForm.name || ""}
            onChange={handleChangeName}
        />
        <TextField
            fullWidth
            required
            disabled={disableLabel}
            error={!!errors.label}
            helperText={errors.label && errors.label !== true && errors.label}
            variant="standard"
            label="Project label"
            value={internalForm.label || ""}
            onChange={e => setForm({...internalForm, label: s(e.target.value).toLowerCase()})}
        />

        <TextField
            fullWidth
            multiline
            helperText={errors.label && errors.label !== true && errors.label}
            variant="standard"
            label="Project description"
            value={internalForm.description || ""}
            onChange={e => setForm({...internalForm, description: e.target.value})}

        />

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4}}>
            {!loading && <Button variant="contained" color="secondary" type="submit" onClick={handleSubmit}>Save Project</Button>}
            {loading && <LinearProgress />}
        </Box>
    </>
}
export default ProjectInfoForm;