import Container from '@mui/material/Container';
import { LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { autoLogin } from '../features/user';


export default function AutoLoginContainer() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(autoLogin())
    },[dispatch]);
    return <>
        <Paper component={Stack} direction="column" justifyContent="center" sx={{
            height: '100vh'
        }}>
            <Container component="main" maxWidth="xs">

                <Typography mt={4} mb={4} align="center">
                    Logging in
                </Typography>
                <LinearProgress />
            </Container>
        </Paper>
    </>
}