import { CalendarMonth, Favorite, Language, LocalOffer, Person, PriorityHigh, Search, Share } from "@mui/icons-material";
import moment from "moment";
import platforms from "./platforms";
import priorities from "./priorities";
import languages from "./languages";
import sentiments from "./sentiments";
import { intervalLabel } from "../common";

const postfilters = {
    interval: {
        icon: CalendarMonth,
        label: filter => {
            return "Last " + intervalLabel(filter)
        }
    },
    date: {
        icon: CalendarMonth,
        label: filter => {
            let string = "";
            if(filter.start)
                string += " from " + moment(filter.start).format("LL");
            if(filter.end){
                string += " to " + moment(filter.end).format("LL");
            }
            return string.trim();
        }
    },
    
    text_search: {
        icon: Search,
        label: filter => filter
    },
    sentiment: {
        icon: Favorite,
        label: filter => sentiments[filter]
    },
    language: {
        icon: Language,
        label: filter => languages[filter]
    },
    tag: {
        icon: LocalOffer,
        label: (filter, project) => {
            if(filter.value && filter.value[0]==="9999" ){
                return "Posts without tags";
            }
            if(!project || !project.tags){
                return "";
            }
            let joinString = ", ";
            let initialString = "";
            switch(filter.operator){
                case 'and': joinString = " and "; break;
                case 'not': initialString = "not "; joinString = " and not "; break;
                case 'notstrict': initialString = "not "; joinString = " or not "; break;
                case 'default':
                default: joinString = " or "; break;
            }
            return <>
                {initialString} {filter.value
                .map(id => project.tags.find(t => t.id === id).label)
                .map(t => <b>{t}</b>)
                .reduce((prev, curr) => [prev, joinString, curr])}
            </>
        }
    },
    priority: {
        icon: PriorityHigh,
        label: filter => priorities[filter]
    },
    author: {
        icon: Person,
        label: filter => filter
    },
    platform: {
        icon: Share,
        label: filter => platforms[filter]
    }
}

export default postfilters;