import React from "react";
import { useSelector } from "react-redux";
import AuthenticatedContainer from "./AuthenticatedContainer";
import AutoLoginContainer from "./AutoLoginContainer";
import UnauthenticatedContainer from "./UnauthenticatedContainer";

export default function MainContainer({children, ...props}){
    const user = useSelector(state => state.user.profile);
    const apiKey = useSelector(state => state.user.apiKey);
    if(!user && apiKey){
        return <AutoLoginContainer/>
    }
    return <>
        {!user && <UnauthenticatedContainer {...props}>{children}</UnauthenticatedContainer>}
        {user && <AuthenticatedContainer {...props}>{children}</AuthenticatedContainer>}
    </>
}