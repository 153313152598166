import { Apps } from "@mui/icons-material";
import { Box, Container, IconButton, Menu, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function AppsMenu() {
    
    var apps = useSelector(state => state.app.applications);
    if(!apps.length){
        apps = JSON.parse(localStorage.getItem("apps"));
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <IconButton
            onClick={handleClick}
            sx={{ ml: 2 }}
            aria-controls={open ? 'app-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
        >

            <Apps fontSize="large" />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            id="app-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Container maxWidth="lg">
                <Box sx={{display: "flex", justifyContent: 'space-around'}}>
                    {apps.map((app,i) => {
                        const img = <img alt={app.title} src={app.icon} style={{maxWidth: "100%"}} />;
                        return <Paper variant="outlined" key={i} style={{
                            margin: 10,
                            width: 160,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            opacity: app.enabled ? 1 : 0.45
                        }}>

                            {app.external ? <>
                                <a href={app.enabled ? "/app/"+app.app_id : "/#"}>{img}</a>
                            </> : <>
                                <Link to={app.enabled ? app.url : "/#"}>{img}</Link>
                            </>}
                            
                            <Typography variant="body2">{app.title}</Typography>
                        </Paper>
                    })}
                </Box>
            </Container>
        </Menu>

    </>
}